'use strict';

global.KeyframeVideo = class KeyframeVideo extends GameElement {
    /**
     * Init
     */
    onReady() {
        this.videoElement = document.createElement('video');
        this.videoElement.muted = false;
        this.videoElement.src = this.source;
        this.appendChild(this.videoElement);
    }

    /**
     * Gets the video source URL
     *
     * @return {String} Source
     */
    get source() {
        if(this.getAttribute('source')) {
            return this.getAttribute('source');
        }

        if(this.config && this.config.source) {
            return this.config.source;
        }

        return '';
    }

    /**
     * Adds an animation
     *
     * @param {String} name
     * @param {Number} from
     * @param {Number} to
     * @param {Boolean} loop
     */
    add(name, from, to, loop = true) {
        if(!this.animations) {
            this.animations = {};
        }

        this.animations[name] = {
            from: from,
            to: to,
            loop: loop
        };
    }

    /**
     * Plays an animation by name
     *
     * @param {String} name
     */
    play(name) {
        if(!this.animations || !this.animations[name]) {
            Debug.error(this, new Error(`Animation "${name}" not found`));
            return;
        }

        if(this._currentAnimation === name) { return; }

        this._currentAnimation = name;
        this.videoElement.currentTime = this.animations[name].from;
        
        if(this.videoElement.error) {
            Debug.error(this, this.videoElement.error);
            return;
        }
        
        this.videoElement.play();
    }

    /**
     * Stops animation playback
     */
    stop() {
        this.videoElement.stop();
    }

    /**
     * Event: Tick
     */
    onTick() {
        if(!this.animations || !this._currentAnimation || !this.animations[this._currentAnimation]) { return; }

        let animation = this.animations[this._currentAnimation];

        if(this.videoElement.currentTime >= animation.to && animation.loop) {
            this.videoElement.currentTime = animation.from;
        }

        if(this.videoElement.paused) {
            this.videoElement.currentTime = animation.from;
        }
    }

    /**
     * Gets the CSS
     */
    get css() { return `
        :host {
            display: block;
        }

        video {
            display: block;

            ${this.config.isVertical ? `
                height: 100%;
            ` : `
                width: 100%;
            `}
        }
    ` }
}

KeyframeVideo.register();
