'use strict';

global.UIPreloader = class UIPreloader extends GameElement {
    /**
     * Event: Ready
     */
    onReady() {
        this._bar = this.getDescendant('bar');
    }

    /**
     * Shows this element
     */
    show() {
        this.setAttribute('in', false);
    }
    
    /**
     * Hides this element
     */
    hide() {
        this.removeAttribute('in');
    }

    /**
     * Sets the text
     *
     * @param {String} text
     */
    set text(text) {
        this.getDescendant('text').innerHTML = text;
    }

    /**
     * Sets the progress
     *
     * @param {Number} progress
     */
    set progress(progress) {
        if(this.parentElement !== document.body) {
            document.body.appendChild(this);
        }
       
        this._progress = progress;
        this._bar.style.width = `${progress * 100}%`;
    }
    
    /**
     * Gets the progress
     *
     * @return {Number} Progress
     */
    get progress() {
        return this._progress || 0;
    }

    /**
     * Gets the CSS
     */
    get css() { return `
        :host {
            position: absolute;
            z-index: 2000;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity 0.5s ease;
        }
            :host([in]) {
                opacity: 1;
            }

        content {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 2rem;
            background-color: white;

            ${Sprite.mixin('ui', 'frame-generic')}
        }

        text {
            font-family: SketchNote, sans-serif;
            font-size: 1.4rem;
            display: block;
            margin-bottom: 0.5rem;
        }

        rail {
            display: block;
            height: 2rem;
            width: 14rem;
            background-color: black;
            position: relative;
            border-radius: 0.4rem;
            overflow: hidden;
        }

        bar {
            background-color: var(--color-primary);
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: width 0.2s ease;
        }
    `; }

    /**
     * Gets the HTML
     */
    get html() { return `
        <content>
            <text></text>
            <rail>
                <bar style="width: 0%"></bar>
            </rail>
        </content>
    `; }
}

UIPreloader.register();
