'use strict';

global.Http = class Http {
    /**
     * A GET request
     *
     * @param {String} url
     * 
     * @param {Object|String} Response
     */
    static async get(url) {
        return await this.request('get', url);
    }
    
    /**
     * A POST request
     *
     * @param {String} url
     * @param {Object} data
     * 
     * @param {Object|String} Response
     */
    static async post(url, data) {
        return await this.request('post', url, data);
    }

    /**
     * Reads a cookie
     *
     * @param {String} key
     *
     * @return {String} Result
     */
    static getCookie(key) {
        let parts = document.cookie.split(";");
        
        for(let i = 0; i < parts.length; i++) {
            let kvp = parts[i].split("=");
            
            if(key == kvp[0].trim()) {
                return decodeURIComponent(kvp[1]);
            }
        }
        
        return null;
    }

    /**
     * A generic request
     *
     * @param {String} method
     * @param {String} url
     * @param {Object} data
     *
     * @return {Object|String} Response
     */
    static async request(method, url, data = null) {
        return await new Promise((resolve, reject) => {
            if(Debug.isActive) {
                url += url.indexOf('?') < 0 ? '?' : '&';
                url += 'debug=true';
            }

            let request = new XMLHttpRequest();
            request.open(method.toUpperCase(), url, true);

            if(data && method.toUpperCase() === 'POST') {
                request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
            }

            request.onload = () => {
                if(request.status >= 200 && request.status < 400) {
                    try {
                        resolve(JSON.parse(request.response));
                    } catch(e) {
                        resolve(request.response);
                    }

                } else {
                    try {
                        reject(new Error(JSON.parse(request.response).error));

                    } catch(e) {
                        reject(new Error(request.response));
                    }
                }
            };

            request.onerror = reject;

            if(data) {
                request.send(JSON.stringify(data));

            } else {
                request.send();
            
            }
        });
    }

    /**
     * Gets a query parameter
     *
     * @param {String} name
     *
     * @return {String} Value
     */
    static getQueryParameter(name) {
        let params = new URLSearchParams(location.href.split('?')[1] || '');

        return params.get(name) || '';
    }
}
