'use strict';

global.UITutorial = class UITutorial extends GameElement {
    /**
     * Event: Init
     */
    onReady() {
        this.onclick = (e) => {
            this.onClick();
        };
    }

    /**
     * Event: Click
     */
    onClick() { 
        Audio.ui('ui/button-click');

        this.destroy();
    }

    /**
     * Gets the CSS
     */
    get css() { return `
        :host { 
            background-color: rgba(0, 0, 0, 0.5); 
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 300;
        }

        content {
            display: flex;
            background-image: url(${Asset.getUrl('/asset/ui/brush-1.png')});
            background-size: 100% 100%;
            padding: 8rem 10rem;
            box-sizing: border-box;
            color: black;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }

        heading {
            font-size: 2rem;
            display: block;
            margin-bottom: 2rem;
            position: relative;
            z-index: 10;
            float: left;
            font-family: SketchNote, sans-serif;
        }

        heading::after {
            content: '';
            display: block;
            width: calc(100% + 2rem);
            left: -1rem;
            position: absolute;
            background-color: var(--color-primary);
            bottom: -1rem;
            height: 2rem;
            z-index: -1;
        }

        text {
            clear: both;
            line-height: 1.5;
        }

        img {
            flex-shrink: 0;
            width: 30rem;
            position: absolute;
            bottom: 6rem;
            left: 10rem;
        }

        img + texts {
            padding-left: 28rem;
            padding-right: 6rem;
        }

        text {
            display: block;
            font-size: 1.5rem;
        }
    `; }

    /**
     * Gets the HTML
     */
    get html() { return `
        <content>
            ${this.config.image ? `<img src="${Asset.getUrl(this.config.image)}">` : ``}
            <texts>
                ${this.config.heading ? `
                    <heading>${this.config.heading}</heading>
                ` : ``}
                <text>${marked(this.config.text)}</text>
            </texts>
        </content>
    `; }
}

UITutorial.register();
