'use strict';

const NAVIGATION_PRECISION = 4; // The higher, the less precise

global.TopicMap = class TopicMap extends GameElement {
    /**
     * Preload
     */
    async load() {
        Audio.music(this.config.music);

        let preload = [
            'asset/ui/sprites.svg',
            `/asset/player/player-topic-map-${User.current.getClass()}.png`,
            [ this.mapConfig.backgroundImage, 400 ]
        ];

        for(let level of this.levels) {
            if(level.previewImage) {
                if(!level.previewImage.source) { continue; }

                preload.push([ level.previewImage.source, level.previewImage.size.x ]);
            }
        }

        if(this.mapConfig.clouds) {
            for(let element of this.mapConfig.clouds.itemListElement) {
                preload.push([ element.item, 40 ]);
            }
        }
        
        if(!User.current.getFlag('tutorial-topic-map')) {
            for(let element of Settings.mapTutorial.itemListElement) {
                preload.push([ element.item, 80 ]);
            }
        }

        await Asset.preload(preload);
    }

    /**
     * Init
     */
    onReady() {
        // Init transform
        this.transform.size.x = this.size.x;
        this.transform.size.y = this.size.y;

        // Init clouds
        this._clouds = [];

        if(this.mapConfig.clouds) {
            for(let i = 0; i < this.mapConfig.clouds.numberOfItems; i++) {
                this._clouds.push(this.transform.size.x + Math.round(Math.random() * 100));
            }
        }

        // Init points
        let canEnterFinalLevel = this.canEnterFinalLevel;

        for(let level of this.levels) {
            level.canEnter = !level.isFinal || canEnterFinalLevel;
            level.totalLevels = this.totalLevels;
            level.completedLevels = this.completedLevels;

            this.mapElement.appendChild(new TopicMapPoint(level));
        }

        // Init player
        let player = new TopicMapPlayer({
            map: this,
            startPosition: this.playerStartPosition
        });
        this.mapElement.appendChild(player);
        this.player = player;

        // Init compass
        this.compass = new TopicMapCompass({
            levels: this.levels,
            player: this.player,
            size: this.size
        });

        this.appendChild(this.compass);

        // Init click navigation
        this.addEventListener('click', (e) => {
            this.onClick(e)
        });

        // Init UI events
        this.getDescendant('journal').onclick = (e) => {
            e.stopPropagation();

            this.onClickJournal();
        };

        // Init map offset logic
        this.mapElement.addEventListener('offset', () => {
            this.onOffsetChange();
        });
       
        // Build navigation
        this.buildNavigation();
        
        // Activate tutorial if needed
        if(!User.current.getFlag('tutorial-topic-map')) {
            this.appendChild(new UITutorialDialog({
                images: Settings.mapTutorial,
                flag: 'tutorial-topic-map'
            }));
        }
    }
    
    /**
     * Transition in
     */
    async transitionIn() {
        await Clock.waitForSeconds(0.5);
    }
    
    /**
     * Transition out
     */
    async transitionOut() {
        await Clock.waitForSeconds(0.5);
    }

    /**
     * Gets the map container
     *
     * @return {HTMLElement} Map
     */
    get mapElement() {
        return this.getDescendant('map');
    }
    
    /**
     * Gets the id of this topic
     */
    get topicId() {
        return Router.path[1];
    }

    /**
     * Gets the name of this topic
     */
    get topicName() {
        if(!this.config || !this.config.name) {
            return '[name]';
        }

        return this.config.name;
    }
    
    /**
     * Gets the id of this map
     */
    get mapId() {
        return Router.path[3];
    }

    /**
     * Gets the name of this map
     */
    get mapName() {
        if(!this.mapConfig || !this.mapConfig.name) {
            return '[name]';
        }

        return this.mapConfig.name;
    }

    /**
     * Gets the map config
     *
     * @return {Object} Map
     */
    get mapConfig() {
        if(!this._mapConfig) {
            this._mapConfig = this.config.maps[this.mapId];
        }

        return this._mapConfig;
    }

    /**
     * Gets the initial player position
     *
     * @return {Vector} Player position
     */
    get playerStartPosition() {
        let last = User.current.getLastMapPosition(this.mapId);

        if(last) {
            return last;
        }

        if(!this.mapConfig.playerPosition || !this.mapConfig.playerPosition.x || !this.mapConfig.playerPosition.y) {
            return new Vector(this.mapConfig.size.x / 2, this.mapConfig.size.y / 2);
        }

        return new Vector(this.mapConfig.playerPosition.x, this.mapConfig.playerPosition.y);
    }

    /**
     * Gets the current player position
     *
     * @return {Vector} Player position
     */
    get playerPosition() {
        if(!this.player) { return new Vector(0, 0); }

        return this.player.transform.position;
    }

    /**
     * Gets the current navigation path
     *
     * @return {Array} Navigation path
     */
    get navigationPath() {
        if(!this.player) { return []; }

        return this.player.navigationPath;
    }

    /**
     * Gets levels
     *
     * @return {Array} Levels
     */
    get levels() {
        if(!this.mapConfig || !this.mapConfig.levels) { return []; }

        return Object.values(this.mapConfig.levels);
    }
    
    /**
     * Gets the amount of completed levels
     *
     * @return {Number} Completed levels
     */
    get completedLevels() {
        let completed = 0;

        for(let level of this.levels) {
            if(!User.current.isCompleted(level.identifier)) { continue; }

            completed++;
        }

        return completed;
    }

    /**
     * Gets the total amount of levels
     *
     * @return {Number} Total levels
     */
    get totalLevels() {
        return this.levels.length;
    }

    /**
     * Gets the size
     *
     * @return {Vector} Size
     */
    get size() {
        if(!this.mapConfig || !this.mapConfig.size) {
            return Vector.ZERO;
        }

        return new Vector(
            this.mapConfig.size.x || 0,
            this.mapConfig.size.y || 0
        );
    }

    /**
     * Gets whether the player can enter the final level
     *
     * @return {Boolean} Can enter final level
     */
    get canEnterFinalLevel() {
        for(let level of this.levels) {
            if(!level.isFinal && !User.current.isCompleted(level.identifier)) {
                return false;
            }
        }

        return true;
    }

    /**
     * Builds the navigation grid
     */
    buildNavigation() {
        let points = this.getDescendants('ge-topicmappoint');
        let map = this.mapElement.getBoundingClientRect();
        let unit = Viewport.worldUnit * NAVIGATION_PRECISION;
        let nodes = [];

        // Add all nodes
        for(let x = 0; x <= this.size.x / NAVIGATION_PRECISION; x++) {
            let row = [];

            for(let y = 0; y <= this.size.y / NAVIGATION_PRECISION; y++) {
                row.push(1);
            }

            nodes.push(row);
        }

        // Detect walls
        for(let point of points) {
            let block = point.blockingVolume;

            let x = Math.round(block.x / NAVIGATION_PRECISION);
            let y = Math.round(block.y / NAVIGATION_PRECISION);
            let w = Math.round(block.width / NAVIGATION_PRECISION);
            let h = Math.round(block.height / NAVIGATION_PRECISION);

            for(let nodeX = x; nodeX < x + w; nodeX++) {
                for(let nodeY = y; nodeY < y + h; nodeY++) {
                    if(!nodes[nodeX] || !nodes[nodeX][nodeY]) { continue; }

                    nodes[nodeX][nodeY] = 0;
                }
            }
        }

        this.navigationGraph = AStar.createGraph(nodes, { diagonal: true });
    }

    /**
     * Event: Click journal
     */
    onClickJournal() {
        Audio.ui('ui/button-click');

        Router.go(`/topic/${this.topicId}/map/${this.mapId}/journal`);
    }

    /**
     * Event: Click
     *
     * @param {InputEvent} e
     */
    onClick(e) {
        if(!this.navigationGraph) { return; }

        Audio.music(this.config.music);

        let navigationPath = [];
        
        let target = Viewport.projectFromScreen(new Vector(e.pageX, e.pageY)).subtract(this.transform.position);
        let graph = this.navigationGraph;

        let startX = Math.round(this.player.transform.position.x / NAVIGATION_PRECISION);
        let startY = Math.round(this.player.transform.position.y / NAVIGATION_PRECISION);
        let endX = Math.round(target.x / NAVIGATION_PRECISION);
        let endY = Math.round(target.y / NAVIGATION_PRECISION);

        if(!graph.grid[startX] || !graph.grid[startX][startY]) {
            return Debug.error(this, new Error(`Start coordinate out of range (${startX},${startY})`));

        } else if(!graph.grid[endX] || !graph.grid[endX][endY]) {
            return Debug.error(this, new Error(`End coordinate out of range (${endX},${endY})`));
        
        }

        let start = graph.grid[startX][startY];
        let end = graph.grid[endX][endY];

        let result = AStar.search(graph, start, end, { heuristic: AStar.heuristics.diagonal });
           
        for(let point of result) {
            navigationPath.push(new Vector(
                point.x * NAVIGATION_PRECISION,
                point.y * NAVIGATION_PRECISION
            ));
        }
        
        if(navigationPath.length < 2) { return; }

        navigationPath[navigationPath.length - 1] = target;

        this.player.navigationPath = navigationPath;
    }
 
    /**
     * Event: Tick
     */
    onTick(delta) {
        // Follow player
        let target = Viewport.size
            .divide(2)
            .subtract(this.player.transform.position)
            .min(Vector.ZERO)
            .max(this.size.subtract(Viewport.size).multiply(-1));

        this.transform.position = target;

        // Update player's collision avoidance
        let grid = this.navigationGraph.grid;
        let x = Math.round(this.player.transform.position.x / NAVIGATION_PRECISION);
        let y = Math.round(this.player.transform.position.y / NAVIGATION_PRECISION);

        this.player.canWalkLeft = grid[x - 1][y] && grid[x - 1][y].weight === 1;
        this.player.canWalkRight = grid[x + 1][y] && grid[x + 1][y].weight === 1;
        this.player.canWalkUp = grid[x][y - 1] && grid[x][y - 1].weight === 1;
        this.player.canWalkDown = grid[x][y + 1] && grid[x][y + 1].weight === 1;

        // Update clouds
        for(let i = 0; i < this._clouds.length; i++) {
            this._clouds[i] -= delta * 10;

            if(this._clouds[i] < -40) {
                this._clouds[i] = this.transform.size.x + Math.round(Math.random() * 100);
            }
        }
    }

    /**
     * Event: Draw
     */
    onDraw(context) {
        // Draw background
        let backgroundImage = Asset.get(this.mapConfig.backgroundImage);
        
        if(backgroundImage) {
            let backgroundImageScale = new Vector(
                backgroundImage.naturalWidth / (this.transform.size.x * Viewport.worldUnit),
                backgroundImage.naturalHeight / (this.transform.size.y * Viewport.worldUnit)
            );

            Canvas.drawCroppedImage(
                this.mapConfig.backgroundImage,
                0,
                0,
                Viewport.size.x,
                Viewport.size.y,
                -this.transform.position.x * backgroundImageScale.x * Viewport.worldUnit,
                -this.transform.position.y * backgroundImageScale.y * Viewport.worldUnit,
                Viewport.root.offsetWidth * backgroundImageScale.x,
                Viewport.root.offsetHeight * backgroundImageScale.y
            );
        }

        if(Debug.isActive) {
            // Draw navigation mesh
            let size = Viewport.worldUnit * NAVIGATION_PRECISION;
            context.strokeStyle = 'rgba(0, 0, 0, 0.2)';

            for(let node of this.navigationGraph.nodes) {
                let x = (node.x * NAVIGATION_PRECISION + this.transform.position.x) * Viewport.worldUnit - size / 2;
                let y = (node.y * NAVIGATION_PRECISION + this.transform.position.y) * Viewport.worldUnit - size / 2;

                if(node.weight === 0) {
                    context.fillStyle = 'rgba(255, 0, 0, 0.2)';
                } else {
                    context.fillStyle = 'rgba(0, 255, 0, 0.2)';
                }

                context.strokeRect(x, y, size, size);
                context.fillRect(x, y, size, size);
            }
  
            // Draw navigation path
            context.strokeStyle = 'rgba(255, 0, 0)';
            context.beginPath();

            let isFirstPoint = true;

            for(let point of this.player.navigationPath || []) {
                let x = (point.x + this.transform.position.x) * Viewport.worldUnit;
                let y = (point.y + this.transform.position.y) * Viewport.worldUnit;

                if(isFirstPoint) {
                    context.moveTo(x, y);
                    isFirstPoint = false;
                
                } else {
                    context.lineTo(x, y);
                
                }
            }

            context.stroke();
        }
    }

    /**
     * Event: Draw deferred
     */
    onDrawDeferred(context) {
        // Draw clouds
        let yMargin = this.transform.size.y / this._clouds.length / 2;
        let ySize = this.transform.size.y - yMargin * 2;
        let yUnit = ySize / (this._clouds.length - 1);

        for(let i = 0; i < this._clouds.length; i++) {
            let x = this._clouds[i] + this.transform.position.x;
            let y = yMargin + yUnit * i + this.transform.position.y;

            Canvas.drawImage(this.mapConfig.clouds.itemListElement[i].item, x, y, 40);
        }
    }

    /**
     * Gets the HTML
     */
    get html() { return `
        <transition></transition>
        <journal></journal>
        <ge-uimusictoggle></ge-uimusictoggle>
        <header>
            <info>
                <name>${this.mapName}</name>
                <levels>${L10N.translate('levels')}: ${this.completedLevels}/${this.totalLevels}</levels>
            </info>
        </header>
        <map></map>
    `; }

    /**
     * Gets the CSS
     */
    get css() { return `
        :host {
            position: absolute;
            display: block;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        journal {
            position: absolute;
            display: block;
            top: 2rem;
            left: 2rem;
            width: 4rem;
            height: 4rem;
            cursor: pointer;
            z-index: 20;
            ${Sprite.mapNamedCss('ui', 'topic-journal')}
        }

        /* Transition */
        transition {
            position: absolute;
            z-index: 900;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: white;
            opacity: 0;
            transition: opacity 0.5s ease;
            pointer-events: none;
        }
            :host([transitioning]) transition {
                opacity: 1;
                pointer-events: all;
            }

        /* Header */
        header {
            position: absolute;
            top: 1rem;
            right: 1rem;
            display: flex;
            align-items: center;
            z-index: 100;
        }
            header name {
                display: block;
                font-size: 2rem;
                padding-left: 1rem;
                font-family: SketchNote, sans-seif;
            }

            header levels {
                font-size: 3rem;
                font-family: SketchNote, sans-seif;
                display: block;
                position: relative;
                padding: 1rem;
            }
                header levels::after {
                    display: block;
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 2rem;
                    width: 100%;
                    background-color: var(--color-primary);
                    z-index: -1;
                }
    `; }
}

TopicMap.register()
