'use strict';

const PanZoom = require('panzoom');

global.TopicWorldMap = class TopicWorldMap extends GameElement {
    /**
     * Preload
     */
    async load() {
        Audio.music('menu'); 
        
        let preload = [
            'asset/ui/sprites.svg',
            [ 'asset/topic-world-map/topic-world-map-background.svg', 100 ]
        ];

        for(let level of this.levels) {
            if(level.previewImage) {
                if(!level.previewImage.source) { continue; }

                preload.push([ level.previewImage.source, level.previewImage.size.x ]);
            }
        }
        
        await Asset.preload(preload);
    }

    /**
     * Init
     */
    onReady() {
        // Init UI events
        this.getDescendant('exit').onclick = (e) => {
            this.onClickExit();
        };
        
        this.getDescendant('zoom in').onclick = (e) => {
            this.onClickZoomIn();
        };
        
        this.getDescendant('zoom out').onclick = (e) => {
            this.onClickZoomOut();
        };
       
        for(let filter of this.getDescendants('filters input')) {
            filter.onchange = (e) => {
                this.onChangeFilter(e.currentTarget.value);
            };
        }

        // Init points
        this._pointsElement = this.getDescendant('map points');

        for(let level of this.levels) {
            let point = new TopicWorldMapPoint(level);

            point.onmousedown = (e) => {
                this.onClickPoint(e.currentTarget);
            };
            
            point.ontouchstart = (e) => {
                this.onClickPoint(e.currentTarget);
            };

            this._pointsElement.appendChild(point);
        }

        // Init map zoom
        this._imageElement = this.getDescendant('map img');

        setTimeout(() => {
            this._panzoom = new PanZoom(this._imageElement, {
                smoothScroll: false,
                bounds: true,
                boundsPadding: 0.1,
                boundsDisabledForZoom: false,
                maxZoom: 10,
                minZoom: 1
            });

            this._panzoom.on('transform', (e) => {
                if(this._openPoint) {
                    this._openPoint = null;
                    this.update();
                }

                this.updatePoints();
            });
        }, 100);
    }

    /**
     * Event: Click zoom in
     */
    onClickZoomIn() {
        Audio.ui('ui/button-click');
        Audio.music('menu'); 
        
        this._panzoom.smoothZoom(window.innerWidth / 2, window.innerHeight / 2, 1.5);
    }
    
    /**
     * Event: Click zoom out
     */
    onClickZoomOut() {
        Audio.ui('ui/button-click');
        Audio.music('menu'); 
        
        this._panzoom.smoothZoom(window.innerWidth / 2, window.innerHeight / 2, 0.5);
    }

    /**
     * Event: Click point
     */
    onClickPoint(point) {
        Audio.ui('ui/button-click');
        Audio.music('menu'); 
        
        this._openPoint = point;

        let preview = this.getDescendant('preview');

        preview.innerHTML = `
            <img src="${Asset.getUrl(point.image)}">
            <name>${point.name}</name>
            <description>${point.description}</description>
            <close></close>
        `;

        preview.querySelector('close').onclick = () => {
            this.onClickClosePreview();
        };

        this.update();
        this.updatePoints();
    }

    /**
     * Event: Click close preview
     */
    onClickClosePreview() {
        Audio.ui('ui/button-click');
        Audio.music('menu'); 
        
        this._openPoint = null;

        this.update();
        this.updatePoints();
    }
    
    /**
     * Gets the relative screen position of the open point
     *
     * @return {Vector} Screen position
     */
    get openPointScreenPosition() {
        if(!this._openPoint) { return Vector.ZERO; }

        let viewport = Viewport.root.getBoundingClientRect();
        let bounds = this._openPoint.getBoundingClientRect();

        return new Vector(
            ((bounds.x - viewport.x) / viewport.width) * 100,
            ((bounds.y - viewport.y) / viewport.height) * 100
        );
    }


    /**
     * Event: Change filter
     */
    onChangeFilter(filter) {
        Audio.ui('ui/button-click');
        Audio.music('menu'); 
        
        for(let point of this.getDescendants('ge-topicworldmappoint')) {
            if(!filter || point.config.timespan === filter) {
                point.removeAttribute('hidden');
            } else {
                point.setAttribute('hidden', true);
            }
        }
    }

    /**
     * Gets the map container
     *
     * @return {HTMLElement} Map
     */
    get mapElement() {
        return this.getDescendant('map');
    }
    
    /**
     * Gets the id of this topic
     */
    get topicId() {
        return Router.path[1];
    }

    /**
     * Gets the name of this topic
     */
    get topicName() {
        if(!this.config || !this.config.name) {
            return '[name]';
        }

        return this.config.name;
    }

    /**
     * Gets levels
     *
     * @return {Array} Levels
     */
    get levels() {
        let levels = [];

        for(let mapId in this.config.maps) {
            for(let levelId in this.config.maps[mapId].levels) {
                levels.push(this.config.maps[mapId].levels[levelId]);
            }
        }

        return levels;
    }

    /**
     * Gets filter strings
     *
     * @return {Array} Filters
     */
    get filters() {
        let filters = {};

        for(let level of this.levels) {
            if(!level.timespan) { continue; }

            filters[level.timespan.trim()] = null;
        }

        filters = Object.keys(filters);

        filters.sort();

        return filters;
    }

    /**
     * Event: Click exit
     */
    onClickExit() {
        Audio.ui('ui/button-click');
        Audio.music('menu'); 
        
        Router.go(`/topics/${this.topicId}`);
    }
   
    /**
     * Update points
     */
    updatePoints() {
        this._pointsElement.style.transformOrigin = this._imageElement.style.transformOrigin;
        this._pointsElement.style.transform = this._imageElement.style.transform;
    
        let scale = this._panzoom.getTransform().scale;

        for(let point of this._pointsElement.children) {
            let thisScale = Math.pow(scale, -1);

            if(point === this._openPoint) {
                thisScale *= 2;
                point.setAttribute('open', true);
            
            } else {
                point.removeAttribute('open');

            }

            point.style.transform = `scale(${thisScale})`;
        }
    }

    /**
     * Transition in
     */
    async transitionIn() {
        await Clock.waitForSeconds(0.1);
    }

    /**
     * Transition out
     */
    async transitionOut() {
        await Clock.waitForSeconds(1); 
    }

    /**
     * Gets the HTML
     */
    get html() { return `
        <exit></exit>
        <ge-uimusictoggle></ge-uimusictoggle>
        <zoom>
            <out></out>
            <in></in>
        </zoom>
        <filters>
            <heading>${L10N.translate('timespans-heading')}</heading>
            <label all>
                <input type="radio" name="filter" value="" autocomplete="off" checked>
                <span>${L10N.translate('all-timespans')}</span>
            </label>
            ${this.filters.map(filter => `
                <label>
                    <input type="radio" name="filter" value="${filter}" autocomplete="off">
                    <span>${filter}</span>
                </label>
            `).join('')}
        </filters>
        <preview></preview>
        <map>
            <heading>${L10N.translate('topic-world-map')}</heading>
            <img src="${Asset.getUrl('asset/topic-world-map/topic-world-map-background.svg')}">
            <points></points>
        </map>
    `; }

    /**
     * Gets the CSS for the preview box
     */
    get previewCss() {
        let position = this.openPointScreenPosition;
        let translate = { x: '2rem', y: '0' };

        if(position.x > 50) {
            translate.x = 'calc(-100% - 4rem)';
        }

        if(position.y > 66) {
            translate.y = '-100%';

        } else if(position.y > 33) {
            translate.y = '-50%';
        }

        return `
            left: ${position.x}%;
            top: ${position.y}%;

            transform: translate(${translate.x}, ${translate.y});
        `;
    }

    /**
     * Gets the CSS
     */
    get css() { return `
        :host {
            background-color: var(--color-grey);
            position: absolute;
            display: block;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            transition: opacity 0.5s ease;
        }
            :host([transitioning]) {
                opacity: 0;
            }

        exit {
            position: absolute;
            display: block;
            top: 2rem;
            left: 2rem;
            width: 4rem;
            height: 4rem;
            cursor: pointer;
            z-index: 20;
            ${Sprite.mapNamedCss('ui', 'up-arrow')}
        }
        
        /* Map */
        map {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            outline: none !important;
        }
            map points {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            map img {
                display: block;
                outline: none !important;
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: center;
                user-select: none;
                -webkit-user-select: none;
                -moz-user-select: none;
            }
        
            map > heading {
                display: block;
                max-width: 60%;
                font-size: 2rem;
                padding: 0;
                left: 50%;
                top: 3rem;
                position: absolute;
                box-sizing: border-box;
                font-family: SketchNote, sans-serif;
                z-index: 20;
                pointer-events: none;
                transform: translateX(-50%);
            }
                map > heading::after {
                    content: '';
                    position: absolute;
                    left: -0.5rem;
                    display: block;
                    background-color: var(--color-primary);
                    height: 1.4rem;
                    bottom: -0.8rem;
                    width: calc(100% + 1rem);
                    z-index: -1;
                }

        /* Filters */
        filters {
            position: absolute;
            display: block;
            bottom: 2rem;
            left: 2rem;
            z-index: 40;
            padding: 2rem;
            background-color: var(--color-grey);
            border-radius: 0.5rem;
        }
            filters heading {
                font-size: 1.4rem;
                margin-bottom: 2rem;
                display: block;
                font-weight: bold;
            }

            filters label {
                display: flex;
                align-items: center;
                cursor: pointer;
                font-size: 1.4rem;
            }
                filters label:not(:last-child) {
                    margin-bottom: 1rem;
                }
                
                filters label input {
                    display: block;
                    margin: 0 1rem 0 0;
                    width: 2rem;
                    height: 2rem;
                    border: 1px solid dimgrey;
                    appearance: none;
                    -o-appearance: none;
                    -ms-appearance: none;
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    background-color: white;
                    outline: none !important;
                    border-radius: 2px;
                }
                    filters label input:checked {
                        background-color: var(--color-primary);
                    }

                filters label span {
                    display: block;
                    line-height: 2rem;
                }

        /* Zoom */
        zoom {
            position: absolute;
            display: flex;
            top: 2rem;
            right: 3rem;
            z-index: 40;
        }
            zoom in, zoom out {
                display: block;
                margin-left: 1rem;
                width: 4rem;
                height: 4rem;
                cursor: pointer;
            }

            zoom out {
                ${Sprite.mapNamedCss('ui', 'minus')};
            }
            
            zoom in {
                ${Sprite.mapNamedCss('ui', 'plus')};
            }
        
        /* Preview */
        preview {
            display: ${this._openPoint ? 'block' : 'none'};
            position: absolute;
            width: 20rem;
            padding: 2rem;
            background-color: white;
            margin: 2rem;
            z-index: 50;

            ${Sprite.mixin('ui', 'frame-generic')}

            ${this.previewCss}
        }
            preview img {
                width: 100%;
                margin-bottom: 1rem;
            }

            preview name {
                display: block;
                font-family: SketchNote, sans-serif;
                font-size: 2rem;
                margin-bottom: 2rem;
            }

            preview description {
                display: block;
                font-size: 1.4rem;
            }
            
            preview close {
                display: block;
                position: absolute;
                top: 1rem;
                right: 1rem;
                width: 2rem;
                height: 2rem;
                cursor: pointer;

                ${Sprite.mapNamedCss('ui', 'close')};
            }
    `; }
}

TopicWorldMap.register()
