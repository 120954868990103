'use strict';

global.StageChallengeSearch = class StageChallengeSearch extends StageChallenge {
    /**
     * Get preloaded assets
     */
    static getPreloadedAssets(config) {
        let preload = [
            '/asset/ui/sprites.svg',
            [ config.backgroundImage, 100 ]
        ];

        if(config.highlights) {
            for(let element of config.highlights.itemListElement) {
                if(!element.item) { continue; }

                preload.push(element.item.image);
            }
        }

        return super.getPreloadedAssets(config).concat(preload);
    }
    
    /**
     * Event: Init
     */
    onReady() {
        super.onReady();

        for(let highlight of this.getDescendants('background highlight')) {
            highlight.onclick = (e) => {
                this.onClickHighlight(e.currentTarget);
            };
        };
    }

    /**
     * Event: Highlight clicked
     *
     * @param {HTMLElement} backgroundHighlight
     */
    onClickHighlight(backgroundHighlight) {
        let id = backgroundHighlight.getAttribute('identifier');
        let indexHighlight = this.getDescendant(`index highlight[identifier="${id}"]`);

        backgroundHighlight.setAttribute('found', true);
        indexHighlight.setAttribute('found', true);

        Audio.ui('stage/stage-correct');

        if(this.getDescendants('background highlight:not([found])').length < 1) {
            this.onComplete();
        }
    }

    /**
     * Gets all highlights
     *
     * @return {Array} Highlights
     */
    get highlights() {
        if(!this.config || !this.config.highlights) { return []; }

        let highlights = [];

        for(let element of this.config.highlights.itemListElement) {
            if(!element.item) { continue; }

            highlights.push(element.item);
        }

        return highlights;
    }

    /**
     * Gets the CSS
     */
    get css() { return super.css + `
        background {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-size: 100% auto;
            background-position: center;
            background-repeat: no-repeat;
            background-color: white;
            
            ${this.config.backgroundImage ? `background-image: url('${Asset.getUrl(this.config.backgroundImage)}');` : ``}
        }

        background highlight {
            display: block;
            position: absolute;
            cursor: pointer;
        }
            background highlight circle {
                display: none;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                box-sizing: border-box;

                ${Sprite.mapNamedCss('ui', 'circle-blue')}
            }

            background highlight[found] circle {
                display: block;
            }

        index {
            display: block;
            position: absolute;
            bottom: 4rem;
            left: 4rem;
            display: flex;
        }

        index highlight {
            height: 10rem;
            background-color: var(--color-primary);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 10rem;
            display: block;
            margin-right: 1rem;

            ${Sprite.mixin('ui', 'frame-generic')}
        }
        
        index highlight[found]::before {
            content: '';
            display: block;
            position: absolute;
            color: white;
            bottom: -1rem;
            right: -1rem;
            width: 4rem;
            height: 4rem;
            ${Sprite.mapNamedCss('ui', 'tick-green-shadow')}
        }
    `; }

    /**
     * Gets the HTML
     */
    get html() { return super.html + `
        <background>
            ${this.highlights.map(highlight => `
                <highlight identifier="${highlight.name}" style="width: ${highlight.size.x}rem; height: ${highlight.size.y}rem; left: ${highlight.position.x}rem; top: ${highlight.position.y}rem;">
                    <circle style="width: ${Math.min(highlight.size.x, highlight.size.y)}rem; height: ${Math.min(highlight.size.x, highlight.size.y)}rem;"></circle>
                </highlight>
            `).join('')}
        </background>
        <index>
            ${this.highlights.map(highlight => `
                <highlight identifier="${highlight.name}" style="background-image: url('${Asset.getUrl(highlight.image.contentUrl)}');"></highlight>
            `).join('')}
        </index>
    `; }
}

StageChallengeSearch.register();
