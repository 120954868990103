'use strict';

global.UISession = class UISession extends GameElement {
    static get STATE() {
        return {
            PICKER: 0,
            JOIN: 1,
            SESSION: 2,
            SESSIONS: 3
        }
    }

    /**
     * Loads this view
     */
    async load() {
        if(!User.current.isTeacher) { return; }

        if(this._interval) {
            clearInterval(this._interval);
        }

        // Cache scroll position
        this._scrollTop = this.scrollTop || 0;

        if(this._state === UISession.STATE.SESSION) {
            this._users = await Http.get(`/api/sessions/users?id=${this._sessionId}`);

            this._interval = setInterval(() => { this.reload(); }, 1000 * 10);

        } else {
            this._sessions = await Http.get('/api/sessions/list');

        }
    }

    /**
     * Constructor
     */
    constructor(data) {
        super(data);

        this._state = UISession.STATE.PICKER;
    }

    /**
     * Event: Destroy
     */
    onDestroy() {
        // Clear update interval
        if(this._updateInterval) {
            clearInterval(this._updateInterval);
        }
    }

    /**
     * Event: Init
     */
    onReady() {
        // Picker view
        if(this._state === UISession.STATE.PICKER) {
            this.getDescendant('student').onclick = () => {
                this.onPickRole('student');
            };
            
            this.getDescendant('teacher').onclick = () => {
                this.onPickRole('teacher');
            };
            
            this.getDescendant('single').onclick = () => {
                this.onPickRole('student', true);
            };

        // Sessions view
        } else if(this._state === UISession.STATE.SESSIONS) {
            this.getDescendant('new').onclick = (e) => {
                this.onClickNewSession();
            };

            for(let remove of this.getDescendants('remove')) {
                remove.onclick = (e) => {
                    this.onClickRemoveSession(e.currentTarget.getAttribute('session'));
                };
            }
            
            for(let remove of this.getDescendants('view')) {
                remove.onclick = (e) => {
                    this.onClickViewSession(e.currentTarget.getAttribute('session'));
                };
            }

        // Student view
        } else if(User.current.isStudent) {
            this.getDescendant('form').onsubmit = (e) => {
                e.preventDefault();

                this.onJoinSession(e.currentTarget.sessionId.value);
            };
        
        }
        
        // Exit button event listener
        this.getDescendant('exit').onclick = () => { this.onClickExit(); }

        // Restore scroll position
        this.scrollTop = this._scrollTop || 0;
    }

    /**
     * Gets all sessions
     *
     * @return {Array} Sessions
     */
    get sessions() {
        return this._sessions || [];
    }
    
    /**
     * Gets all users for the current session
     *
     * @return {Array} Users
     */
    get users() {
        return this._users || [];
    }
    
    /**
     * Formats a date
     *
     * @param {Number} time
     *
     * @return {String} Date
     */
    formatDate(time) {
        let date = new Date(time);

        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours() < 10 ? date.getHours() + '0' : ''}${date.getHours()}:${date.getMinutes() < 10 ? date.getMinutes() + '0' : ''}${date.getMinutes()}`;
    }

    /**
     * Event: Pick role
     *
     * @param {String} role
     * @param {Boolean} isSinglePlayer
     */
    async onPickRole(role, isSinglePlayer = false) {
        Audio.ui('ui/button-click');

        User.current.role = role;

        if(isSinglePlayer) {
            User.current.sessionId = null;
        }

        await User.current.save();
       
        if(isSinglePlayer) {
            if(!User.current.hasClass()) {
                Router.go('/intro');
            
            } else {
                Router.go('/topics');
            
            }
        
        } else {
            if(User.current.isTeacher) {
                this._state = UISession.STATE.SESSIONS;
            } else {
                this._state = UISession.STATE.JOIN;
            }

            this.reload();

        }
    }

    /**
     * Shows a message
     *
     * @param {String} message
     */
    async showMessage(message) {
        this._message = message;

        await this.reload();

        this._message = '';
    }

    /**
     * Event: Click view session
     */
    async onClickViewSession(id) {
        Audio.ui('ui/button-click');
        
        this._sessionId = id;
        this._state = UISession.STATE.SESSION;

        this.reload();
    }

    /**
     * Event: Click remove session
     */
    async onClickRemoveSession(id) {
        Audio.ui('ui/button-click');
        
        await Http.post(`/api/sessions/remove?id=${id}`);

        this.reload();
    }

    /**
     * Event: Click new session
     */
    async onClickNewSession() {
        Audio.ui('ui/button-click');
        
        await Http.post('/api/sessions/new');

        this.reload();
    }

    /**
     * Event: Join session
     *
     * @param {String} id
     */
    async onJoinSession(id) {
        if(!id) { return; }
       
        this._sessionId = id;

        Audio.ui('ui/button-click');

        let data = await Http.get(`/api/session/exists?id=${id}`);

        if(!data.exists) {
            this.showMessage('session-not-found');
            return;
        }

        User.current.sessionId = id;

        await User.current.save();
    
        if(!User.current.hasClass()) {
            Router.go('/intro');
        
        } else {
            Router.go('/topics');

        }
    }
    
    /**
     * Event: Click exit (back to topics)
     */
    onClickExit() {
        Audio.ui('ui/button-click');
        
        if(this._state === UISession.STATE.SESSION) {
            this._sessionId = null;

            this._state = UISession.STATE.SESSIONS;

            this.reload();

        } else if(this._state === UISession.STATE.SESSIONS || this._state === UISession.STATE.JOIN) {
            this._state = UISession.STATE.PICKER;

            this.reload();

        }
    }

    /**
     * Gets the CSS
     */
    get css() { return `
        :host {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            ${this._state === UISession.STATE.SESSION || this._state === UISession.STATE.SESSIONS ? `
                padding: 6rem 0;
                overflow: auto;
            ` : `
                justify-content: center;
            `}
        }
        
        heading {
            margin-bottom: 4rem;
            font-size: 4rem;
            display: block;
            font-family: SketchNote, sans-serif;
        }
       
        message {
            margin-bottom: 4rem;
            font-size: 1.4rem;
        }

        exit {
            display: ${this._state === UISession.STATE.PICKER ? 'none' : 'block'};
            cursor: pointer;
            position: absolute;
            top: 2rem;
            width: 4rem;
            height: 4rem;
            right: 2rem;
            z-index: 10;
            font-size: 4rem;

            ${Sprite.mapNamedCss('ui', 'close')}
        }

        /* Picker view */
        picker {
            display: flex;
        }
            picker student, picker teacher, picker single {
                ${Sprite.mixin('ui', 'button')}
            }

        /* Session view */
        users {
            line-height: 1.5;
            font-size: 1.4rem;
            margin-top: 2rem;
            width: calc(100% - 2rem);
            max-width: 60rem;
        }

        user {
            display: block; 
        }
            user:not(:last-of-type) {
                padding-bottom: 2rem;
                margin-bottom: 2rem;
            }

            user username {
                font-size: 3rem;
                font-family: SketchNote, sans-serif;
            }

            user answers {
                display: block;
            }
            
            user answer {
                display: block;
            }
                user answer:not(:last-of-type) {
                    padding-bottom: 1rem;
                    border-bottom: 1px solid lightgrey;
                    margin-bottom: 1rem;
                }
                
                user answer label {
                   font-weight: bold;
                   display: block;
                   margin-bottom: 1rem;
                }
                
                user answer text {
                   display: block;
                }

        /* Sessions list */
        sessions {
            display: block;
            line-height: 2;
            font-size: 1.4rem;
            margin-top: 2rem;
            width: 100%;
            max-width: 60rem;
        }
        
        session {
            display: flex;
            align-items: center;
            padding: 0 1rem;
            width: 100%;
        }
            session:not(:last-of-type) {
                padding-bottom: 1rem;
                border-bottom: 1px solid black;
                margin-bottom: 1rem;
            }

            session id, session time {
                display: block;
                line-height: 3rem;
            }
           
            session id {
                width: 20rem;
            }

            session time {
                flex-grow: 1;
            }

            session actions {
                display: flex;
            }

            session remove, session view {
                cursor: pointer;
                display: block;
                width: 3rem;
                height: 3rem;
                text-align: center;
                line-height: 3rem;
                font-size: 2rem;
                color: white;
                background-color: var(--color-primary);
                box-shadow: 4px 4px 0 black;
                margin-left: 2rem;
            }

            session remove {
                background-color: var(--color-alert);
            }

        new {
            ${Sprite.mixin('ui', 'button')}
        }
            new:active {
                transform: scale(0.9);
            }

        /* Join session */
        input {
            padding: 0;
            display: block;
            box-sizing: border-box;
            width: 100%;
            text-align: center;
            text-transform: uppercase;
            font-family: SketchNote, sans-serif;
            font-size: 2rem;

            ${Sprite.mixin('ui', 'frame-generic')}
        }

        button {
            margin: 0 auto;
            
            ${Sprite.mixin('ui', 'button')}
        }
            button:active {
                transform: scale(0.9);
            }
    `; }

    /**
     * Gets the HTML
     */
    get html() { return `
        <exit></exit>

        ${this._state === UISession.STATE.PICKER ? `
            <heading>${L10N.translate('pick-role')}</heading>

            ${this._message ? `
                <message>${L10N.translate(this._message)}</message>
            ` : ``}

            <picker>
                <student>${L10N.translate('student')}</student>
                <teacher>${L10N.translate('teacher')}</teacher>
                <single>${L10N.translate('single-player')}</single>
            </picker>

        ` : this._state === UISession.STATE.SESSION ? `
            <heading>${this._sessionId}</heading>
            
            <users>
                ${this.users.map(user => `
                    <user>
                        <username>${user.username}</username>
                        <answers>
                            ${user.answers.map(answer => `
                                <answer>
                                    <label>${answer.label}</label> 
                                    <text>${answer.text}</text> 
                                </answer>
                            `).join('')}
                        </answers>
                    </user>
                `).join('')}
            </users>

        ` : User.current.isTeacher ? `
            <heading>${L10N.translate('session-manage-heading')}</heading>
            
            ${this._message ? `
                <message>${L10N.translate(this._message)}</message>
            ` : ``}

            <new>${L10N.translate('new-session')}</new>

            <sessions>
                ${this.sessions.map(session => `
                    <session>
                        <id>${session.id}</id>
                        <time>${this.formatDate(session.time)}</time>
                        <actions>
                            <view session="${session.id}">&#8505;</view>
                            
                            ${session.teacher === User.current.username? `
                                <remove session="${session.id}">&times;</remove>
                            ` : ``}
                        </actions>
                    </session>
                `).join('')}
            </sessions>

        ` : `
            <heading>${L10N.translate('session-join-heading')}</heading>
            
            ${this._message ? `
                <message>${L10N.translate(this._message)}</message>
            ` : ``}
            
            <form>
                <input value="${this._sessionId || User.current.sessionId || ''}" name="sessionId" placeholder="${L10N.translate('session-join-id')}">
                <button type="submit">${L10N.translate('session-join-confirm')}</button>
            </form>
        `}
    `; }
}

UISession.register();
