'use strict';

global.Tool = class Tool {
    /**
     * Creates a variable safe string
     *
     * @param {String} string
     *
     * @return {String} Safe string
     */
    static toVar(string) {
        return string.toLowerCase().replace(/[^a-z]/g, '');
    }

    /**
     * Detects whether we're on iPad
     *
     * @return {Boolean} Is iPad
     */
    static get isIPad() {
        return (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad';
    }

    /**
     * Detects whether we're on Firefox
     *
     * @return {Boolean} Is Firefox
     */
    static get isFirefox() {
        return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    }
}
