'use strict';

global.TopicSelector = class TopicSelector extends GameElement {
    /**
     * Preload
     */
    async load() {
        Audio.music('menu');        
        
        let preload = [
            '/asset/ui/sprites.svg'
        ];

        this.config = {
            topics: await this.fetch('/api/topic')
        };

        if(Router.path[1]) {
            this._currentTopicId = Router.path[1];
            this._enterTopic = true;

        } else if(Object.keys(this.config.topics).length > 0) {
            this._currentTopicId = Object.keys(this.config.topics)[0];
        
        }
        
        await Asset.preload(preload);
    }

    /**
     * Transition in
     */
    async preTransitionIn() {
        let isFromJournal = Router.previousPath.indexOf('journal') > -1;
        let isFromWorldMap = Router.previousPath.indexOf('worldmap') > -1;
            
        if(isFromJournal) {
            this.setAttribute('from-journal', true);
        }

        if(isFromWorldMap) {
            this.setAttribute('from-world-map', true);
        }
    }

    async transitionIn() {
        await Clock.waitForSeconds(0.1);
    }

    async postTransitionIn() {
        await Clock.waitForSeconds(1);

        this.removeAttribute('from-journal');
        this.removeAttribute('from-world-map');
    }
    
    /**
     * Transition out
     */
    async preTransitionOut() {
        let isToJournal = Router.path.indexOf('journal') > -1;
        let isToWorldMap = Router.path.indexOf('worldmap') > -1;
            
        if(isToJournal) {
            this.setAttribute('to-journal', true);
        }

        if(isToWorldMap) {
            this.setAttribute('to-world-map', true);
        }

        await Clock.waitForSeconds(0.5);
    }
    
    async transitionOut() {
        await Clock.waitForSeconds(1);
    }

    /**
     * Event: Init
     */
    onReady() {
        // Init topics
        this.getDescendants('topic').forEach((topicElement) => {
            let id = topicElement.getAttribute('identifier');
            let topic = this.getTopic(id);

            if(!topic) { return; }

            let enter = topicElement.querySelector('enter');
            enter.onclick = () => { this.onClickEnterTopic(); };
            
            let worldMap = topicElement.querySelector('worldmap enter');
            worldMap.onclick = () => { this.onClickWorldMap(); };
        
            let journal = topicElement.querySelector('journal enter');
            journal.onclick = () => { this.onClickJournal(); };
        });

        // Set up event handlers
        this.getDescendant('menu toggle').onclick = () => { this.onClickMenu(); }
        this.getDescendant('menu logout').onclick = () => { User.current.logout(); }
        this.getDescendant('menu session').onclick = () => { Router.go('/session'); }
        this.getDescendant('menu classselector').onclick = () => { Router.go('/select'); }

        this.getDescendant('back').onclick = () => { this.onClickExitTopic(); }
        
        this.getDescendant('nav previous').onclick = () => { this.onClickPrevious(); }
        this.getDescendant('nav next').onclick = () => { this.onClickNext(); }
        
        // Enter topic if instructed
        if(this._enterTopic) {
            this.enterTopic(true);
        }
    }

    /**
     * Event: Click next
     */
    onClickNext() {
        Audio.ui('ui/button-click');
        Audio.music('menu');        
        
        let topics = Object.keys(this.topics);

        let nextTopicId = topics[this.currentTopicIndex + 1];
        
        if(!nextTopicId) {
            nextTopicId = topics.shift();
        }

        if(!nextTopicId) { return; }
       
        this._previousTopicId = this._currentTopicId;
        this._currentTopicId = nextTopicId;

        this.update();
    }
    
    /**
     * Event: Click previous
     */
    onClickPrevious() {
        Audio.ui('ui/button-click');
        Audio.music('menu');        
        
        let topics = Object.keys(this.topics);
        
        let nextTopicId = topics[this.currentTopicIndex - 1];
        
        if(!nextTopicId) {
            nextTopicId = topics.pop();
        }

        if(!nextTopicId) { return; }

        this._previousTopicId = this._currentTopicId;
        this._currentTopicId = nextTopicId;

        this.update();
    }

    /**
     * Event: Click enter
     */
    onClickEnterTopic() {
        Audio.ui('ui/button-click');
        Audio.music('menu');        
        
        this.enterTopic();
    }
    
    /**
     * Event: Click exit topic
     */
    onClickExitTopic() {
        Audio.ui('ui/button-click');
        Audio.music('menu');        
        
        this._isOpen = false;
        this.update();
    }
      
    /**
     * Event: Click menu
     */
    onClickMenu() {
        Audio.ui('ui/button-click');
        Audio.music('menu');        
        
        this._isMenuOpen = !this._isMenuOpen;

        this.update();
    }

    /**
     * Event: Click world map
     */
    onClickWorldMap() {
        if(!this._currentTopicId) { throw new Error('No current topic id'); }
        
        Audio.ui('ui/button-click');
        
        let topic = this.getTopic(this._currentTopicId);

        if(!topic || !topic.maps || Object.values(topic.maps).length < 1) { return; }

        Router.go(`/topic/${topic.identifier}/worldmap`);
    }

    /**
     * Event: Click journal
     */
    onClickJournal() {
        if(!this._currentTopicId) { throw new Error('No current topic id'); }
        
        Audio.ui('ui/button-click');
        
        let topic = this.getTopic(this._currentTopicId);

        if(!topic || !topic.maps || Object.values(topic.maps).length < 1) { return; }

        Router.go(`/topic/${topic.identifier}/map/${Object.values(topic.maps)[0].identifier}/journal`);
    }

    /**
     * Enters a topic
     *
     * @param {Boolean} skipTransition
     */
    enterTopic(skipTransition = false) {
        if(!this._currentTopicId) { throw new Error('No current topic id'); }

        this._skipTransition = skipTransition;

        this._isOpen = true;
        this.update();

        let video = this.getDescendant(`topic[identifier="${this._currentTopicId}"] active video`);

        if(video) {
            video.currentTime = 0;

            setTimeout(() => {
                video.play();
            }, this._skipTransition ? 0 : 500);
        }

        this._skipTransition = false;
    }

    /**
     * Gets a topic by id
     *
     * @param {String} id
     *
     * @return {Object} Topic
     */
    getTopic(id) {
        return this.topics[id];
    }

    /**
     * Gets the current topic index
     *
     * @return {Number} Index
     */
    get currentTopicIndex() {
        return Object.keys(this.topics).indexOf(this._currentTopicId);
    }
    
    /**
     * Gets the previous topic index
     *
     * @return {Number} Index
     */
    get previousTopicIndex() {
        return Object.keys(this.topics).indexOf(this._previousTopicId);
    }

    /**
     * Gets the topics
     *
     * @return {Array} Topics
     */
    get topics() {
        return this.config.topics || {};
    }

    /**
     * Gets the HTML
     */
    get html() { return `
        <back></back>
        <ge-uimusictoggle></ge-uimusictoggle>
        <menu>
            <toggle></toggle>
            <top>
                <classselector>${L10N.translate('class-selector')}</classselector>
            </top>
            <bottom>
                <session>${L10N.translate('session')}</session>
                <logout>${L10N.translate('logout')}</logout>
            </bottom>
        </menu>
        ${Object.values(this.topics).length > 0 ? `
            <navigation>
                <previous></previous>
                <next></next>
            </navigation>
        ` : ``}
        <topics>
            ${Object.values(this.topics).map(topic => `
                <topic identifier="${topic.identifier}">
                    <idle>
                        ${topic.selector.idleVideoBackground ? `
                            <video autoplay loop muted src="${topic.selector.idleVideoBackground.contentUrl}"></video>
                        ` : ``}
                        <intro>
                            <name>${topic.name}</name>
                            <description>${topic.description}</description>
                            <enter>${L10N.translate('topic-selector-enter')}</enter>
                        </intro>
                        ${topic.selector.greeting && topic.selector.greeting.text ? `
                            <greeting ${topic.selector.greeting.position || 'up'}>
                                ${topic.selector.greeting.text}
                            </greeting>
                        ` : ``}
                    </idle>
                    <active>
                        ${topic.selector.activeVideoBackground ? `
                            <video muted src="${topic.selector.activeVideoBackground.contentUrl}"></video>
                        ` : ``}
                        <worldmap>
                            <name>${L10N.translate('world-map')}</name>
                            <description>${L10N.translate('topic-selector-world-map-description')}</description>
                            <enter>${L10N.translate('topic-selector-enter')}</enter>
                        </worldmap>
                        <journal>
                            <name>${L10N.translate('journal')}</name>
                            <description>${L10N.translate('topic-selector-journal-description')}</description>
                            <enter>${L10N.translate('topic-selector-enter')}</enter>
                        </journal>
                    </active>
                </topic>
            `).join('')}
        </topics>
        <nav>
            <previous></previous>
            <next></next>
        </nav>
        <ge-uidisclaimer left></ge-uidisclaimer>
    `; }

    /**
     * Gets the CSS
     */
    get css() { return `
        :host {
            transition: opacity 0.5s ease;
        }
            :host([transitioning]) {
                opacity: 0;
            }

        /* Back button */
        back {
            display: ${this._isOpen ? 'block' : 'none'};
            position: absolute;
            top: 2rem;
            width: 4rem;
            height: 4rem;
            cursor: pointer;
            left: 2rem;
            z-index: 20;
            
            ${Sprite.mapNamedCss('ui', 'up-arrow')};
        }

        /* Menu */
        menu {
            margin: 0;
            transition: transform 0.5s ease;
            background-color: white;
            position: absolute;
            padding: 0;
            top: -1rem;
            left: -1rem;
            display: ${this._isOpen ? 'none' : 'block'};
            z-index: 300;
            transform: ${this._isMenuOpen ? 'none' : 'translateX(-100%)'};
            width: 30rem;
            height: calc(100% + 2rem);

            ${Sprite.mixin('ui', 'frame-generic')}
        }
            menu toggle {
                position: absolute;
                top: 3rem;
                width: 4rem;
                height: 4rem;
                display: block;
                cursor: pointer;
                
                ${this._isMenuOpen ? `
                    right: 2rem;
                ` : `
                    left: calc(100% + 3rem);
                `}

                ${Sprite.mapNamedCss('ui', this._isMenuOpen ? 'close' : 'menu')};
            }

            menu top {
                position: absolute;
                font-family: SketchNote, sans-serif;
                font-size: 3rem;
                display: block;
                left: 4rem;
                top: 10rem;
            }
                menu top > * {
                    display: block;
                    cursor: pointer;
                    line-height: 1.5;
                }

            menu bottom {
                position: absolute;
                display: block;
                left: 4rem;
                font-size: 2rem;
                bottom: 4rem;
            }
                menu bottom > * {
                    display: block;
                    cursor: pointer;
                    line-height: 1.5;
                    font-weight: bold;
                }

        /* Topics */
        topics {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
        
        topic {
            width: 100%;
            height: 100%;
            flex-shrink: 0;
            position: absolute;
            overflow: hidden;
            left: 0;
            top: 0;
            display: block;
            transition: transform 0.5s linear;
            background-color: white;
            opacity: 0;
        }
            /* Carousel position */
            ${this.currentTopicIndex === 0 ? `
                topic:last-child {
                    transform: translateX(-100%);
                }
            ` : `
                topic:nth-child(${this.currentTopicIndex}) {
                    transform: translateX(-100%);
                }
            `}

            ${this.currentTopicIndex === Object.keys(this.topics).length - 1 ? `
                topic:first-child {
                    transform: translateX(100%);
                }
            ` : `
                topic:nth-child(${this.currentTopicIndex + 2}) {
                    transform: translateX(100%);
                }
            `}

            topic:nth-child(${this.currentTopicIndex + 1}) {
                transform: none;
                opacity: 1;
            }
            
            topic:nth-child(${this.previousTopicIndex + 1}) {
                opacity: 1;
            }

            /* Active & idle */
            topic idle, topic active {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                transition: ${this._skipTransition ? 'none' : 'transform 1s ease'};
            }
                topic idle video, topic active video {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

            /* Idle */
            topic idle {
                transform: none;
            }
                ${this._isOpen ? `
                    topic[identifier="${this._currentTopicId}"] idle {
                        transform: translateY(-100%);
                    }
                ` : ``}
                
                topic idle greeting {
                    display: block;
                    position: absolute;
                    font-size: 2rem;
                    max-width: 24rem;
                    padding: 2rem;
                    background-color: white;

                    ${Sprite.mixin('ui', 'frame-generic')}
                }
                    topic idle greeting::after {
                        display: block;
                        width: 6rem;
                        height: 6rem;
                        content: '';
                        position: absolute;
                        left: calc(100% - 0.3rem);
                    }

                    topic idle greeting[up] {
                        top: 10rem;
                        left: 40rem;
                    }
                        topic idle greeting[up]::after {
                            bottom: 0;
                            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M0,30 L60,80 L0,60" stroke="black" fill="white" stroke-width="3"></path></svg>');
                        }
                    
                    topic idle greeting[down] {
                        top: 50rem;
                        left: 20rem;
                    }
                        topic idle greeting[down]::after {
                            top: 0;
                            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M0,30 L60,20 L0,60" stroke="black" fill="white" stroke-width="3"></path></svg>');
                        }

                topic idle intro {
                    position: absolute;
                    display: block;
                    left: 16rem;
                    bottom: 27rem;
                }
                    topic idle intro name {
                        display: block;
                        font-family: SketchNote, sans-serif;
                        font-size: 8rem;
                        margin-bottom: 2rem;
                        max-width: 40rem;
                    }
                    
                    topic idle intro description {
                        display: block;
                        margin-bottom: 4rem;
                        font-size: 2rem;
                    }

                    topic idle intro enter {
                        margin-left: -2rem;
                        
                        ${Sprite.mixin('ui', 'button')};
                    }
                        topic idle intro enter:active {
                            transform: scale(0.9);
                        }

            /* Active */
            topic active {
                transform: translateY(100%);
            }
                ${this._isOpen ? `
                    topic[identifier="${this._currentTopicId}"] active {
                        transform: none;
                    }
                ` : ``}
               
                /* Video transition */
                topic active video {
                    transition: transform 1s ease;
                }
                    :host([from-journal]) topic active video,
                    :host([to-journal]) topic active video {
                        transform-origin: 30% 70%;
                    }
                    
                    :host([from-world-map]) topic active video,
                    :host([to-world-map]) topic active video {
                        transform-origin: 70% 20%;
                    }

                    :host([transitioning="in"][from-journal]) topic active video,
                    :host([transitioning="out"][to-journal]) topic active video,
                    :host([transitioning="in"][from-world-map]) topic active video,
                    :host([transitioning="out"][to-world-map]) topic active video {
                        transform: scale(1.4);
                    }
            
                /* World map & journal */
                topic active worldmap, topic active journal {
                    display: block;
                    position: absolute;
                    max-width: 30rem;
                    transition: opacity 0.5s ease;
                }
                    :host([to-world-map]) topic active worldmap,
                    :host([to-journal]) topic active worldmap,
                    :host([from-world-map]) topic active worldmap,
                    :host([from-journal]) topic active worldmap,
                    :host([to-world-map]) topic active journal,
                    :host([to-journal]) topic active journal,
                    :host([from-world-map]) topic active journal,
                    :host([from-journal]) topic active journal {
                        opacity: 0;
                    }

                    topic active worldmap name,
                    topic active journal name {
                        font-family: SketchNote, sans-serif;
                        font-size: 4rem;
                        display: block;
                        margin-bottom: 2rem;
                        white-space: nowrap;
                    }
                    
                    topic active worldmap description,
                    topic active journal description {
                        font-size: 2rem;
                        margin-bottom: 4rem;
                    }

                    topic active worldmap enter,
                    topic active journal enter {
                        position: relative;
                        margin-left: -2rem;
                        
                        ${Sprite.mixin('ui', 'button')};
                    }
                        topic active worldmap enter:active,
                        topic active journal enter:active {
                            transform: scale(0.9);
                        }
                
                /* World map */
                topic active worldmap {
                    top: 10rem;
                    left: 20rem;
                }
                    topic active worldmap enter {
                        left: 10rem;
                    }
                
                /* Journal */
                topic active journal {
                    bottom: 4rem;
                    right: 10rem;
                }
                    topic active journal enter {
                        left: -6rem;
                    }


        /* Navigation */
        nav {
            position: absolute;
            top: calc(50% - 4rem);
            left: 0;
            display: ${this._isOpen ? 'none' : 'block'};
            width: 100%;
            z-index: 20;
        }
            nav next,
            nav previous {
                display: block;
                position: absolute;
                top: 0;
                height: 8rem;
                width: 8rem;
                cursor: pointer;
                transition: transform 0.25 ease;
            }
                nav next:active,
                nav previous:active {
                    transform: scale(0.9);
                }

            nav next {
                right: 2rem;

                ${Sprite.mapNamedCss('ui', 'button-right')}
            }
            
            nav previous {
                left: 2rem;
                
                ${Sprite.mapNamedCss('ui', 'button-left')}
            }
    `; }
}

TopicSelector.register();
