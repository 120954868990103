'use strict';

global.UIClassSelector = class UIClassSelector extends GameElement {
    /**
     * Loads this element
     */
    async load() {
        let preload = [
            [ '/asset/class-selector/class-selector-background.png', 100 ],
            [ '/asset/class-selector/class-selector-girl.png', 20 ],
            [ '/asset/class-selector/class-selector-robo.png', 20 ],
            [ '/asset/class-selector/class-selector-quarterback.png', 20 ],
        ];

        if(!this.config.skipVideos) {
            preload = preload.concat([
                '/asset/class-selector/class-selector-intro-1.mp4',
                '/asset/class-selector/class-selector-intro-2-girl.mp4',
                '/asset/class-selector/class-selector-intro-2-robo.mp4',
                '/asset/class-selector/class-selector-intro-2-quarterback.mp4'
            ]);
        }

        await Asset.preload(preload);
    }

    /**
     * Event: Init
     */
    onReady() {
        // Step 0 events
        this.getDescendant('step[step="0"] start').onclick = () => {
            this.goToStep(1);
        };
        
        // Step 1 events
        this.getDescendant('step[step="1"] video').onended = () => {
            this.showNextButton = true;
            this.update();
        };
        
        // Step 2 events
        for(let button of this.getDescendants('step[step="2"] class pick')) {
            button.onclick = (e) => {
                this.onSelectClass(e.currentTarget.parentElement.getAttribute('name'));
            };
        };
        
        // Step 3 events
        this.getDescendant('step[step="3"] video').onended = () => {
            this.showNextButton = true;
            this.update();
        };
    
        // Next button
        this.getDescendant('next').onclick = () => {
            this.goToStep((this.step || 0) + 1);
            Audio.ui('ui/button-click');
        };

        Input.watch(this);
        Audio.music(null);

        if(this.config.skipVideos) {
            this.goToStep(2);
        } else {
            this.goToStep(1);
        }
    }

    /**
     * Event: Select class
     *
     * @param {String} name
     */
    onSelectClass(name) {
        if(!name) { return; }

        User.current.className = name;

        User.current.save();
      
        if(this.config.skipVideos) {
            this.goToStep(4);
        } else {
            this.goToStep(3);
        }
    }

    /**
     * Moves to a step
     *
     * @param {Number} step
     */
    async goToStep(step) {
        if(step > 3) {
            Router.go('/topics');
            return;
        }

        this.step = step;

        this.showNextButton = false;

        this.update();

        switch(step) {
            case 1:
                try {
                    await this.getDescendant('step[step="1"] video').play();

                } catch(e) {
                    this.goToStep(0);

                }

                break;

            case 2:
                this.getDescendant('step[step="1"] video').pause();
                break;

            case 3:
                this.getDescendant('step[step="3"] video').src = `/asset/class-selector/class-selector-intro-2-${User.current.getClass()}.mp4`;
                this.getDescendant('step[step="3"] video').play();
                break;
        }
    }

    /**
     * Event: Key down
     *
     * @param {InputEvent} e
     */
    onKeyDown(e) {
        if(this._isDestroyed) { return; }

        if(e.key === 'Tab') {
            this.goToStep((this.step || 0) + 1);
        }
    }
    
    /**
     * Gets the CSS
     */
    get css() { return `
        :host {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
   
        step {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
            step[step="0"] { display: ${this.step ? 'none' : 'block'}; }
            step[step="1"] { display: ${this.step === 1 ? 'block' : 'none'}; }
            step[step="2"] { display: ${this.step === 2 ? 'block' : 'none'}; }
            step[step="3"] { display: ${this.step === 3 ? 'block' : 'none'}; }

            step[step="2"] {
                background-image: url(${Asset.getUrl('/asset/class-selector/class-selector-background.png')});
            }

            step start {
                margin: 0 2rem;
                position: absolute;
                left: calc(50% - 10rem);
                top: calc(50% - 5rem);
                
                ${Sprite.mixin('ui', 'button')}
            }
                step start:active {
                    transform: scale(0.9);
                }

            step video {
                position: absolute;
                z-index: 10;
                display: block;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

        class {
            display: block;
            position: absolute;
        }
            class[name="quarterback"] {
                width: 36.6rem;
                height: 36.6rem;
                left: 0rem;
                top: 26.4rem;
            }
            
            class[name="girl"] {
                width: 27.9rem;
                height: 27.9rem;
                left: 38.1rem;
                top: 36.6rem;
            }
            
            class[name="robo"] {
                width: 23.7rem;
                height: 23.7rem;
                left: 68.3rem;
                top: 37.1rem;
            }

            class media {
                position: relative;
                display: block;
            }

            class img {
                width: 100%;
                display: block;
            }
        
            class pick {
                margin: 0 auto;
                
                ${Sprite.mixin('ui', 'button')}
            }
                class pick:active {
                    transform: scale(0.9);
                }
        
        /* Next button */
        next {
            position: absolute;
            display: block;
            pointer-events: ${this.showNextButton ? 'all' : 'none'};
            opacity: ${this.showNextButton ? '1' : '0'};
            transform: ${this.showNextButton ? 'none' : 'translateX(-50%)'};
            transition: opacity 0.25s ease, transform 0.25s ease;
            right: 2rem;
            bottom: 2rem;
            font-size: 1.5rem;
            font-weight: bold;
            font-style: italic;
            color: white;
            cursor: pointer;
            z-index: 180;
            line-height: 10rem;
            text-align: center;
            width: 20rem;
            height: 10rem;
            user-select: none;
            ${Sprite.mapNamedCss('ui', 'button-arrow-right')}
        }
    `; }

    /**
     * Gets the HTML
     */
    get html() { return `
        <step step="0">
            <start>${L10N.translate('class-selector-start')}</start>
        </step>
        <step step="1">
            <video src="/asset/class-selector/class-selector-intro-1.mp4">
        </step>
        <step step="2"<>
            ${[ 'girl', 'quarterback', 'robo' ].map(name => `
                <class name="${name}">
                    <media>
                        <img src="/asset/class-selector/class-selector-${name}.png">
                    </media>
                    <pick>${L10N.translate('select')}</pick>
                </class>
            `).join('')}
        </step>
        <step step="3">
            <video>
        </step>
        <next>${L10N.translate('class-selector-proceed')}</next>
    `; }
}

UIClassSelector.register();
