'use strict';

let pressedKeys = {};
let watchedElements = [];

global.Input = class Input {
    /**
     * Init
     */
    static init() {
        window.addEventListener('keydown', (e) => {
            this.onKeyDown(e);
        });
        
        window.addEventListener('keyup', (e) => {
            this.onKeyUp(e);
        });

        window.addEventListener('blur', () => {
            this.onBlur();
        });
    }

    /**
     * Adds an element to the watch list
     *
     * @param {GameElement} element
     */
    static watch(element) {
        if(watchedElements.indexOf(element) > -1) { return; }

        watchedElements.push(element);
    }

    /**
     * Gets available watched elements
     *
     * @return {Array} Watched elements
     */
    static get watchedElements() {
        for(let i = watchedElements.length - 1; i >= 0; i--) {
            if(watchedElements[i]._isDestroyed) {
                watchedElements.splice(i, 1);
            }
        }

        return watchedElements;
    }

    /**
     * Event: Blur
     */
    static onBlur() {
        pressedKeys = [];
    }

    /**
     * Event: Key down
     *
     * @param {InputEvent} event
     */
    static onKeyDown(event) {
        pressedKeys[event.code] = Date.now();

        for(let element of this.watchedElements) {
            if(typeof element.onKeyDown !== 'function') { continue; }

            element.onKeyDown(event);
        }
    }
    
    /**
     * Event: Key up
     *
     * @param {InputEvent} event
     */
    static onKeyUp(event) {
        delete pressedKeys[event.code];

        for(let element of this.watchedElements) {
            if(typeof element.onKeyUp !== 'function') { continue; }

            element.onKeyUp(event);
        }
    }

    /**
     * Gets wheter a key is pressed
     *
     * @param {String} code
     * @param {Number} duration
     *
     * @return {Boolean} Is pressed
     */
    static isKeyPressed(code, duration = 0) {
        if(!pressedKeys[code]) { return false; }
        if(duration && Date.now() - pressedKeys[code] < duration) { return false; }

        return true;
    }
}

Input.init();
