'use strict';

global.TopicJournalEnvelope = class TopicJournalEnvelope extends GameElement {
    /**
     * Event: Init
     */
    onReady() {
        this.getDescendant('open').onclick = (e) => {
            this.onClickOpen();
        };
        
        this.getDescendant('close').onclick = (e) => {
            this.onClickClose();
        };

        this.getDescendant('send').onclick = (e) => {
            this.onClickSend();
        };

    }
    
    /**
     * Gets the id of this topic
     */
    get topicId() {
        return Router.path[1];
    }
    
    /**
     * Gets the id of this map
     */
    get mapId() {
        return Router.path[3];
    }
    
    /**
     * Event: Click open
     */
    onClickOpen() {
        this.getDescendant('dialog').setAttribute('open', true);
    }
            
    /**
     * Event: Click close
     */
    onClickClose() {
        this.getDescendant('dialog').removeAttribute('open');
    }
    
    /**
     * Event: Click send
     */
    onClickSend() {
        let textarea = this.getDescendant('textarea');

        this.onClickClose();

        User.current.setFinalQuestionAnswer(this.topicId, this.mapId, textarea.value);
    }
    
    /**
     * Gets the question
     */
    get question() {
        let map = this.config.map;

        if(!map || !map.description) {
            return '[question]';
        }

        return map.description;
    }

    /**
     * Gets the CSS
     */
    get css() { return `
        open {
            display: block;
            bottom: -3rem;
            right: -1rem;
            width: 30rem;
            height: 20rem;
            transform: rotate(-30deg) translate(15%, 30%);
            position: absolute;
            z-index: 20;
            cursor: pointer;
            transition: transform 0.5s ease;
            ${Sprite.mapNamedCss('ui', 'envelope')}
        }

        open text {
            font-size: 1.5rem;
            font-weight: bold;
            text-align: center;
            font-style: italic;
            display: block;
            line-height: 1.5;
            max-width: 18rem;
            text-align: center;
            margin: 3rem auto 0 auto;
        }

        dialog[open] + open {
            transform: rotate(-30deg) translate(15%, 120%);
        }

        dialog {
            display: block;
            pointer-events: none;
            border: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            opacity: 0;
            background-color: rgba(0, 0, 0, 0.5);
            transition: opacity 0.5s ease;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 200;
        }

        dialog[open] {
            opacity: 1;
            pointer-events: all;
        }

        content {
            transition: transform 0.5s ease;
            transform: translateY(40rem);
            display: block;
            width: 60rem;
            height: 60rem;
            position: relative;
            top: -6rem;
        }

        dialog[open] content {
            transform: none;
        }

        foreground, middleground, background {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        foreground {
            z-index: 20;
            pointer-events: none;
            ${Sprite.mapNamedCss('ui', 'envelope-foreground')}
        }
        
        middleground {
            z-index: 10;
            box-sizing: border-box;
            top: 10rem;
            left: 10rem;
            height: 40rem;
            width: 40rem;
            background-color: white;
            padding: 2rem;

            ${Sprite.mixin('ui', 'frame-generic')}
        }

        background {
            z-index: 0;
            ${Sprite.mapNamedCss('ui', 'envelope-background')}
        }

        textarea {
            display: block;
            width: 100%;
            resize: none;
            padding: 2rem;
            background-color: lightgrey;
            box-sizing: border-box;
            height: 24rem;
            margin-top: 2rem;

            ${Sprite.mixin('ui', 'frame-generic')}
        }

        send {
            pointer-events: all;
            margin: 2rem 0 0 auto;
            position: absolute;
            bottom: 4rem;
            left: 20rem;

            ${Sprite.mixin('ui', 'button')}
        }
            send:active {
                transform: scale(0.9);
            }

        close {
            width: 3rem;
            height: 3rem;
            display: block;
            position: absolute;
            top: 1rem;
            right: 1rem;
            cursor: pointer;
            ${Sprite.mapNamedCss('ui', 'close')}
        }

        heading {
            display: block;
            padding-right: 3rem;
            font-size: 2rem;
            font-family: SketchNote, sans-serif;
        }
    `; }

    /**
     * Gets the HTML
     */
    get html() { return `
        <dialog>
            <content>
                <background></background>
                <middleground>
                    <close></close>
                    <heading>${this.question}</heading>
                    <textarea>${User.current.getFinalQuestionAnswer(this.topicId, this.mapId)}</textarea>
                </middleground>
                <foreground>
                    <send>${L10N.translate('send')}</send>
                </foreground>
            </content>
        </dialog>
        <open>
            <text>${L10N.translate('topic-journal-answer')}</text>    
        </open>
    `; }
}

TopicJournalEnvelope.register();
