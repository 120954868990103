'use strict';

const FRAME_RATE = 18;

global.KeyframeSprites = class KeyframeSprites extends GameElement {
    /**
     * Event: Draw
     */
    onDraw(context) {
        let animation = this.animations[this.currentAnimation];

        if(!animation) { return; }

        let frame = this.currentAnimationFrames[this._frameIndex];

        if(!frame) { return; }

        let transform = this.globalTransform;

        if(this.isFlipped) {
            Canvas.flip(true, false);
        }

        Canvas.drawPositionedSprite(
            this.map,
            frame[0],
            frame[1],
            transform.position.x,
            transform.position.y,
            transform.size.x,
            transform.size.y
        ); 

        if(this.isFlipped) {
            Canvas.unflip();
        }
    }
    
    /**
     * Event: Tick
     */
    onTick(delta) {
        if(!this._frameIndex) {
            this._frameIndex = 0;
        }

        this._frameTimer = (this._frameTimer || 0) + delta * FRAME_RATE;

        if(this._frameTimer >= 1) {
            this._frameIndex++;

            // Animation has ended
            if(this._frameIndex >= this.currentAnimationFrames.length) {
                if(this.isCurrentAnimationLooping) {
                    this._frameIndex = 0;
            
                } else if(this._nextAnimation) {
                    this.play(this._nextAnimation);
                    this._nextAnimation = null;

                } else {
                    this._frameIndex = this.currentAnimationFrames.length - 1;

                    this.stop();
                }
            }

            this._frameTimer = 0;
        }
    }

    /**
     * Gets the sprite map name
     *
     * @return {String} Map name
     */
    get map() {
        if(this.config && this.config.map) {
            return this.config.map;
        }

        return '';
    }

    /**
     * Adds an animation
     *
     * @param {String} name
     * @param {Array} frames
     * @param {Boolean} loop
     */
    add(name, frames, loop = true) {
        if(!this.animations) {
            this.animations = {};
        }

        this.animations[name] = {
            frames: frames,
            loop: loop
        };
    }

    /**
     * Plays an animation by name
     *
     * @param {String} name
     */
    play(name) {
        if(!this.animations || !this.animations[name]) {
            Debug.error(this, new Error(`Animation "${name}" not found`));
            return;
        }

        if(this._currentAnimation === name) { return; }

        this._currentAnimation = name;
        this._frameIndex = 0;
        
        this.update();
    }

    /**
     * Queues an animation to play after the current one is finished
     *
     * @param {String} name
     */
    queue(name) {
        this._nextAnimation = name;
    }

    /**
     * Stops animation playback
     */
    stop() {
        this._isStopped = true;
        this._currentAnimation = '';

        this.dispatchEvent(new Event('stop'));
    }

    /**
     * Gets the current animation
     *
     * @return {String} Current animation
     */
    get currentAnimation() {
        return this._currentAnimation;
    }
    
    /**
     * Gets the current animation's frames
     *
     * @return {Array} Frames
     */
    get currentAnimationFrames() {
        if(!this.animations || !this.animations[this.currentAnimation]) { return []; }

        return this.animations[this.currentAnimation].frames;
    }

    /**
     * Gets whether the current animation is looping
     *
     * @return {Boolean} Is looping
     */
    get isCurrentAnimationLooping() {
        if(!this.animations || !this.animations[this.currentAnimation]) { return false; }

        return this.animations[this.currentAnimation].loop || false;
    }

    /**
     * Gets whether playback is running
     *
     * @return {Boolean} Is playing
     */
    get isPlaying() {
        return !this._isStopped;
    }
}

KeyframeSprites.register();
