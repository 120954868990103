'use strict';

const PanZoom = require('panzoom');

global.StageShowcaseImages = class StageShowcaseImages extends StageShowcase {
    /**
     * Get preloaded assets
     */
    async getPreloadedAssets(config) {
        let preload = [
            '/asset/ui/sprites.svg'
        ];

        if(config.images) {
            for(let element of config.images.itemListElement) {
                if(!element.item) { continue; }

                preload.push([ element.item, 100 ]);
            }
        }

        return super.getPreloadedAssets(config).concat(preload);
    }
    
    /**
     * Event: Init
     */
    onReady() {
        super.onReady();

        // Set up zoomable images
        setTimeout(() => {
            this.getDescendants('zoomables img').forEach((element) => {
                new PanZoom(element, {
                    smoothScroll: false,
                    bounds: true,
                    boundsPadding: 0.1,
                    maxZoom: 10,
                    minZoom: 1
                });
            });
        }, 100);
        
        // Activate tutorial if needed
        if(!User.current.getFlag('tutorial-stage-showcase-images')) {
            this.appendChild(new UITutorialDialog({
                images: Settings.showcaseImagesTutorial,
                flag: 'tutorial-stage-showcase-images'
            }));
        }
    }

    /**
     * Gets the id of an event
     *
     * @param {InputEvent} e
     *
     * @return {String} Id
     */
    getEventId(e) {
        return e.pointerId || e.button;
    }

    /**
     * Gets all images 
     *
     * @return {Array} Images
     */
    get images() {
        if(!this.config || !this.config.images) { return []; }

        let images = [];

        for(let element of this.config.images.itemListElement) {
            if(!element.item) { continue; }

            images.push(element.item);
        }

        return images;
    }

    /**
     * Gets the CSS
     */
    get css() { return super.css + `
        :host {
            background-color: white;
        }

        debug {
            display: block;
            font-size: 2rem;
            right: 4rem;
            top: 4rem;
            position: absolute;
            pointer-events: none;
            z-index: 400;
        }
        
        zoomables {
            display: flex;
            position: absolute;
            left: 4rem;
            top: 4rem;
            width: calc(100% - 8rem);
            height: calc(100% - 8rem);
        }

        panzoom {
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: 50%;
            display: block;
            height: 100%;
            overflow: hidden;
            outline: none !important;
        }

        panzoom:not(:first-child) {
            margin-left: 1rem;
        }
        
        panzoom:not(:last-child) {
            margin-right: 1rem;
        }

        panzoom img {
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: contain;
            pointer-events: none;
            outline: none !important;
            user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
        }
    `; }

    /**
     * Gets the HTML
     */
    get html() { return super.html + `
        <debug></debug>

        <zoomables>
            ${this.images.map(image => `
                <panzoom>
                    <img src="${Asset.getUrl(image)}">
                </panzoom>
            `).join('')}
        </zoomables>
    `; }
}

StageShowcaseImages.register();
