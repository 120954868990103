'use strict';

/**
 * An all-pupose game element
 */
global.GameElement = class GameElement extends HTMLElement {
    /**
     * Constructor
     *
     * @param {String|Object} data
     */
    constructor(data) {
        super();
       
        this.transform = {
            position: new Vector(0, 0),
            size: new Vector(10, 10)
        };

        this.init(data);
    }

    /**
     * Destroys this element
     */
    async destroy() {
        for(let child of this.getDescendants('*')) {
            if(typeof child.destroy === 'function') {
                child.destroy();
            } else {
                child.parentNode.removeChild(child);
            }
        }

        if(!this.parentNode) { return; }

        this.parentNode.removeChild(this);

        this._isDestroyed = true;
        
        if(typeof this.onDestroy === 'function') {
            this.onDestroy();
        }
        
        this.dispatchEvent(new Event('destroy'));
    }

    /**
     * Gets a copy of the transform
     *
     * @return {Object} Transform
     */
    getTransform() {
        return {
            position: this.transform.position.clone(),
            size: this.transform.size.clone(),
        }
    }

    /**
     * Hides this element
     */
    hide() {
        this._isHidden = true;
        
        this.setAttribute('hidden', true);
    }
    
    /**
     * Shows this element
     */
    show() {
        this._isHidden = false;

        this.removeAttribute('hidden');
    }
        
    /**
     * Appends a child element to the shadow root
     *
     * @param {HTMLElement} element
     */
    appendChild(element) {
        this.rootElement.appendChild(element);
    }

    /**
     * Adopts the offset as the transform
     */
    adoptTransformFromOffset() {
        requestAnimationFrame(() => {
            this.transform.position.x = this.offsetLeft / Viewport.worldUnit;
            this.transform.position.y = this.offsetTop / Viewport.worldUnit;
            this.transform.size.x = this.offsetWidth / Viewport.worldUnit;
            this.transform.size.y = this.offsetHeight / Viewport.worldUnit;
        });
    }
    
    /**
     * Adopts the bounds as the transform
     */
    adoptTransformFromBounds() {
        requestAnimationFrame(() => {
            let bounds = this.getBoundingClientRect();

            this.transform.position.x = bounds.x / Viewport.worldUnit;
            this.transform.position.y = bounds.y / Viewport.worldUnit;
            this.transform.size.x = bounds.width / Viewport.worldUnit;
            this.transform.size.y = bounds.height / Viewport.worldUnit;
        });
    }

    /**
     * Loads the model data into memory
     *
     * @param {String|Object} data
     */
    async init(data) {
        this.rootElement = this.attachShadow({mode: 'open'});

        this.config = {};
        
        if(typeof data === 'string') {
            try {
                this.config = await this.fetch(data);

            } catch(e) {
                Debug.error(this, e);
            
            }
        
        } else if(data) {
            this.config = data;

        }
        
        await this.reload();

        Clock.add(this);
        Canvas.add(this, this.layer);
    }

    /**
     * Reloads this element
     */
    async reload() {
        this._isReady = false;
        
        if(typeof this.load === 'function') {
            await this.load();
        }

        this.render();

        this._isReady = true;

        this.onReady();
        this.dispatchEvent(new Event('ready'));
    }

    /**
     * Waits for the element to be ready
     */
    async waitUntilReady() {
        if(this._isReady) { return; }

        await new Promise((resolve) => {
            this.addEventListener('ready', resolve);
        });
    }

    /**
     * Renders the element
     */
    render() {
        let css = this.css || '';
        let html = this.html || '';
       
        this.rootElement.innerHTML = `${css ? `<style>${css}</style>` : ''}${html}`;
        this.styleElement = this.rootElement.querySelector('style');
    }

    /**
     * Fetches the model data
     */
    async fetch(url) {
        return await Http.get(url);
    }

    /**
     * Event: Ready
     */
    onReady() {}

    /**
     * Updates the CSS
     */
    update() {
        if(!this.styleElement) { return; }

        this.styleElement.innerHTML = this.css;
    }

    /**
     * Gets the layer of this element
     *
     * @return {Number} Layer
     */
    get layer() {
        if(this.config.layer) {
            return this.config.layer;
        }

        if(this.parentElement) {
            return this.parentElement.layer || 0;
        }

        return 0;
    }

    /**
     * Gets the parent element via shadow root
     *
     * @return {HTMLElement} Parent
     */
    get parentElement() {
        if(super.parentElement) {
            return super.parentElement;
        }

        if(this.parentNode instanceof ShadowRoot) {
            return this.parentNode.host;
        }

        return null;
    }

    /**
     * Gets the global transform
     *
     * @return {Object} Transform
     */
    get globalTransform() {
        let position = { x: this.transform.position.x, y: this.transform.position.y };
        let parentElement = this.parentElement;
        
        while(parentElement) {
            if(parentElement instanceof GameElement) {
                position.x += parentElement.transform.position.x;
                position.y += parentElement.transform.position.y;
      
                parentElement = parentElement.parentElement;

            } else {
                if(parentElement.parentNode instanceof ShadowRoot) {
                    parentElement = parentElement.parentNode.host;
                
                } else {
                    parentElement = parentElement.parentElement;

                }
            }
        }

        return {
            position: new Vector(position.x, position.y),
            size: new Vector(this.transform.size.x, this.transform.size.y)
        }
    }

    /**
     * Gets the bounds of this element
     *
     * @return {DOMRect} Bounds
     */
    get bounds() {
        let transform = this.globalTransform;

        return new DOMRect(
            transform.position.x,
            transform.position.y,
            transform.size.x,
            transform.size.y
        );
    }

    /**
     * Gets the HTML content
     *
     * @return {String} HTML
     */
    get html() {
        return '';
    }

    /**
     * Gets the shadow style
     *
     * @return {String} Shadow style
     */
    get css() { return ''; }

    /**
     * Finds an element with a selector query
     *
     * @param {String} selector
     *
     * @return {HTMLElement} Element
     */
    getDescendant(selector) {
        try {
            return this.rootElement.querySelector(selector);

        } catch(e) {
            Debug.error(this, new Error(`Invalid selector: ${selector}`));

            return null;

        }
    }
    
    /**
     * Finds several element with a selector query
     *
     * @param {String} selector
     *
     * @return {Array} Elements
     */
    getDescendants(selector) {
        try {
            return Array.from(this.rootElement.querySelectorAll(selector) || []);

        } catch(e) {
            Debug.error(this, new Error(`Invalid selector: ${selector}`));

            return [];

        }
    }

    /**
     * Gets the tag name
     */
    static getCustomTagName() {
        return `ge-${this.name.toLowerCase()}`;
    }

    /**
     * Registers this element as a custom element
     */
    static register() {
        customElements.define(this.getCustomTagName(), this);
    }
}
