'use strict';

let buffer = {
    performance: {
        timer: 0
    },
    log: {
        element: null
    }
};

global.Debug = class Debug {
    /**
     * Print type enum
     */
    static get PRINT_TYPE() {
        return {
            LOG: 0,
            ERROR: 1,
            WARNING: 2
        };
    }
    
    /**
     * Gets whether we're in debug mode
     *
     * @return {Boolean} Active
     */
    static get isActive() {
        return location.href.indexOf('?debug') >= 0;
    }

    /**
     * Rotates the player class
     */
    static clearPlayerClass() {
        User.current.className = null;

        Router.onChange();
    }

    /**
     * Shows debug tools
     */
    static showTools() {
        let tools = document.createElement('debugtools');

        // Player class
        let playerClass = document.createElement('playerclass');

        playerClass.innerHTML = '웃';
        playerClass.title = 'Change player class';
        playerClass.onclick = () => { this.clearPlayerClass(); };

        tools.appendChild(playerClass);

        Viewport.root.appendChild(tools);
    }

    /**
     * Starts an execution timer
     *
     * @param {Object} sender
     * @param {String} message
     */
    static startTimer(sender, message) {
        buffer.performance.timer = performance.now();
        buffer.performance.sender = sender;

        this.log(sender, (message || 'Timer started') + '...');
    }
    
    /**
     * Stops the execution timer and returns the difference
     */
    static stopTimer() {
        if(buffer.performance.timer <= 0 || !buffer.performance.sender) { return }

        let diff = performance.now() - buffer.performance.timer;
        
        this.log(buffer.performance.sender, '...timer stopped:', diff);
        
        buffer.performance.timer = 0;
        buffer.performance.sender = null;
    }

    /**
     * Prints a message to console
     *
     * @param {Number} type
     * @param {Function|Object} sender
     * @param {Array} values
     */
    static print(type, sender, ...values) {
        let name = sender.name || sender.constructor.name;

        switch(type) {
            case this.PRINT_TYPE.LOG:
                console.log(`[${name}]`, ...values);
                break;

            case this.PRINT_TYPE.ERROR:
                console.error(`[${name}]`, '🛑', ...values);
                break;

            case this.PRINT_TYPE.WARNING:
                console.warn(`[${name}]`, '⚠️', ...values);
                break;
        }
    }

    /**
     * Logs a message
     *
     * @param {Function} sender
     * @param {Array} values
     */
    static log(sender, ...values) {
        this.print(this.PRINT_TYPE.LOG, sender, ...values);
    }
    
    /**
     * Logs an error
     *
     * @param {Function} sender
     * @param {Error} error
     */
    static error(sender, error) {
        this.print(this.PRINT_TYPE.ERROR, sender, error && error.message ? error.message : 'Unspecified error');
    }
    
    /**
     * Logs a warning
     *
     * @param {Function} sender
     * @param {Array} values
     */
    static warn(sender, ...values) {
        this.print(this.PRINT_TYPE.WARNING, sender, ...values);
    }
}
