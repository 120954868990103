'use strict';

global.Vector = class Vector {
    static get ZERO() { return new Vector(0, 0); }
    static get ONE() { return new Vector(1, 1); }
    static get INF() { return new Vector(Infinity, Infinity); }
    
    /**
     * Constructor
     */
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }

    /** 
     * Renders this vector as a string
     *
     * @return {String} Output
     */
    toString() {
        return `Vector(${this.x},${this.y})`;
    }

    /**
     * Gets the distance between this and another coordinate
     *
     * @param {Vector} other
     *
     * @return {Number} Distance
     */
    distanceTo(other) {
        return Math.sqrt(Math.pow(Math.abs(this.x - other.x), 2) + Math.pow(Math.abs(this.y - other.y), 2))  
    }

    /**
     * Gets the direction
     *
     * @param {Vector} other
     *
     * @param {Vector} Direction
     */
    directionTowards(other) {
        return new Vector(other.x - this.x, other.y - this.y);
    }

    /**
     * Adds another vector to this one
     *
     * @param {Vector} other
     *
     * @return {Vector} New vector
     */
    add(other) {
        return new Vector(
            this.x + other.x,
            this.y + other.y
        );
    }
    
    /**
     * Subtracts another vector from this one
     *
     * @param {Vector} other
     *
     * @return {Vector} New vector
     */
    subtract(other) {
        return new Vector(
            this.x - other.x,
            this.y - other.y
        );
    }
    
    /**
     * Multiplies this vector by a factor
     *
     * @param {Number} factor
     *
     * @return {Vector} New vector
     */
    multiply(factor) {
        return new Vector(
            this.x * factor,
            this.y * factor
        );
    }
    
    /**
     * Divides this vector by a factor
     *
     * @param {Number} factor
     *
     * @return {Vector} New vector
     */
    divide(factor) {
        return new Vector(
            this.x / factor,
            this.y / factor
        );
    }

    /**
     * Gets a normalized version of this vector
     *
     * @return {Vector} Normalised
     */
    get normalized() {
        return this.divide(this.length);
    }

    /**
     * Gets the length of this vector
     *
     * @return {Number}
     */
    get length() {
        return Math.sqrt(Math.pow(Math.abs(this.x), 2) + Math.pow(Math.abs(this.y), 2));
    }

    /**
     * Linear interpolation
     *
     * @param {Vetor} other
     * @param {Number} factor
     */
    lerp(other, factor) {
        return this.multiply(1 - factor).add(other.multiply(factor));
    }

    /**
     * Round
     *
     * @param {Number} decimals
     */
    round(decimals = 0) {
        decimals = Math.round(decimals);

        return new Vector(
            Math.round(this.x * Math.pow(10, decimals)) / Math.pow(10, decimals),
            Math.round(this.y * Math.pow(10, decimals)) / Math.pow(10, decimals)
        );
    }

    /**
     * Clamp to maximum amount
     *
     * @param {Vector} max
     */
    max(max) {
        return new Vector(
            Math.max(max.x, this.x),
            Math.max(max.y, this.y)
        );
    }
    
    /**
     * Clamp to minimum amount
     *
     * @param {Vector} min
     */
    min(min) {
        return new Vector(
            Math.min(min.x, this.x),
            Math.min(min.y, this.y)
        );
    }

    /**
     * Clones this vector
     *
     * @return {Vector} Clone
     */
    clone() {
        return new Vector(this.x, this.y);
    }
}
