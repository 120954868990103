'use strict';

global.Router = class Router {
    /**
     * Initialises the router
     */
    static async init() {
        try {
            await Asset.load();
            await Settings.load();
            await User.load();

        } catch(e) {
            Debug.error(this, e);

        }

        this.onChange();
    }
    
    /**
     * Go to a specified path
     */
    static go(path, skipChange) {
        this._previousUrl = location.hash.replace('#!', '');

        location = `/#!${path}${Debug.isActive ? '?debug' : ''}`;
        
        if(!skipChange) {
            this.onChange();
        }
    }

    /**
     * Reloads the current route
     */
    static reload() {
        this.onChange();
    }

    /**
     * Gets the previous path
     *
     * @return Array Path
     */
    static get previousPath() {
        if(!this._previousUrl) { return []; }

        let route = this._previousUrl.split('?').shift();
        
        return route.split('/').filter(Boolean);
    }

    /**
     * Gets the current path
     *
     * @return {Array} Path
     */
    static get path() {
        let route = location.hash.replace('#!', '').split('?').shift();
        
        return route.split('/').filter(Boolean);
    }

    /**
     * Event: Hash change
     */
    static async onChange() {
        let route = location.hash.replace('#!', '').split('?').shift();
        let parts = route.split('/').filter(Boolean);

        if(!User.current) {
            await this.setRoot(new UILogin());
            return;
        }

        switch(parts[0]) {
            case 'intro':
                await this.setRoot(new UIClassSelector());
                break;
            
            case 'select':
                await this.setRoot(new UIClassSelector({ skipVideos: true }));
                break;

            case 'login':
                await this.setRoot(new UILogin());
                break;

            case 'session':
                await this.setRoot(new UISession());
                break;

            case 'topic':
                switch(parts[2]) {
                    case 'worldmap':
                        await this.setRoot(new TopicWorldMap(`/api/topic/${parts[1]}`));
                        break;
                            
                    case 'map':
                        switch(parts[4]) {
                            case 'journal':
                                await this.setRoot(new TopicJournal(`/api/topic/${parts[1]}`));
                                break;

                            case 'level':
                                await this.setRoot(new Level(`/api/topic/${parts[1]}`));
                                break;

                            default:
                                await this.setRoot(new TopicMap(`/api/topic/${parts[1]}`));
                                break;
                        }
                        break;

                    default:
                        Debug.error(`Route ${route} not recognised`);
                        break;
                }
                break;
            
            case 'topics':
                await this.setRoot(new TopicSelector());
                break;

            default:
                this.go('/session');
                break;
        }
    }

    /**
     * Replaces the body content
     *
     * @param {GameElement} element
     */
    static async setRoot(element) {
        Audio.sfx(null);

        // Transition elements out and destroy them
        for(let child of Array.from(Viewport.root.querySelectorAll('*') || [])) {
            if(child === element || child.tagName === 'AUDIO') { continue; }
            
            if(typeof child.preTransitionOut === 'function') {
                await child.preTransitionOut();
            }

            if(typeof child.transitionOut === 'function') {
                child.setAttribute('transitioning', 'out');

                await child.transitionOut();
                
                child.removeAttribute('transitioning');
            }
            
            if(typeof child.postTransitionOut === 'function') {
                await child.postTransitionOut();
            }

            if(typeof child.destroy === 'function') {
                child.destroy();
            
            } else {
                child.parentNode.removeChild(child);
            
            }
        }
        
        // Wait for element to be ready
        await element.waitUntilReady(); 

        // Insert and transition new element in
        Viewport.root.appendChild(element);
        
        if(typeof element.preTransitionIn === 'function') {
            await element.preTransitionIn();
        }
       
        if(typeof element.transitionIn === 'function') {
            element.setAttribute('transitioning', 'in');

            await element.transitionIn();
            
            element.removeAttribute('transitioning');
        }
        
        if(typeof element.postTransitionIn === 'function') {
            await element.postTransitionIn();
        }
    }
}

Router.init();
