'use strict';

global.TopicJournal = class TopicJournal extends GameElement {
    /**
     * Preload
     */
    async load() {
        Audio.music('menu');        
        Audio.sfx(null);
        
        let preload = [
            '/asset/ui/sprites.svg',
            [ this.mapPreviewImage, 200 ]
        ];

        for(let snippet of this.mapSnippets || []) {
            if(!snippet.image) { continue; }

            preload.push([ snippet.image.contentUrl, 40 ]);
        }

        await Asset.preload(preload);
    }

    /**
     * Event: Init
     */
    onReady() {
        Audio.sfx(null);
        
        // Init snippets
        this.initSnippets();

        // Add final question envelope
        if(User.current.sessionId) {
            this.appendChild(new TopicJournalEnvelope({ map: this.mapConfig }));
        }

        // Hook up events
        this.getDescendant('map enter').onclick = (e) => {
            this.onClickEnter();
        };

        this.getDescendants('bookmarks bookmark').forEach((bookmark) => {
            bookmark.onclick = (e) => {
                this.onClickBookmark(bookmark);
            }
        });
        
        this.getDescendant('inventory open').onclick = (e) => {
            this.onClickInventoryToggle();
        };
        
        this.getDescendant('inventory close').onclick = (e) => {
            this.onClickInventoryToggle();
        };

        this.getDescendant('exit').onclick = () => { this.onClickExit(); };

        this.getDescendant('complete close').onclick = () => { this.onClickCloseCompletedDialog(); };

        window.onmouseup = (e) => {
            this.onSnippetStopDrag();
        };
        
        window.ontouchend = (e) => {
            this.onSnippetStopDrag();
        };

        window.onmousemove = (e) => {
            this.onPointerMove(e.pageX, e.pageY);
        };

        window.ontouchmove = (e) => {
            this.onPointerMove(e.touches[0].pageX, e.touches[0].pageY);
        };

        // Activate tutorial if needed
        if(!User.current.getFlag('tutorial-topic-journal') && this.userHasSnippet()) {
            this.appendChild(new UITutorialDialog({
                images: Settings.journalTutorial,
                flag: 'tutorial-topic-journal',
            }));
        }
    }

    /**
     * Event: Close completed dialog
     */
    onClickCloseCompletedDialog() {
        Audio.ui('ui/button-click');
        Audio.music('menu');        
        
        User.current.setFlag(`completed-map-${this.mapId}`, true);

        User.current.save();

        this.update();
    }

    /**
     * Event: Click enter
     */
    onClickEnter() {
        Audio.ui('ui/button-click');

        this._useTransitionVideo = true;
        
        Router.go(`/topic/${this.topicId}/map/${this.mapId}`);
    }

    /**
     * Event: Click bookmark
     *
     * @param {HTMLElement} bookmark
     */
    async onClickBookmark(bookmark) {
        Audio.ui('topic-journal/bookmark-click');
        
        Router.go(`/topic/${this.topicId}/map/${bookmark.getAttribute('identifier')}/journal`, true);

        await this.reload();
    }

    /**
     * Event: Click inventory toggle
     */
    onClickInventoryToggle() {
        Audio.ui('ui/button-click');
        Audio.music('menu');        
        
        let inventory = this.getDescendant('inventory');

        let isOpen = inventory.hasAttribute('open');

        if(isOpen) {
            inventory.removeAttribute('open');
        } else {
            inventory.setAttribute('open', true);
        }
    }
    
    /**
     * Event: Click exit
     */
    onClickExit() {
        Audio.ui('ui/button-click');
        
        Router.go(`/topics/${this.topicId}`);
    }

    /**
     * Event: Start dragging a snippet
     *
     * @param {HTMLElement} snippet
     */
    onSnippetStartDrag(x, y, snippet) {
        snippet.setAttribute('dragging', true);
        
        this._draggingSnippet = snippet;
        this._draggingSnippetOffset = new Vector(x, y);
    }
    
    /**
     * Event: Pointer is moved
     *
     * @param {Number} x
     * @param {Number} y
     */
    onPointerMove(x, y) {
        if(!this._draggingSnippet) { return; }

        let position = new Vector(x, y).subtract(this._draggingSnippetOffset);

        this._draggingSnippet.style.transform = `translate(${position.x}px, ${position.y}px)`;
    }
    
    /**
     * Event: Stop dragging a snippet
     */
    onSnippetStopDrag() {
        if(!this._draggingSnippet) { return; }

        let dragRect = this._draggingSnippet.getBoundingClientRect();

        for(let slot of this.getDescendants('pages snippet')) {
            if(slot.getAttribute('identifier') !== this._draggingSnippet.getAttribute('identifier')) { continue; }

            let slotRect = slot.getBoundingClientRect();

            if(!Physics.isOverlap(dragRect, slotRect)) { continue; }
            
            User.current.setSnippetPlaced(this._draggingSnippet.getAttribute('identifier'));

            Audio.ui('topic-journal/snippet-place');

            this.update();
            break;
        }

        this.initSnippets();
    }

    /**
     * Initialises an inventory snippet
     *
     * @param {HTMLElement} snippet
     */
    initInventorySnippet(snippet) {
        snippet.onmousedown = (e) => {
            this.onSnippetStartDrag(e.pageX, e.pageY, snippet);
        }
        
        snippet.ontouchstart = (e) => {
            this.onSnippetStartDrag(e.touches[0].pageX, e.touches[0].pageY, snippet);
        }
    }

    /**
     * Gets if the user has any snippets from this topic
     *
     * @return {Boolean} Has snippet
     */
    userHasSnippet() {
        for(let snippet of this.mapSnippets || []) {
            if(User.current.hasSnippet(snippet.name)) { return true; }
        }

        return false;
    }

    /**
     * Initialises the snippets
     */
    initSnippets() {
        this._draggingSnippet = null;
        this._draggingSnippetOffset = null;

        let inventory = this.getDescendant('inventory snippets');
        let journal = this.getDescendant('pages snippets');
        let inventoryContainer = this.getDescendant('inventory');     

        inventoryContainer.removeAttribute('notify');

        inventory.innerHTML = '';
        journal.innerHTML = '';

        // Render placed snippet slots
        for(let snippet of this.mapSnippets || []) {
            journal.innerHTML += `
                <snippet identifier="${Tool.toVar(snippet.name)}">
                    <media>
                        <border></border>
                        <img>
                    </media>
                    <name>${snippet.name}</name>
                    <description>${snippet.description}</description>
                </snippet>
            `;
        }
            
        // Render inventory snippets
        for(let snippet of this.mapSnippets || []) {
            let isPlaced = User.current.isSnippetPlaced(snippet.name);

            if(!isPlaced && User.current.hasSnippet(snippet.name)) {
                inventory.innerHTML += `
                    <snippet identifier="${Tool.toVar(snippet.name)}">
                        <container>
                            <border></border>
                            <img src="${Asset.getUrl(snippet.image)}">
                        </container>
                    </snippet>
                `;

                inventoryContainer.setAttribute('notify', true);
            }
        }

        // Populate placed snippets and init inventory snippet events
        for(let snippet of this.mapSnippets || []) {
            let isPlaced = User.current.isSnippetPlaced(snippet.name);
            
            if(!isPlaced && User.current.hasSnippet(snippet.name)) {
                let element = this.getDescendant(`inventory snippet[identifier="${Tool.toVar(snippet.name)}"]`);

                this.initInventorySnippet(element);

            } else if(isPlaced) {
                let element = this.getDescendant(`pages snippet[identifier="${Tool.toVar(snippet.name)}"`);
                let index = this.getDescendants('pages snippet').indexOf(element);

                element.innerHTML = `
                    <media>
                        <border></border>
                        <img src="${Asset.getUrl(snippet.image)}">
                    </media>
                    <name>${snippet.name}</name>
                    <description>
                        ${snippet.description}
                    </description>
                `;
            }
        }
    }
    
    /**
     * Gets the id of this map
     */
    get mapId() {
        return Router.path[3];
    }

    /**
     * Gets the current map config
     *
     * @return {Obejct} Map
     */
    get mapConfig() {
        for(let id in this.config.maps || {}) {
            if(id !== this.mapId) { continue; }

            return this.config.maps[id];
        }

        return null;
    }
    
    /**
     * Gets the snippets in this map
     *
     * @return {Array} Snippets
     */
    get mapSnippets() {
        let map = this.mapConfig;

        if(!map) { return []; }

        let snippets = [];

        for(let level of Object.values(map.levels || {})) {
            if(!level.snippet || !level.snippet.name) { continue; }

            snippets.push(level.snippet);
        }

        return snippets;
    }

    /**
     * Gets whether this map has been completed
     *
     * @return {Boolean} Is completed
     */
    get isMapCompleted() {
        let map = this.mapConfig;

        if(!map) { return false; }

        for(let level of map.levels || []) {
            if(User.current.isCompleted(level.identifier)) { continue; }

            return false;
        }

        return true;
    }
    
    /**
     * Gets the name of this map
     */
    get mapName() {
        let map = this.mapConfig;

        if(!map || !map.name) {
            return '[name]';
        }

        return map.name;
    }
    
    /**
     * Gets the description of this map
     */
    get mapDescription() {
        let map = this.mapConfig;

        if(!map || !map.description) {
            return '[description]';
        }

        return map.description;
    }
    
    /**
     * Gets the amount of completed levels in this map
     *
     * @return {Number} Completed levels
     */
    get mapCompletedLevels() {
        let map = this.mapConfig;

        if(!map || !map.levels) { return 0; }

        let completed = 0;

        for(let id in map.levels) {
            let level = map.levels[id];

            if(!User.current.isCompleted(level.identifier)) { continue; }

            completed++;
        }

        return completed;
    }
    
    /**
     * Gets the total amount of levels in this map
     *
     * @return {Number} Total levels
     */
    get mapTotalLevels() {
        let map = this.mapConfig;

        if(!map || !map.levels) { return 0; }

        return Object.keys(map.levels).length;
    }

    /**
     * Gets the completion percentage
     *
     * @return {Number} Percent
     */
    get percentComplete() {
        let current = this.mapCompletedLevels;
        let goal = this.mapTotalLevels;

        for(let snippet of this.mapSnippets || []) {
            goal++;

            if(User.current.isSnippetPlaced(snippet.name)) {
                current++;
            }
        }

        return Math.round((current / goal) * 100);
    }

    /**
     * Gets the URL of the map preview image
     *
     * @return {String} URL
     */
    get mapPreviewImage() {
        let map = this.mapConfig;

        if(!map) { return ''; }

        return map.previewImage || '';
    }

    /**
     * Gets the id of this topic
     */
    get topicId() {
        return Router.path[1];
    }

    /**
     * Gets the name of this topic
     */
    get topicName() {
        if(!this.config || !this.config.name) {
            return '[name]';
        }

        return this.config.name;
    }
    
    /**
     * Gets the description of this topic
     */
    get topicDescription() {
        if(!this.config || !this.config.description) {
            return '[description]';
        }

        return this.config.description;
    }
    
    /**
     * Gets bookmarks
     *
     * @param {Array} Bookmarks
     */
    get bookmarks() {
        let bookmarks = [];

        for(let map of Object.values(this.config.maps || {})) {
            bookmarks.push(map);
        }

        return bookmarks;
    }
    
    /**
     * Transition in
     */
    async transitionIn() {
        let isFromMap = Router.previousPath.indexOf('map') > -1;
            
        if(isFromMap) {
            this.setAttribute('from-map', true);
        
            await Clock.waitForSeconds(0.5);
        }

        await Clock.waitForSeconds(0.1);

        this.removeAttribute('from-map');
    }

    /**
     * Transition out
     */
    async transitionOut() {
        if(this._useTransitionVideo) {
            let video = this.getDescendant('transition video');

            video.removeAttribute('hidden');
            video.play();

            await new Promise((resolve) => {
                video.onended = resolve;
            });
        
        } else {
            await Clock.waitForSeconds(0.5);

        }
    }
    
    /**
     * Gets the CSS
     */
    get css() { return `
        :host {
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: white;
            transition: opacity 0.5s ease;
        }
            :host([transitioning="in"]) {
                opacity: 0;
            }

        exit {
            display: block;
            cursor: pointer;
            position: absolute;
            top: 2rem;
            left: 2rem;
            width: 4rem;
            height: 4rem;
            z-index: 10;
            font-size: 4rem;
            ${Sprite.mapNamedCss('ui', 'up-arrow')}
        }

        pages {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 2rem;
            position: absolute;
            top: 6rem;
            left: 3rem;
            width: calc(100% - 10rem);
            height: calc(100% - 12rem);
            padding: 0 2rem;
            box-sizing: border-box;
            pointer-events: none;
            z-index: 2;
            transform-origin: 25% 50%;
            transition: transform 1s ease;
        }
            :host([transitioning="in"][from-map]) pages {
                transform: scale(1.4);
            }
            
            pages::after {
                content: '';
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: url(${Asset.getUrl('/asset/ui/frame-journal.svg')});
                background-size: 100% 100%;
                background-position: center;
                background-repeat: no-repeat;
            }

        map, snippets {
            display: block;
            z-index: 2;
            position: relative;
            top: 3rem;
            height: calc(100% - 6rem);
            pointer-events: all;
        }

        /* Transition */
        transition {
            position: absolute;
            z-index: 200;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: white;
            opacity: 0;
            transition: opacity 0.5s ease;
            pointer-events: none;
        }
            :host([transitioning="out"]) transition {
                opacity: 1;
                pointer-events: all;
            }

            transition video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
                transition video[hidden] {
                    display: none;
                }

        /* Map */
        map {
            display: flex;
            flex-direction: column;
            pointer-events: all;
        }
            map preview {
                ${this.mapPreviewImage ? `background-image: url('${Asset.getUrl(this.mapPreviewImage)}');`: ``}
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                flex-grow: 1;
                display: flex;
                align-items: flex-end;
                justify-content: center;
            }
                map preview enter {
                    ${Sprite.mixin('ui', 'button')}
                }
                    map preview enter:active {
                        transform: scale(0.9);
                    }

            map > heading {
                display: block;
                max-width: 60%;
                font-size: 2rem;
                padding: 0;
                margin-left: 1rem;
                margin-bottom: 3rem;
                position: relative;
                box-sizing: border-box;
                font-family: SketchNote, sans-serif;
                line-height: 1.4;
            }
                map > heading::after {
                    content: '';
                    position: absolute;
                    display: block;
                    height: 1rem;
                    width: calc(100% + 1rem);
                    left: -0.5rem;
                    bottom: 0;
                    z-index: -1;
                    background-color: var(--color-primary);
                }

            map completion {
                display: block;
                position: absolute;
                right: 2rem;
                top: 0;
            }
                map completion text {
                    font-size: 1.2rem;
                    font-weight: bold;
                    text-align: center;
                    display: block;
                    width: 8rem;
                }

                map completion circle {
                    display: block;
                    width: 8rem;
                    height: 8rem;
                    position: relative;
                    margin: 0 0 1rem auto;
                }
                    map completion circle border,
                    map completion circle fill {
                        display: block;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 8rem;
                    }

                    map completion circle percent {
                        position: relative;
                        font-family: SketchNote, sans-serif;
                        font-size: 3rem;
                        line-height: 8rem;
                        top: 0;
                        left: 0;
                        width: 100%;
                        text-align: center;
                        z-index: 10;
                        display: block;
                    }
                        map completion circle percent::after {
                            content: '${this.percentComplete}%';
                        }
                    
                    map completion circle border {
                        height: 100%;
                        z-index: 10;

                        ${Sprite.mapNamedCss('ui', 'circle')}
                    }

                    map completion circle fill {
                        overflow: hidden;
                        height: ${this.percentComplete}%;
                        transition: height 0.5s ease;
                    }
                        map completion circle fill::after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 8rem;
                            height: 8rem;
                            border-radius: 50%;
                            background-color: var(--color-primary);
                        }
                    
        /* Snippets*/
        pages snippets {
            position: relative;
            display: block;
            pointer-events: all;
        }
            pages snippet {
                display: block;
                position: absolute;
                padding: 1rem;
                box-sizing: border-box;
            }
                pages snippet:nth-of-type(1) { top: 0; left: 0; width: 40%; height: 50%; }
                pages snippet:nth-of-type(2) { top: 50%; left: 0; width: 40%; height: 50%; }
                pages snippet:nth-of-type(3) { top: 0; left: 40%; width: 60%; height: 35%; }
                pages snippet:nth-of-type(4) { top: 35%; left: 40%; width: 60%; height: 35%; }
                pages snippet:nth-of-type(5) { top: 70%; left: 40%; width: 60%; height: 30%; }

                pages snippet media {
                    display: block;
                    position: relative;
                    overflow: hidden;
                }
                    pages snippet media::after {
                        content: '';
                        display: block;
                        padding-bottom: 66.66%;
                    }

                    pages snippet:nth-of-type(1) media {
                        width: 80%;
                        margin-bottom: 2rem;
                        transform: rotate(-4deg);
                    }

                    pages snippet:nth-of-type(2) media {
                        width: 70%;
                        margin-bottom: 2rem;
                        transform: rotate(6deg);
                    }
                    
                    pages snippet:nth-of-type(3) media {
                        width: 40%;
                        top: 1rem;
                        transform: rotate(4deg);
                        float: right;
                        margin: 0 0 2rem 2rem;
                    }

                    pages snippet:nth-of-type(4) media {
                        width: 40%;
                        top: 1rem;
                        transform: rotate(-6deg);
                        float: right;
                        margin: 0 0 2rem 2rem;
                    }
                    
                    pages snippet:nth-of-type(5) media {
                        width: 40%;
                        top: -1rem;
                        transform: rotate(-2deg);
                        float: left;
                        margin: 0 1rem 0 0;
                    }
                    
                    pages snippet media border {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 10;

                        ${Sprite.mixin('ui', 'frame-generic-slim')}
                    }

                    pages snippet media img {
                        display: block;
                        position: absolute;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
            
                pages snippet name {
                    font-size: 1.5rem;
                    display: block;
                    margin-bottom: 1rem;
                    font-family: SketchNote, sans-serif;
                }
                
                pages snippet description {
                    line-height: 1.2;
                    font-size: 1rem;
                }

        /* Bookmarks */
        pages bookmarks {
            position: absolute;
            display: block;
            top: 2rem;
            pointer-events: all;
            left: calc(100% - 2.3rem);
            transform-origin: 0% 100%;
            transform: rotate(90deg);
            z-index: 20;
            width: 50rem;
        }
            pages bookmarks bookmark {
                color: white;
                padding: 1rem 2rem;
                cursor: pointer;
                background-color: var(--color-primary);
                display: inline-block;
                white-space: nowrap;
                box-sizing: border-box;
                font-weight: bold;
                font-size: 1.2rem;
                transform-origin: 0% 100%;
                margin-right: 1rem;
                position: relative;
                color: black;
                
                ${Sprite.mixin('ui', 'frame-generic')}
            }
                pages bookmarks bookmark[current] {
                    background-color: white;
                    pointer-events: none;
                    transform: rotate(3deg);
                    padding-bottom: 3rem;
                }
                
                pages bookmarks bookmark:not([current]) {
                    top: 0.3rem;
                    padding-bottom: 0.5rem;
                    border-image-width: 2rem 2rem 0 2rem;
                }

        /* Complete */
        complete {
            position: absolute;
            z-index: 200;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url('${Asset.getUrl(this.config.completedImage)}');
            text-align: center;
            display: flex;
            transform: ${this.percentComplete >= 100 && !User.current.getFlag(`completed-map-${this.mapId}`) ? 'none' : 'translateY(-100%)'};
            transition: transform 1s ease;
            flex-direction: column;
            justify-content: flex-end;
            background-color: white;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
            complete heading {
                font-family: SketchNote, sans-serif;
                font-size: 3rem;
                display: block;
            }

            complete text {
                margin-top: 3rem;
                display: block;
                line-height: 1.4;
                font-size: 1.4rem;
            }

            complete close {
                margin: 2rem auto;

                ${Sprite.mixin('ui', 'button')}
            }

        /* Inventory */
        inventory open {
            display: block;
            cursor: pointer;
            left: 1rem;
            bottom: 1rem;
            position: absolute;
            width: 4rem;
            height: 4rem;

            ${Sprite.mapNamedCss('ui', 'inventory-toggle')}
        }
            inventory[notify] open::after {
                content: '';
                width: 1.2rem;
                height: 1.2rem;
                border-radius: 50%;
                display: block;
                position: absolute;
                left: 0;
                top: 0.4rem;
                background-color: var(--color-alert);
            }

        inventory inside {
            display: block;
            position: absolute;
            bottom: 0;
            transition: transform 0.5s ease;
            padding: 4rem;
            background-color: white;
            border: 2px solid black;
            border-top-right-radius: 4rem;
            z-index: 40;
            width: 30rem;
        }
            :host([transitioning]) inventory inside {
                display: none;
            }

        inventory snippets {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            border: 0;
            top: 0;
        }

        inventory snippet {
            display: block;
            position: relative;
            flex-basis: 50%;
            min-width: 50%;
            max-width: 50%;
            width: 50%;
            margin-bottom: 2rem;
        }
            inventory snippet:nth-child(1) { transform: rotate(-4deg); }
            inventory snippet:nth-child(2) { transform: rotate(3deg); }
            inventory snippet:nth-child(3) { transform: rotate(4deg); }
            inventory snippet:nth-child(4) { transform: rotate(-2deg); }
            inventory snippet:nth-child(5) { transform: rotate(3deg); }
            inventory snippet:nth-child(6) { transform: rotate(-4deg); }

            inventory snippet[dragging] {
                z-index: 40;
            }

            inventory snippet container {
                display: block;
                width: calc(100% - 2rem);
                position: relative;
                overflow: hidden;
                cursor: grab;
                margin: 1rem auto;
                background-color: white;
            }
                inventory snippet container::after {
                    content: '';
                    display: block;
                    padding-bottom: 66.66%;
                }

                inventory snippet border {
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 10;

                    ${Sprite.mixin('ui', 'frame-generic-slim')}
                }
                
                inventory snippet container img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    pointer-events: none;
                    position: absolute;
                }

        inventory close {
            display: block;
            width: 4rem;
            height: 4rem;
            position: absolute;
            bottom: 1rem;
            left: 1rem;
            cursor: pointer;
            ${Sprite.mapNamedCss('ui', 'close')}
        }

        inventory:not([open]) inside {
            transform: translateY(100%);
            pointer-events: none;
        }
    `; }

    /**
     * Gets the HTML
     */
    get html() { return `
        <exit></exit>
        <ge-uimusictoggle></ge-uimusictoggle>
        <inventory>
            <open></open>
            <inside>
                <close></close>
                <snippets></snippets>
            </inside>
        </inventory>
        <transition>
            <video hidden src="/asset/topic-journal/topic-journal-transition-${User.current.getClass()}.mp4">
        </transition>
        <ge-uidisclaimer></ge-uidisclaimer>
        <complete>
            <heading>${L10N.translate('topic-complete-heading')}</heading>
            <text>${L10N.translate('topic-complete-text')}</text>
            <close>${L10N.translate('topic-complete-close')}</close>
        </complete>
        <pages>
            <map>
                <heading>${this.mapDescription}</heading>
                <completion>
                    <circle>
                        <border></border>
                        <fill></fill>
                        <percent></percent>
                    </circle>
                    <text>${L10N.translate('journal-completion')}</text>
                </completion>
                
                <preview>
                    <enter>${L10N.translate('topic-journal-enter')}</enter>
                </preview>
            </map>
            <snippets></snippets>
            <bookmarks>
                ${this.bookmarks.map(map => `
                    <bookmark ${map.identifier === this.mapId ? 'current' : ''} identifier="${map.identifier}">${map.name}</bookmark>
                `).join('')}
            </bookmarks>
        </pages>
    `; }
}

TopicJournal.register();
