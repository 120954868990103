'use strict';

global.StageChallenge = class StageChallenge extends Stage {
    /**
     * Event: Key down
     *
     * @param {InputEvent} e
     */
    onKeyDown(e) {
        if(this._isDestroyed) { return; }

        // Press tab to win the challenge
        if(e.key === 'Tab') {
            this.onComplete();
        }
    }
    
    /**
     * Event: Complete
     */
    onComplete() {
        this.isCompleted = true;

        this.update();
    }
}
