'use strict';

global.Sprite = class Sprite {
    /**
     * Gets CSS for a sprite by map/x/y
     *
     * @param {String} mapName
     * @param {Number} X
     * @param {Number} Y
     *
     * @return {String} Sprite CSS
     */
    static mapPositionCss(mapName, x, y) {
        if(!MAPS[mapName]) {
            Debug.error(this, new Error(`Mapped sprite ${mapName} not found`));
            return '';
        }

        return this.mapCss(MAPS[mapName], { x: x, y: y });
    }

    /**
     * Gets CSS for a sprite by map/name
     *
     * @param {String} mapName
     * @param {String} spriteKey
     *
     * @return {String} Sprite CSS
     */
    static mapNamedCss(mapName, spriteKey) {
        if(!MAPS[mapName] || !MAPS[mapName].sprites || !MAPS[mapName].sprites[spriteKey]) {
            Debug.error(this, new Error(`Mapped sprite ${mapName}/${spriteKey} not found`));
            return '';
        }

        return this.mapCss(MAPS[mapName], MAPS[mapName].sprites[spriteKey]);
    }

    /**
     * Gets CSS for a sprite
     *
     * @param {Object} map
     * @param {Object} sprite
     *
     * @return {String} Sprite CSS
     */
    static mapCss(map, sprite) {
        let cells = map.size / map.unit;
        let increment = 100 / (cells - 1);
        let size =  {
            x: ((sprite.w || 1) * map.unit) / map.size,
            y: ((sprite.h || 1) * map.unit) / map.size
        };
        let position = {
            x: sprite.x * increment,
            y: sprite.y * increment
        };

        return `
            background-image: url(${Asset.getUrl(map.image)});
            background-repeat: no-repeat;
            background-size: ${cells / (sprite.w || 1)}00%;
            background-position: ${position.x}% ${position.y}%;
        `;
    }
    
    /**
     * Generic mixins
     *
     * @param {String} collectionName
     * @param {String} spriteKey
     *
     * @return {String} Sprite CSS
     */
    static mixin(collectionName, spriteKey) {
        if(!MIXINS[collectionName] || !MIXINS[collectionName][spriteKey]) {
            Debug.error(this, new Error(`Mixin ${collectionName}/${spriteKey} not found`));
            return '';
        }

        return MIXINS[collectionName][spriteKey];
    }

    /**
     * Gets a sprite map by name
     *
     * @param {mapName}
     *
     * @return {Object} Map
     */
    static getMap(mapName) {
        if(!MAPS[mapName]) {
            throw new Error(`Sprite map ${mapName} not found`);
        }

        return MAPS[mapName];
    }
}

const MAPS = {
    'ui': {
        image: '/asset/ui/sprites.svg',
        size: 1024,
        unit: 128,
        sprites: {
            'box-blue': { x: 6, y: 0 },
            'button-arrow-right': { x: 7, y: 4 },
            'button-arrow-down': { x: 4, y: 5 },
            'button-blue': { x: 2, y: 4 },
            'button-green': { x: 4, y: 4 },
            'button-left': { x: 0, y: 0 },
            'button-right': { x: 1, y: 0 },
            'circle': { x: 7, y: 0 },
            'circle-blue': { x: 5, y: 0 },
            'close': { x: 3, y: 3 },
            'cloud-1': { x: 0, y: 5 },
            'cloud-2': { x: 1, y: 5 },
            'compass': { x: 2, y: 1 },
            'cross-red-shadow': { x: 5, y: 4 },
            'envelope': { x: 7, y: 3 },
            'envelope-background': { x: 1, y: 4 },
            'envelope-foreground': { x: 0, y: 4 },
            'inventory-toggle': { x: 6, y: 3 },
            'minus': { x: 2, y: 0 },
            'menu': { x: 2, y: 3 },
            'music-on': { x: 0, y: 3 },
            'music-off': { x: 1, y: 3 },
            'padlock': { x: 5, y: 5 },
            'pause': { x: 6, y: 4 },
            'pause-transparent': { x: 7, y: 2 },
            'play': { x: 3, y: 0 },
            'plus': { x: 4, y: 0 },
            'point-final': { x: 3, y: 1 },
            'point-marker-completed': { x: 0, y: 2 },
            'point-marker-new': { x: 7, y: 1 },
            'rotate-left': { x: 2, y: 5 },
            'rotate-right': { x: 3, y: 5 },
            'star-blank': { x: 2, y: 2 },
            'star-fill': { x: 1, y: 2 },
            'star-frame': { x: 3, y: 2 },
            'tick-green-shadow': { x: 4, y: 3 },
            'topic-journal': { x: 5, y: 3 },
            'up-arrow': { x: 6, y: 2 },
        }
    },
    'player-crash-cloud': {
        image: '/asset/player/player-crash-cloud.png',
        size: 1024,
        unit: 256
    }
}

for(let className of [ 'girl', 'robo', 'quarterback' ]) {
    for(let mapName of [ 'topic-map', 'jumprun-land', 'jumprun-sea', 'dodge-land', 'dodge-sea' ]) {
        MAPS[`player-${mapName}-${className}`] = {
            image: `/asset/player/player-${mapName}-${className}.png`,
            size: 2048,
            unit: 256
        };
    }
}

const MIXINS = {
    'ui': {
        'frame-generic': `
            border-image-source: url('/asset/ui/frame-generic.svg');
            border-image-slice: 20 fill;
            border-image-width: 2rem;
        `,
        'frame-generic-slim': `
            border-image-source: url('/asset/ui/frame-generic-slim.svg');
            border-image-slice: 20 fill;
            border-image-width: 2rem;
        `,
        'button': `
            background-color: transparent;
            border: 0;
            box-sizing: border-box;
            color: white;
            cursor: pointer;
            display: block;
            font-family: Calibri, sans-serif;
            font-size: 1.5rem;
            font-style: italic;
            font-weight: bold;
            height: 10rem;
            line-height: 10rem;
            max-height: 10rem;
            max-width: 20rem;
            outline: none !important;
            padding: 0;
            text-align: center;
            transition: transform 0.1s ease;
            user-select: none;
            width: 20rem;
            -webkit-touch-callout: none !important;
            -webkit-user-select: none !important;
            
            ${Sprite.mapNamedCss('ui', 'button-blue')}
        `
    }
};
