'use strict';

global.UITutorialDialog = class UITutorialDialog extends GameElement {
    /**
     * Event: Init
     */
    onReady() {
        this.getDescendant('close').onclick = (e) => {
            e.stopPropagation();

            this.onClickClose();
        };

        let proceed = this.getDescendant('proceed');

        if(proceed) {
            proceed.onclick = (e) => {
                e.stopPropagation();

                this.onClickProceed();
            };
        }
    }

    /**
     * Event: Click close
     */
    async onClickClose() {
        Audio.ui('ui/button-click');

        if(this.config.flag) {
            await User.current.setFlag(this.config.flag, true);
        }

        this.destroy();
    }

    /**
     * Event: Click proceed
     */
    onClickProceed() {
        Audio.ui('ui/button-click');

        this._imageIndex = (this._imageIndex || 0) + 1;

        this.update();
    }

    /**
     * Gets the images
     */
    get images() {
        if(!this.config.images) { return []; }

        let images = [];

        for(let element of this.config.images.itemListElement) {
            images.push(element.item);
        }
            
        return images;
    }

    /**
     * Gets whether next button should be displayed
     *
     * @return {Boolean} Display next button
     */
    get displayNextButton() {
        let count = this.images.length;

        if(count < 2) { return false; }

        return !this._imageIndex || this._imageIndex < count - 1;
    }

    /**
     * Gets the CSS
     */
    get css() { return `
        :host {
            position: absolute;
            display: block;
            background-color: rgba(0, 0, 0, 0.5);
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 900;
        }
  
        dialog {
            display: block;
            position: absolute;
            width: 60rem;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: transparent;
            border: 0;
        }
            dialog::after {
                content: '';
                display: block;
                width: calc(100% + 4rem);
                height: calc(100% + 4rem);
                position: absolute;
                left: -2rem;
                top: -2rem;
                transform: rotate(-2deg);
                background-color: white;

                ${Sprite.mixin('ui', 'frame-generic')}
            }

        images {
            display: block;
            overflow: hidden;
            position: relative;
            z-index: 20;
            white-space: nowrap;
        }
            images img {
                display: inline-block;
                vertical-align: top;
                width: 100%;
                transition: transform 0.5s ease;
                transform: translateX(-${(this._imageIndex || 0) * 100}%);
            }
        
        close {
            position: absolute;
            display: block;
            cursor: pointer;
            top: -2rem;
            right: 0;
            width: 3rem;
            height: 3rem;
            z-index: 30;

            ${Sprite.mapNamedCss('ui', 'close')}
        }
        
        proceed {
            position: absolute;
            bottom: -12rem;
            right: -4rem;
            z-index: 40;

            ${Sprite.mixin('ui', 'button')}
            
            display: ${this.displayNextButton ? 'block' : 'none'};
        }
            proceed:after {
                transform: scale(0.9);
            }
    `; }

    /**
     * Gets the HTML
     */
    get html() { return `
        <dialog>
            <close></close>
            <images>
                ${this.images.map((image) => `
                    <img src="${Asset.getUrl(image)}">
                `).join('')}
            </images>
            ${this.images.length > 1 ? `
                <proceed>${L10N.translate('proceed')}</proceed>
            ` : ``}
        <dialog>
    `; }
}

UITutorialDialog.register();
