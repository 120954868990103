'use strict';

global.Audio = class Audio {
    /**
     * Plays a sound
     *
     * @param {String} url
     * @param {Number} track
     * @param {Boolean} loop
     */
    static async play(url, track, loop) {
        if(!track || track < 1 || track > 3) {
            throw new Error('Track can only be 1, 2 or 3');
        }

        if(!this._tracks) {
            this._tracks = {
                1: document.createElement('audio'),
                2: document.createElement('audio'),
                3: document.createElement('audio')
            };
        }

        this._tracks[3].muted = this.isMusicMuted;

        if(!this._tracks[track].parentElement) {
            document.body.appendChild(this._tracks[track]);
        }
        
        if(this._tracks[track].paused || this._tracks[track].ended) {
            this._tracks[track].src = '';
        }

        if(loop && this._tracks[track].getAttribute('src') === url) { return; }

        this._tracks[track].loop = loop === true;
        this._tracks[track].src = url;
        this._tracks[track].load();

        try {
            await this._tracks[track].play();

            await new Promise((resolve) => {
                this._tracks[track].onended = resolve;
            });

            if(this._tracks[track].paused) {
                this._tracks[track].src = '';
            }

        } catch(e) {
            Debug.error(this, e);

        }
    }
    
    /**
     * Plays a UI sound effect
     *
     * @param {String} name
     */
    static async ui(name) {
        if(!name) {
            this.stop(1);
        
        } else {
            await this.play(`/asset/${name}.mp3`, 1);

        }
    }

    /**
     * Plays a sound effect
     *
     * @param {String} name
     * @param {Boolean} loop
     */
    static async sfx(name, loop) {
        if(!name) {
            this.stop(2);
        
        } else {
            await this.play(`/asset/${name}.mp3`, 2, loop);

        }
    }
    
    /**
     * Plays a music track
     *
     * @param {String} name
     */
    static music(name) {
        if(!name) {
            this.stop(3);
        
        } else {
            this.play(`/asset/music/${name}.mp3`, 3, true);

        }
    }

    /**
     * Stops a track
     *
     * @param {Number} track
     */
    static stop(track) {
        if(!track || track < 1 || track > 3) {
            throw new Error('Track can only be 1, 2 or 3');
        }
        
        if(!this._tracks || this._tracks[track].paused || this._tracks[track].ended) { return; }

        this._tracks[track].pause();
        this._tracks[track].src = '';
    }

    /**
     * Gets whether music is muted
     *
     * @return {Boolean} Is muted
     */
    static get isMusicMuted() {
        return User.current.getFlag('music-muted');
    }

    /**
     * Sets music muted status
     *
     * @param {Boolean} isMuted
     */
    static async setMusicMuted(isMuted) {
        if(this._tracks) {
            this._tracks[3].muted = isMuted;
        }
        
        await User.current.setFlag('music-muted', isMuted);
    }
}
