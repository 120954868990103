'use strict';

const NAVIGATION_STOPPING_DISTANCE = 0.5;
const WALKING_SPEED = 18;

global.TopicMapPlayer = class TopicMapPlayer extends GameElement {
    /**
     * Event: Init
     */
    onReady() {
        super.onReady();
      
        // Set up sprite
        this.sprite = new KeyframeSprites({
            map: `player-topic-map-${User.current.getClass()}`,
            layer: this.layer
        });

        Physics.addCollider(this.sprite);

        this.sprite.add('idle', [
            // Loop
            [ 2, 6 ],
            [ 3, 6 ],
            [ 4, 6 ],
            [ 5, 6 ],
            [ 6, 6 ],
            [ 7, 6 ],
            [ 0, 7 ],
            [ 1, 7 ],
            [ 2, 7 ],
            [ 3, 7 ],
            [ 4, 7 ],
            [ 5, 7 ],
            [ 6, 7 ],
            [ 7, 7 ],

            // Loop
            [ 2, 6 ],
            [ 3, 6 ],
            [ 4, 6 ],
            [ 5, 6 ],
            [ 6, 6 ],
            [ 7, 6 ],
            [ 0, 7 ],
            [ 1, 7 ],
            [ 2, 7 ],
            [ 3, 7 ],
            [ 4, 7 ],
            [ 5, 7 ],
            [ 6, 7 ],
            [ 7, 7 ],

            // Loop
            [ 2, 6 ],
            [ 3, 6 ],
            [ 4, 6 ],
            [ 5, 6 ],
            [ 6, 6 ],
            [ 7, 6 ],
            [ 0, 7 ],
            [ 1, 7 ],
            [ 2, 7 ],
            [ 3, 7 ],
            [ 4, 7 ],
            [ 5, 7 ],
            [ 6, 7 ],
            [ 7, 7 ],

            // Loop
            [ 2, 6 ],
            [ 3, 6 ],
            [ 4, 6 ],
            [ 5, 6 ],
            [ 6, 6 ],
            [ 7, 6 ],
            [ 0, 7 ],
            [ 1, 7 ],
            [ 2, 7 ],
            [ 3, 7 ],
            [ 4, 7 ],
            [ 5, 7 ],
            [ 6, 7 ],
            [ 7, 7 ],
            
            // Loop
            [ 2, 6 ],
            [ 3, 6 ],
            [ 4, 6 ],
            [ 5, 6 ],
            [ 6, 6 ],
            [ 7, 6 ],
            [ 0, 7 ],
            [ 1, 7 ],
            [ 2, 7 ],
            [ 3, 7 ],
            [ 4, 7 ],
            [ 5, 7 ],
            [ 6, 7 ],
            [ 7, 7 ],

            // Variation
            [ 2, 1 ],
            [ 3, 1 ],
            [ 4, 1 ],
            [ 5, 1 ],
            [ 6, 1 ],
            [ 7, 1 ],
            [ 0, 2 ],
            [ 1, 2 ],
            [ 2, 2 ],
            [ 3, 2 ],
            [ 4, 2 ],
            [ 5, 2 ],
            [ 6, 2 ],
            [ 7, 2 ],
            [ 0, 3 ],
            [ 1, 3 ],
            [ 2, 3 ],
            [ 3, 3 ],
            [ 4, 3 ],
            [ 5, 3 ],
            [ 6, 3 ],
            [ 7, 3 ],
            [ 0, 4 ],
            [ 1, 4 ],
            [ 2, 4 ],
            [ 3, 4 ],
            [ 4, 4 ],
            [ 5, 4 ],
            [ 6, 4 ],
            [ 7, 4 ],
            [ 0, 5 ],
            [ 1, 5 ],
            [ 2, 5 ],
            [ 3, 5 ],
            [ 4, 5 ],
            [ 5, 5 ],
            [ 6, 5 ],
            [ 7, 5 ],
            [ 0, 6 ],
            [ 1, 6 ]
        ]);

        this.sprite.add('walk', [
            [ 0, 0 ],
            [ 1, 0 ],
            [ 2, 0 ],
            [ 3, 0 ],
            [ 4, 0 ],
            [ 5, 0 ],
            [ 6, 0 ],
            [ 7, 0 ],
            [ 0, 1 ],
            [ 1, 1 ]
        ]);
        
        this.sprite.transform.size.x = 14;
        this.sprite.transform.size.y = 14;
        this.sprite.transform.position.x = -7;
        this.sprite.transform.position.y = -14;

        this.appendChild(this.sprite);

        this.sprite.play('idle');

        this.onanimationend = (e) => {
            this.onNavigationEnded();
        };

        // Set up transform
        this.transform.position.x = parseInt(this.startPosition.x);
        this.transform.position.y = parseInt(this.startPosition.y);
        this.transform.size.x = 6;
        this.transform.size.y = 6;
    }
    
    /**
     * Gets the layer of this element
     *
     * @return {Number} Layer
     */
    get layer() {
        return 2;
    }
    
    /**
     * Gets the start position
     *
     * @return {Vector} Start position
     */
    get startPosition() {
        return this.config.startPosition || new Vector(0, 0);
    }
    
    /**
     * Sets the navigation path
     *
     * @param {Array} path
     */
    set navigationPath(path) {
        let lastPosition = this.position;

        this._navigationPath = path.filter(val => val);

        if(this._navigationPath.length < 1) { return; }

        this.dispatchEvent(new Event('navigationbegin'));
            
        this.sprite.play('walk');

        this._navigationDelta = 0;
            
        let target = this.navigationPath[this._navigationPath.length - 1];

        this.sprite.isFlipped = target.x < this.transform.position.x;
    }

    /**
     * Gets the navigation path
     *
     * @return {Array} Path
     */
    get navigationPath() {
        return this._navigationPath || [];
    }
    
    /**
     * Event: Tick
     */
    onTick(delta) {
        let direction = Vector.ZERO;

        if(this.navigationPath.length > 0) {
            let target = this.navigationPath[0];

            if(this.transform.position.distanceTo(target) <= NAVIGATION_STOPPING_DISTANCE) {
                this.navigationPath.shift();
            
            } else {
                direction = this.transform.position.directionTowards(target).normalized;

            }
                
            this.sprite.play('walk');

            if(User.current.getClass() === 'robo') {
                Audio.sfx('player/player-roll', true);
            } else {
                Audio.sfx('player/player-walk', true);
            }

        } else {
            if((Input.isKeyPressed('ArrowLeft') || Input.isKeyPressed('KeyA')) && this.canWalkLeft) {
                direction.x = -0.8;
                this.sprite.isFlipped = true;

            } else if((Input.isKeyPressed('ArrowRight') || Input.isKeyPressed('KeyD')) && this.canWalkRight) {
                direction.x = 0.8;
                this.sprite.isFlipped = false;
            
            }
            
            if((Input.isKeyPressed('ArrowUp') || Input.isKeyPressed('KeyW')) && this.canWalkUp) {
                direction.y = -0.8;

            } else if((Input.isKeyPressed('ArrowDown') || Input.isKeyPressed('KeyS')) && this.canWalkDown) {
                direction.y = 0.8;

            }

            if(direction.length > 0) {
                this.sprite.play('walk');
                
                if(User.current.getClass() === 'robo') {
                    Audio.sfx('player/player-roll', true);
                } else {
                    Audio.sfx('player/player-walk', true);
                }
            
            } else if(this.sprite.currentAnimation === 'walk') {
                this.sprite.play('idle');
                Audio.sfx(null);

            }
        }

        let target = this.transform.position
            .add(direction.multiply(delta * WALKING_SPEED))
            .max(this.sprite.transform.size.divide(2))
            .min(this.config.map.size.subtract(this.sprite.transform.size.divide(2)));

        this.transform.position = target;
    }

    /**
     * Event: Final navigation point has been reached
     */
    onNavigationEnded() {
        this._currentPosition = this.navigationPath.pop();

        this._navigationPath = [];

        this.dispatchEvent(new Event('navigationend'));
            
        this.sprite.play('idle');
    }
}

TopicMapPlayer.register();
