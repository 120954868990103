'use strict';

global.UILogin = class UILogin extends GameElement {
    /**
     * States
     */
    static get STATE() {
        return {
            LOGIN: 1,
            REGISTER: 2
        }
    };

    /**
     * Event: Init
     */
    onReady() {
        let register = this.getDescendant('register');

        if(register) {
            register.onclick = () => { this.onRegister(); };
        }
        
        let login = this.getDescendant('login');

        if(login) {
            login.onclick = () => { this.onLogin(); };
        }

        let form = this.getDescendant('form');

        if(form) {
            form.onsubmit = (e) => { this.onSubmit(e); };
        }
    }

    /**
     * Loads this view
     */
    async load() {
        this._state = this._state || UILogin.STATE.LOGIN;
        this._message = this._message || Http.getQueryParameter('message');

        if(this._state === UILogin.STATE.REGISTER) {
            this._captcha = await Http.get('/api/user/captcha');
        }
    }

    /**
     * Event: Submit
     */
    async onSubmit(e) {
        e.preventDefault();

        this._error = '';
        this._message = '';

        let username = this.getDescendant('input[name="username"]').value;
        let password = this.getDescendant('input[name="password"]').value;

        try {
            if(this._state === UILogin.STATE.REGISTER) {
                let captchaText = this.getDescendant('input[name="captcha"]').value;
                
                await Http.post(`api/user/register?username=${username}&password=${password}&captchaKey=${this._captcha.key}&captchaText=${captchaText}`);
                
                await User.load();

                Router.go('/session');

            } else if(this._state === UILogin.STATE.LOGIN) {
                await Http.post(`api/user/login?username=${username}&password=${password}`);

                await User.load();

                if(Router.path && Router.path[0] === 'login') {
                    Router.go('/topics');

                } else {
                    Router.onChange();

                }
            }
        
        } catch(e) {
            this._username = username;
            this._password = password;

            this._error = e.message || 'unknown-error';

            this.reload();

        }
    }
    
    /**
     * Event: Click login
     */
    async onLogin() {
        this._state = UILogin.STATE.LOGIN;

        this.reload();
    }

    /**
     * Event: Click register
     */
    async onRegister() {
        this._state = UILogin.STATE.REGISTER;

        this.reload();
    }

    /**
     * Gets the CSS
     */
    get css() { return `
        heading {
            display: block;
            font-size: 2rem;
            position: absolute;
            font-family: SketchNote, sans-serif;
            top: 8rem;
            left: 10rem;
        }
            heading::after {
                content: '';
                position: absolute;
                left: 0;
                display: block;
                background-color: var(--color-primary);
                height: 1.4rem;
                bottom: -0.8rem;
                width: 100%;
                z-index: -1;
            }

        form {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        field {
            box-sizing: border-box;
            display: block;
            margin: 2rem;
            padding: 0;
        }

        input {
            box-sizing: border-box;
            display: block;
            border: 0;
            background-color: transparent;
            line-height: 5rem;
            height: 5rem;
            padding: 0 2rem;
            margin: 0;
            width: 28rem;
            font-family: 'CeraPro', sans-serif;
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            
            ${Sprite.mixin('ui', 'frame-generic')}

            border-image-slice: 26 fill;
        }

        svg {
            display: block;
            margin: 0 auto 2rem auto;
        }

        register, login {
            font-size: 1.5rem;
            font-weight: 700;
            cursor: pointer;
            text-align: center;
            text-decoration: underline;
            display: block;
            margin-top: 13rem;
        }

        button {
            position: absolute;
            bottom: 2rem;
            left: calc(50% - 10rem);

            ${Sprite.mixin('ui', 'button')}
        }
            button:active {
                transform: scale(0.9);
            }
        
        error, message {
            display: block;
            position: absolute;
            pointer-events: none;
            text-align: center;
            font-size: 2rem;
            margin: 1rem;
            top: 12rem;
            left: 0;
            width: 100%;
        }
    `; }

    /**
     * Gets the HTML
     */
    get html() { return `
        ${this._error ? `
            <error>${L10N.translate(this._error)}</error>
        
        ` : this._message ? `
            <message>${L10N.translate(this._message)}</message>
        
        ` : ``}

        ${this._state === UILogin.STATE.LOGIN ? `
            <heading>${L10N.translate('login')}</heading>

            <form> 
                <field>
                    <input type="text" name="username" value="${this._username || ''}" placeholder="${L10N.translate('username')}">
                </field>
                <field>
                    <input type="password" name="password" value="${this._password || ''}" placeholder="${L10N.translate('password')}">
                </field>
                <button type="submit">${L10N.translate('login')}</button>
                <register>${L10N.translate('login-needs-account')}</register>
            </form>

        ` : this._state === UILogin.STATE.REGISTER ? `
            <heading>${L10N.translate('register')}</heading>
            
            <form> 
                <field>
                    <input type="text" name="username" value="${this._username || ''}" placeholder="${L10N.translate('username')}">
                </field>
                <field>
                    <input type="password" name="password" value="${this._password || ''}" placeholder="${L10N.translate('password')}">
                </field>
                <field>
                    ${this._captcha.svg}
                    <input type="text" name="captcha" placeholder="${L10N.translate('captcha')}">
                </field>
                <button type="submit">${L10N.translate('register')}</button>
                <login>${L10N.translate('login-has-account')}</login>
            </form>

        ` : ``}
        
        <ge-uidisclaimer left></ge-uidisclaimer>
    `; }
}

UILogin.register();
