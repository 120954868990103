'use strict';

const GRAVITY = 9.6;
const WEIGHT = 20;
const JUMP_FORCE = 100;

const FOREGROUND_SPEED = 1.8;
const MIDDLEGROUND_SPEED = 0.1;
const BACKGROUND_SPEED = 0.04;

const TRACK_SIZE = 40;

/**
 * The crash effect
 */
class JumpRunCrash extends KeyframeSprites {
    get layer() { return 6; }

    /**
     * Constructor
     */
    constructor(data) {
        data = data || {};

        data.map = 'player-crash-cloud';

        super(data);
        
        this.add('crash', [
            [ 0, 0 ],
            [ 1, 0 ],
            [ 2, 0 ],
            [ 3, 0 ],
            [ 0, 1 ],
            [ 1, 1 ],
            [ 2, 1 ],
            [ 3, 1 ],
            [ 0, 2 ],
        ], false);

        this.transform.position = this.transform.size.multiply(-0.9);

        this.play('crash');
    }

    /**
     * Event: Crash
     *
     * @param {DodgeObstacle} obstacle
     */
    onCrash(obstacle) {
        this.transform.position = obstacle.transform.position.add(obstacle.config.image.offset).subtract(Vector.ONE);
        this.transform.size = Vector.ONE.multiply(obstacle.config.image.size + 2);
        this.play('crash');
    }
}

JumpRunCrash.register();

/**
 * The obstacle
 */
class JumpRunObstacle extends GameElement {
    /**
     * Event: Init
     */
    onReady() {
        Physics.addCollider(this);

        this.transform.size = new Vector(this.config.size.x || 6, this.config.size.y || 6);
    }
  
    /**
     * Event: Draw
     */
    onDraw(context) {
        Canvas.drawImage(
            this.config.image.source,
            this.transform.position.x + this.config.image.offset.x,
            this.transform.position.y + this.config.image.offset.y,
            this.config.image.size,
            this.config.image.size
        );
    }
}

JumpRunObstacle.register();

/**
 * The player
 */
class JumpRunPlayer extends GameElement {
    /**
     * Gets the layer of this element
     *
     * @return {Number} Layer
     */
    get layer() {
        return 4;
    }

    /**
     * Event: Init
     */
    onReady() {
        this._positionDelta = 0;
        this._jumpVelocity = 0;
        this._crashTimer = 0;
        
        this.transform.size.x = 4;
        this.transform.size.y = 6;
        this.transform.position.y = Viewport.size.y - 11 - this.transform.size.y;
        this.transform.position.x = 10;

        this.sprite = new KeyframeSprites({
            map: `player-jumprun-${this.config.environment || 'land'}-${User.current.getClass()}`
        });
            
        this.sprite.add('idle', [
            [ 0, 5 ]
        ]);
        this.sprite.add('run', [
            [ 0, 5 ],
            [ 1, 5 ],
            [ 2, 5 ],
            [ 3, 5 ],
            [ 4, 5 ],
            [ 5, 5 ],
            [ 6, 5 ],
            [ 7, 5 ],
            [ 0, 6 ],
            [ 1, 6 ],
            [ 2, 6 ],
            [ 3, 6 ],
            [ 4, 6 ],
            [ 5, 6 ],
            [ 6, 6 ],
            [ 7, 6 ],
            [ 0, 7 ],
            [ 1, 7 ],
            [ 2, 7 ],
            [ 3, 7 ]
        ]);
        this.sprite.add('crash', [
            [ 0, 0 ],
            [ 1, 0 ],
            [ 2, 0 ],
            [ 3, 0 ],
            [ 4, 0 ],
            [ 5, 0 ],
            [ 6, 0 ],
            [ 7, 0 ],
            [ 0, 1 ],
            [ 1, 1 ],
            [ 2, 1 ],
            [ 3, 1 ],
            [ 4, 1 ],
            [ 5, 1 ],
            [ 6, 1 ],
            [ 7, 1 ],
            [ 0, 2 ],
            [ 1, 2 ],
            [ 2, 2 ],
            [ 3, 2 ]
        ], false);
        this.sprite.add('jump', [
            [ 4, 2 ],
            [ 5, 2 ],
            [ 6, 2 ],
            [ 7, 2 ],
            [ 0, 3 ],
            [ 1, 3 ],
            [ 2, 3 ],
            [ 3, 3 ],
            [ 4, 3 ],
            [ 5, 3 ],
            [ 6, 3 ],
            [ 7, 3 ],
            [ 0, 4 ],
            [ 1, 4 ],
            [ 2, 4 ],
            [ 3, 4 ],
            [ 4, 4 ],
            [ 5, 4 ],
            [ 6, 4 ],
            [ 7, 4 ]
        ], false);

        this.sprite.play('idle');
       
        this.sprite.transform.size.x = 20;
        this.sprite.transform.size.y = 20;
        this.sprite.transform.position.x = -(this.sprite.transform.size.x / 2 - this.transform.size.x + 2);
        this.sprite.transform.position.y = -(this.sprite.transform.size.y - this.transform.size.y  - 1) - (this.config.offset || 0);

        this.appendChild(this.sprite);

        Physics.addCollider(this);
    }

    /**
     * Event: Tick
     */
    onTick(delta) {
        if(this.isPaused) { return; }

        // Count down crash timer
        if(this._crashTimer > 0)  {
            this._crashTimer -= delta;
        }

        // Calculate jump velocity
        this._jumpVelocity = this._jumpVelocity - delta * GRAVITY * WEIGHT;

        // Apply gravity
        this._positionDelta = Math.max(0, this._positionDelta + this._jumpVelocity * delta);
        
        // Update animation
        if(this._crashTimer > 0) {
            this.sprite.play('crash');

        } else if(this.isOnGround) {
            if(this.isRunning) {
                this.sprite.play('run');
                
                if(this.config.environment === 'sea') {
                    Audio.sfx('player/player-row', true);
                } else if(User.current.getClass() === 'robo') {
                    Audio.sfx('player/player-roll', true);
                } else {
                    Audio.sfx('player/player-walk', true);
                }
            } else {
                this.sprite.play('idle');
            }
        
        } else {
            this.sprite.play('jump');
        
        }

        // Set vertical position
        let baseline = Viewport.size.y - 11 - this.transform.size.y;
        this.transform.position.y = baseline - (this._positionDelta || 0);
    }

    /**
     * Event: Hit obstacle
     */
    onHitObstacle(obstacle) {
        // Remove the crsh sound in iPad for performance reasons
        if(!Tool.isIPad) {
            Audio.sfx(`player/player-obstacle-${this.config.environment || 'land'}`);
        }
        
        this._crashTimer = 0.5;
    }

    /**
     * Jump
     */
    jump() {
        if(!this.isOnGround || this._crashTimer > 0) { return; }
       
        // Remove the jump sound on iPad for performance reasons
        if(!Tool.isIPad) {
            Audio.sfx(`player/player-jump-${this.config.environment || 'land'}`);
        }

        this._jumpVelocity = JUMP_FORCE;
    }

    /**
     * Gets whether we're on the ground
     *
     * @return {Boolean} Is on ground
     */
    get isOnGround() {
        return this._positionDelta <= 0;
    }
}

JumpRunPlayer.register();

global.StageChallengeJumpRun = class StageChallengeJumpRun extends StageChallenge {
    /**
     * Get preloaded assets
     */
    static getPreloadedAssets(config) {
        let preload = [
            `/asset/player/player-jumprun-${config.environment || 'land'}-${User.current.getClass()}.png`,
            '/asset/stage/stage-challenge-jumprun-goal.svg',
            '/asset/player/player-crash-cloud.png',
            [ config.trackImage, Viewport.size.y, 'h' ],
            [ config.foregroundImage, Viewport.size.y, 'h' ],
            [ config.middlegroundImage, Viewport.size.y, 'h' ],
            [ config.backgroundImage, Viewport.size.y, 'h' ]
        ];

        if(config.obstacles) {
            for(let element of config.obstacles.itemListElement) {
                if(!element.item || !element.item.image) { continue; }

                preload.push([ element.item.image.source, element.item.size.x ]);
            }
        }
    
        return super.getPreloadedAssets(config).concat(preload);
    }
    
    /**
     * Gets the name of the music theme
     *
     * @return {String} Music
     */
    get music() {
        return 'theme-dramatic';
    }

    /**
     * Event: Init
     */
    onReady() {
        super.onReady();

        // Player
        if(!this.config.player) {
            this.config.player = {};
        }
        
        this.config.player.offset = this.offset;
        this.config.player.environment = this.config.environment;
        this.player = new JumpRunPlayer(this.config.player);

        this.player.onColliderEnter = (obstacle) => {
            this.onPlayerHitObstacle(obstacle);
        };
        
        this.appendChild(this.player);
        
        // Input events
        this.getDescendant('jump').onmousedown = (e) => {
            this.onPressJump();
        };

        this.getDescendant('jump').ontouchstart = (e) => {
            this.onPressJump();
        };

        this._progress = 0;
        
        // Crash
        this._crash = new JumpRunCrash();
        
        this.appendChild(this._crash);
    }

    /**
     * Event: Tutorial passed
     */
    onTutorialPassed() {
        super.onTutorialPassed();

        this.startCountdown();
    }
    
    /**
     * Event: Pause changed
     */
    onPauseChanged() {
        this.player.sprite.style.animationPlayState = this.isPaused ? 'paused' : 'running';
    }

    /**
     * Event: Draw
     */
    onDraw(context) {
        // Background
        Canvas.drawImageRepeatX(
            this.config.backgroundImage,
            -this._progress * BACKGROUND_SPEED,
            0,
            0,
            Viewport.size.y
        );
        
        // Middleground
        Canvas.drawImageRepeatX(
            this.config.middlegroundImage,
            -this._progress * MIDDLEGROUND_SPEED,
            0,
            0,
            Viewport.size.y
        );

        // Goal
        Canvas.drawImage(
            '/asset/stage/stage-challenge-jumprun-goal.svg',
            this.config.finishPosition - 20 - this._progress,
            Viewport.size.y - 10 - 40 + 7,
            40,
            40
        );
        
        // Track
        Canvas.drawImageRepeatX(
            this.config.trackImage,
            -this._progress,
            Viewport.size.y - TRACK_SIZE,
            TRACK_SIZE,
            TRACK_SIZE
        );
    }

    /**
     * Event: Draw deferred
     */
    onDrawDeferred(context) {
        // Foreground, but not on iPad
        if(!Tool.isIPad) { 
            Canvas.drawImageRepeatX(
                this.config.foregroundImage,
                -this._progress * FOREGROUND_SPEED,
                0,
                0,
                Viewport.size.y
            );
        } 
    }

    /**
     * Event: Tick
     */
    onTick(delta) {
        if(this.isPaused || !this.player.isRunning) { return; }

        // Speed modifier
        this._speedModifier = this._speedModifier === undefined ? 1 : this._speedModifier;
            
        if(this._speedModifier < 1) {
            this._speedModifier = Math.min(1, this._speedModifier + delta);
        }
            
        // In progress
        if(!this.isCompleted) {
            this._progress = (this._progress || 0) + delta * this.speed;
           
            if(this._progress >= (this.config.finishPosition || 100) - 10) {
                this.onComplete();
                return;
            }

            // Obstacles
            if(!this._obstacleTimer || this._obstacleTimer < 0) {
                this.insertObstacle();
                this._obstacleTimer = Math.floor(Math.random() * (this.config.obstacleTimeoutMax || 2)) + (this.config.obstacleTimeoutMin || 1);
            
            } else {
                this._obstacleTimer = (this._obstacleTimer || 0) - delta;

            }
      
            for(let obstacle of this._obstacles || []) {
                if(!obstacle || obstacle._isDestroyed) { continue; }

                obstacle.transform.position.x -= delta * this.speed;

                if(obstacle.transform.position.x < -obstacle.transform.size.x * 4) {
                    obstacle.destroy();
                }
            }

        // Completed
        } else {
            this.player.transform.position.x += delta * this.speed;

        }
    }

    /**
     * Event: Press jump
     */
    onKeyDown(e) {
        super.onKeyDown(e);

        if(e.keyCode === 32) {
            this.onPressJump();
        }
    }
            
    /**
     * Event: Press jump
     */
    onPressJump() {
        if(this.isPaused || !this.player.isRunning || this._isDestroyed) { return; }

        this.player.jump();
    }
    
    /**
     * Event: Player hit an obstacle
     *
     * @param {Obstacle} obstacle
     */
    async onPlayerHitObstacle(obstacle) {
        this._speedModifier = 0;
        
        if(this._obstacles) {
            let index = this._obstacles.indexOf(obstacle);

            if(index > -1) {
                this._obstacles.splice(index, 1);
            }
        }

        obstacle.destroy();

        this.player.onHitObstacle();
        
        this._crash.onCrash(obstacle);
    }

    /**
     * Event: Countdown ended
     */
    onCountdownEnded() {
        if(this._isDestroyed) { return; }

        this.getDescendant('countdown').innerHTML = ''; 
        this.player.isRunning = true;

        this.update();
    }

    /**
     * Gets the current speed
     *
     * @return {Number} Speed
     */
    get speed() {
        if(this.isPaused) { return 0; }

        return (this.config.speed || 50) * (this._speedModifier || 1);
    }
    
    /**
     * Gets the offset
     *
     * @return {Number} Offset
     */
    get offset() {
        if(isNaN(this.config.offset)) {
            return 0;
        }

        return this.config.offset;
    }
   
    /**
     * Gets all obstacles
     *
     * @return {Array} Obstacles
     */
    get obstacles() {
        if(!this.config || !this.config.obstacles) { return []; }

        let obstacles = [];

        for(let element of this.config.obstacles.itemListElement) {
            if(!element.item) { continue; }

            obstacles.push(element.item);
        }

        return obstacles;
    }

    /**
     * Inserts a new obstacle
     */
    insertObstacle() {
        let finishPosition = (this.config.finishPosition || 100) - this._progress;
        
        if(finishPosition < 100) { return; }
        
        let index = Math.floor(Math.random() * this.obstacles.length);
        let config = JSON.parse(JSON.stringify(this.obstacles[index]));

        config.layer = 1;

        let obstacle = new JumpRunObstacle(config);
            
        obstacle.transform.position.x = Viewport.size.x;
        obstacle.transform.position.y = Viewport.size.y - 11 - config.size.y;

        if(!this._obstacles) {
            this._obstacles = [];
        }
        
        this._obstacles.push(obstacle);

        this.appendChild(obstacle);
    }

    /**
     * Starts the countdown
     */
    startCountdown() {
        Audio.ui('stage/stage-challenge-countdown');
        
        this.setCountdown(3);

        setTimeout(() => {
            this.setCountdown(2);
        
            setTimeout(() => {
                this.setCountdown(1);

                setTimeout(() => {
                    this.onCountdownEnded();

                }, 1000);
            }, 1000);
        }, 1000);
    }

    /**
     * Sets the countdown timer
     *
     * @param {Number} time
     */
    setCountdown(time) {
        if(this._isDestroyed) { return; }

        this.getDescendant('countdown').innerHTML = time; 
    }

    /**
     * Gets the CSS
     */
    get css() { return super.css + `
        countdown {
            font-family: SketchNote, sans-serif;
            display: block;
            font-size: 8rem;
            position: absolute;
            z-index: 80;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        jump {
            ${Sprite.mixin('ui', 'button')}

            display: ${this.isCompleted ? 'none' : 'block'};
            position: absolute;
            left: calc(50% - 10rem);
            z-index: 50;
            bottom: 4rem;
        }
            jump:active {
                transform: scale(0.9);
            }
    `; }

    /**
     * Gets the HTML
     */
    get html() { return super.html + `
        <countdown></countdown>
        <jump>${L10N.translate('jump')}</jump>
    `; }
}

StageChallengeJumpRun.register();
