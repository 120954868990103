'use strict';

global.UIStar = class UIStar extends GameElement {
    /**
     * Gets the label
     *
     * @return {String} Label
     */
    get label() {
        return this.config.label || this.getAttribute('label') || '';
    }
    
    /**
     * Gets the value
     *
     * @return {Number} Value
     */
    get value() {
        return parseFloat(this.getAttribute('value'));
    }

    /**
     * Sets the value
     *
     * @param {Number} value
     */
    set value(value) {
        this.setAttribute('value', value);

        this.getDescendant('fill').style.height = `${this.value * this.size}rem`;
    }

    /**
     * Gets whether this star is small
     *
     * @return {Boolean} Is small
     */
    get isSmall() {
        return this.hasAttribute('small');
    }
    
    /**
     * Gets whether this star is tiny
     *
     * @return {Boolean} Is tiny
     */
    get isTiny() {
        return this.hasAttribute('tiny');
    }

    /**
     * Gets the size in world units
     *
     * @return {Number} Size
     */
    get size() {
        if(this.isTiny) {
            return 4;
        }

        if(this.isSmall) {
            return 6;
        }

        return 10;
    }

    /**
     * Gets the CSS
     */
    get css() { return `
        :host { 
            display: block;
            position: relative;
            width: ${this.size}rem;
            height: ${this.size}rem;
            ${Sprite.mapNamedCss('ui', 'star-blank')}
        }

        outline, fill, fill sprite {
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
        }

        outline {
            width: 100%;
            height: 100%;
            ${Sprite.mapNamedCss('ui', 'star-frame')}
        }

        fill {
            width: 100%;
            height: ${this.value * this.size}rem;
            overflow: hidden;
        }

        fill sprite {
            width: 100%;
            height: ${this.size}rem;
            ${Sprite.mapNamedCss('ui', 'star-fill')}
        }

        label {
            position: absolute;
            display: block;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: ${this.isSmall ? 1 : 2}rem;
            z-index: 20;
            font-weight: bold;
        }
    `; }

    /**
     * Gets the HTML
     */
    get html() { return `
        ${this.label ? `<label>${this.label}</label>` : ``}
        <fill>
            <sprite></sprite>
        </fill>
        <outline></outline>
    `; }
}

UIStar.register();
