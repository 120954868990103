'use strict';

global.Settings = class Settings {
    /**
     * Fetches the settings
     */
    static async load() {
        let data = {};

        try  {
            data = await Http.get('/api/settings');

        } catch(e) {
            Debug.error(this, e);

        }

        this.privacyPolicyUrl = data.privacyPolicyUrl || '';
        this.disclaimer = data.disclaimer || '';
        this.mapTutorial = data.mapTutorial || { itemListElement: [] };
        this.journalTutorial = data.journalTutorial || { itemListElement: [] };
        this.showcaseImagesTutorial = data.showcaseImagesTutorial || { itemListElement: [] };
    }
}
