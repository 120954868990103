'use strict';

const MARGIN = 2;

global.TopicMapCompass = class TopicMapCompass extends GameElement {
    /**
     * Gets the layer of this element
     *
     * @return {Number} Layer
     */
    get layer() {
        return 4;
    }
    
    /**
     * Event: Init
     */
    onReady() {
        this.transform.size.x = 14;
        this.transform.size.y = 14;
        this.transform.position.x = Viewport.size.x - this.transform.size.x - 2;
        this.transform.position.y = Viewport.size.y - this.transform.size.y - 2;
    }

    /**
     * Gets the player position
     *
     * @return {Vector} Position
     */
    getPlayerPosition() {
        let x = this.player.transform.position.x / this.size.x;
        let y = this.player.transform.position.y / this.size.y;

        x = MARGIN + (x * (14 - MARGIN * 2));
        y = MARGIN + (y * (14 - MARGIN * 2));

        x += this.transform.position.x;
        y += this.transform.position.y;

        return new Vector(x, y);
    }
   
    /**
     * Gets the position of a point
     *
     * @param {Object} level
     *
     * @return {Vector} Position
     */
    getPointPosition(level) {
        if(!level.position) {
            level.position = { x: 0, y: 0 };
        }
        
        if(!level.offset) {
            level.offset = { x: 0, y: 0 };
        }

        let x = (level.position.x + level.offset.x) / this.size.x;
        let y = (level.position.y + level.offset.y) / this.size.y;
        
        x = MARGIN + (x * (14 - MARGIN * 2));
        y = MARGIN + (y * (14 - MARGIN * 2));
        
        x += this.transform.position.x;
        y += this.transform.position.y;

        return new Vector(x, y);
    }

    /**
     * Gets the size of the map
     *
     * @return {Vector} size
     */
    get size() {
        if(!this.config || !this.config.size) { return new Vector(0,0); }

        return this.config.size;
    }

    /**
     * Gets player
     *
     * @return {TopicMapPlayer} Player
     */
    get player() {
        if(!this.config || !this.config.player) { return null; }

        return this.config.player;
    }

    /**
     * Gets levels
     *
     * @return {Array} Levels
     */
    get levels() {
        if(!this.config || !this.config.levels) { return []; }

        return this.config.levels;
    }
   
    /**
     * Event: Draw
     */
    onDrawDeferred(context) {
        // Draw background
        Canvas.drawNamedSprite(
            'ui',
            'compass',
            this.transform.position.x,
            this.transform.position.y,
            this.transform.size.x,
            this.transform.size.y
        );

        // Draw points
        for(let point of this.levels) {
            let pointPosition = this.getPointPosition(point);
            let isCompleted = User.current.isCompleted(point.identifier);

            if(point.isFinal && !isCompleted) {
                Canvas.drawNamedSprite(
                    'ui',
                    'point-final',
                    pointPosition.x - 1,
                    pointPosition.y - 1,
                    2,
                    2
                );

            } else {
                if(!isCompleted) {
                    context.fillStyle = '#d46161';

                } else {
                    context.fillStyle = '#81a459';
                
                }

                context.beginPath();
                context.arc(
                    pointPosition.x * Viewport.worldUnit,
                    pointPosition.y * Viewport.worldUnit,
                    0.5 * Viewport.worldUnit,
                    0,
                    2 * Math.PI
                );
                context.fill();
            }
        }
        
        // Draw player
        let playerPosition = this.getPlayerPosition();
        
        context.fillStyle = 'black';
        context.beginPath();
        context.arc(
            playerPosition.x * Viewport.worldUnit,
            playerPosition.y * Viewport.worldUnit,
            0.75 * Viewport.worldUnit,
            0,
            2 * Math.PI
        );
        context.fill();

    }
}

TopicMapCompass.register();
