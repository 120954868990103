'use strict';

const PERFECT_FRAME_TIME = 1000;

let tickInstances = [];
let lastTick = Date.now();
let isRunning = false;
let fps = {
    timer: 0,
    count: 0,
    value: 0
};

global.Clock = class Clock {
    /**
     * Gets the current delta
     */
    static get delta() {
        return Math.min(0.03, (Date.now() - lastTick) / PERFECT_FRAME_TIME);
    }

    /**
     * Gets frames per second
     */
    static get fps() {
        return fps.value;
    }

    /**
     * Add to tick
     *
     * @param {Object} instance
     */
    static add(instance) {
        if(typeof instance.onTick !== 'function') { return; }

        tickInstances.push(instance);
    }

    /**
     * Event: Tick
     */
    static onTick() {
        if(!isRunning) { return; }

        requestAnimationFrame(() => {
            this.onTick();
        });
       
        let delta = this.delta;
        lastTick = Date.now();

        if(fps.timer > 0) {
            fps.timer -= delta;
            fps.count++;

        } else {
            fps.timer = 1;
            fps.value = fps.count;
            fps.count = 0;

        }

        if(!document.hidden) {
            for(let i = tickInstances.length - 1; i >= 0; i--) {
                if(!tickInstances[i] || tickInstances[i]._isDestroyed) {
                    tickInstances.splice(i, 1);
                    continue;
                }

                tickInstances[i].onTick(delta);
            }
        }
    }

    /**
     * Gets all instances
     *
     * @return {Array} Instances
     */
    static get instances() {
        return tickInstances.concat([]);
    }

    /**
     * Starts the clock
     */
    static start() {
        if(isRunning) { return; }
        
        lastTick = Date.now();
        isRunning = true;
        requestAnimationFrame(() => {
            this.onTick();
        });
    }

    /**
     * Stops the clock
     */
    static stop() {
        isRunning = false;
    }

    /**
     * Gets whether the clock is running
     *
     * @return {Boolean} Is running
     */
    static get isRunning() {
        return isRunning;
    }

    /**
     * Repeat a function for N seconds
     *
     * @param {Number} seconds
     * @param {Function} callback
     */
    static async repeatForSeconds(seconds, callback) {
        let time = seconds;
        let last = Date.now();

        while(time > 0) {
            let now = Date.now();
            let delta = (now - last) / PERFECT_FRAME_TIME;
            last = now;
            time -= delta;

            callback();

            await this.waitForFrames(1);
        }
    }

    /**
     * Repeats a function at N interval for N repetitions
     *
     * @param {Number} interval
     * @param {Number} repetitions
     * @param {Function} callback
     */
    static async repeatAtInterval(interval, repetitions, callback) {
        while(repetitions > 0) {
            repetitions--;

            callback();

            await this.waitForSeconds(interval);
        }
    }
        
    /**
     * Wait for N seconds
     *
     * @param {Number} seconds
     */
    static async waitForSeconds(seconds) {
        await new Promise((resolve) => {
            setTimeout(resolve, seconds * 1000);
        });
    }
    
    /**
     * Wait for N animation frames
     *
     * @param {Number} frames
     */
    static async waitForFrames(frames) {
        for(let i = 0; i < frames; i++) {
            await new Promise((resolve) => {
                requestAnimationFrame(resolve);
            });
        }
    }
}

Clock.start();
