'use strict';

let current = null;

global.User = class User {
    /**
     * Constructor
     */
    constructor(data) {
        for(let k in data) {
            this[k] = data[k];
        }
    }

    /**
     * Gets the current user
     */
    static get current() {
        return current;
    }

    /**
     * Loads the current user
     */
    static async load() {
        let data = await Http.get('api/user/load');

        current = new User(data);
    }

    /**
     * Saves the current user state
     */
    async save() {
        await Http.post('api/user/save', this);
    }

    /**
     * Resets the user
     */
    async reset() {
        this.className = '';
        this.flags = {};
        this.finalQuestionAnswers = {};
        this.mapPositions = {};
        this.snippets = [];

        await this.save();

        location.reload();
    }

    /**
     * Gets a flag
     *
     * @param {String} name
     *
     * @return {Boolean} Value
     */
    getFlag(name) {
        return !!this.flags && this.flags[name] === true;
    }
   
    /**
     * Sets a flag
     *
     * @param {String} name
     * @param {Boolean} value
     */
    async setFlag(name, value) {
        if(!this.flags) {
            this.flags = {};
        }

        this.flags[name] = value;

        await this.save();
    }
   
    /**
     * Sets whether a level is completed
     *
     * @param {String} level
     *
     * @return {Boolean} Is completed
     */
    async setCompleted(level) {
        if(this.isCompleted(level)) { return; }

        await this.setFlag(`completed-level-${level}`, true);
    }

    /**
     * Gets whether a level is completed
     *
     * @param {String} level
     *
     * @return {Boolean} Is completed
     */
    isCompleted(level) {
        return this.getFlag(`completed-level-${level}`);
    }

    /**
     * Gets whether this user is a teacher
     *
     * @param {Boolean} Is teacher
     */
    get isTeacher() {
        return this.role === 'teacher';
    }
    
    /**
     * Gets whether this user is a student
     *
     * @param {Boolean} Is student
     */
    get isStudent() {
        return !this.isTeacher;
    }

    /**
     * Set final answer for topic
     *
     * @param {String} topic
     * @param {String} map
     * @param {String} text
     */
    setFinalQuestionAnswer(topic, map, text) {
        if(!this.finalQuestionAnswers) {
            this.finalQuestionAnswers = {};
        }

        if(!this.finalQuestionAnswers[topic] || this.finalQuestionAnswers[topic].constructor !== Object) {
            this.finalQuestionAnswers[topic] = {};
        }

        this.finalQuestionAnswers[topic][map] = text;

        this.save();
    }

    /**
     * Gets the final answer for a topic
     *
     * @param {String} topic
     * @param {String} map
     *
     * @return {String} Text
     */
    getFinalQuestionAnswer(topic, map) {
        if(!this.finalQuestionAnswers || !this.finalQuestionAnswers[topic]) { return ''; }

        return this.finalQuestionAnswers[topic][map] || '';
    }

    /**
     * Grants all snippets
     */
    async giveAllSnippets() {
        this.snippets = [];

        let snippets = await Http.get('/api/snippet');

        for(let snippet of snippets) {
            this.snippets.push(Tool.toVar(snippet.name));
        }

        await this.save();
    }

    /**
     * Completes all levels
     */
    async completeAllLevels() {
        let levels = await Http.get('/api/level');

        if(!this.flags) {
            this.flags = {};
        }
        
        for(let level of levels) {
            this.flags[`completed-level-${level.identifier}`] = true;
        }

        await this.save();
    }

    /**
     * Sets the journal placement of a snippet
     *
     * @param {String} id
     */
    setSnippetPlaced(id) {
        if(!id) { return; }

        this.setFlag(`placed-snippet-${id}`, true);

        this.save();
    }
    
    /**
     * Checks if the user has placed a snippet
     *
     * @param {String} id
     *
     * @return {Boolean} Is placed
     */
    isSnippetPlaced(id) {
        if(!id) { return false; }
        
        return this.getFlag(`placed-snippet-${Tool.toVar(id)}`);
    }
    
    /**
     * Checks if the user has a snippet
     *
     * @param {String} id
     *
     * @param {Boolean} Has snippet
     */
    hasSnippet(id) {
        if(!this.snippets || !id) { return false; }

        return this.snippets.indexOf(Tool.toVar(id)) > -1;
    }

    /**
     * Adds a snippet
     *
     * @param {String} id
     */
    addSnippet(id) {
        if(!id || this.hasSnippet(id)) { return; }

        if(!this.snippets) {
            this.snippets = [];
        }

        this.snippets.push(Tool.toVar(id));

        this.save();
    }
    
    /**
     * Gets whether the character class has been picked
     *
     * @return {Boolean} Has class
     */
    hasClass() {
        return !!this.className;
    }

    /**
     * Gets the character class
     *
     * @return {String} Class
     */
    getClass() {
        return this.className || 'robo';
    }

    /**
     * Sets the last position on a given map
     *
     * @param {String} mapId
     * @param {Vector} position
     */
    setLastMapPosition(mapId, position) {
        if(!this.mapPositions) {
            this.mapPositions = {};
        }

        this.mapPositions[mapId] = { x: position.x, y: position.y };

        this.save();
    }

    /**
     * Gets the last position on a given map
     *
     * @param {String} mapId
     *
     * @return {Vector} Position
     */
    getLastMapPosition(mapId) {
        if(!this.mapPositions || !this.mapPositions[mapId]) {
            return null;
        }

        return new Vector(this.mapPositions[mapId].x, this.mapPositions[mapId].y);
    }

    /**
     * Logs the user out
     */
    logout() {
        let res = document.cookie;
        let multiple = res.split(';');
        
        for(let i = 0; i < multiple.length; i++) {
            let key = multiple[i].split('=');
            
            document.cookie = key[0] + ' =; expires = Thu, 01 Jan 1970 00:00:00 UTC';
        }

        location.reload();
    }
}
