'use strict';

global.UIMusicToggle = class UIMusicToggle extends GameElement {
    /**
     * Event: Init
     */
    onReady() {
        this.onmousedown = (e) => {
            e.stopPropagation();

            this.onClick();
        };

        this.ontouchstart = (e) => {
            e.stopPropagation();

            this.onClick();
        };
    }

    /**
     * Event: Click
     */
    async onClick() {
        if(this._lastClick && Date.now() - this._lastClick < 500) { return; }

        this._lastClick = Date.now();

        await Audio.setMusicMuted(!Audio.isMusicMuted);

        this.update();
    }

    /**
     * Gets the css
     */
    get css() { return `
        :host {
            display: block;
            position: absolute;
            top: 2rem;
            left: 8rem;
            height: 4rem;
            width: 4rem;
            cursor: pointer;
            z-index: 60;

            ${Sprite.mapNamedCss('ui', Audio.isMusicMuted ? 'music-off' : 'music-on')}
        }
    `; }
}

UIMusicToggle.register();
