'use strict';

global.UIDisclaimer = class UIDisclaimer extends GameElement {
    /**
     * Event: Init
     */
    onReady() {
        this.getDescendant('open button').onclick = (e) => {
            this.onClickOpen();
        };
        
        this.getDescendant('close').onclick = (e) => {
            this.onClickClose();
        };
    }

    /**
     * Event: Click open
     */
    onClickOpen() {
        Audio.ui('ui/button-click');

        this.getDescendant('modal').setAttribute('open', true);
    }
    
    /**
     * Event: Click close
     */
    onClickClose() {
        Audio.ui('ui/button-click');

        this.getDescendant('modal').removeAttribute('open');
    }

    /**
     * Gets the HTML
     */
    get html() { return `
        <open>
            <button>${L10N.translate('disclaimer')}</button>

            ${Settings.privacyPolicyUrl ? `
                <a href="${Settings.privacyPolicyUrl}" target="_blank" rel="noopener noreferrer">${L10N.translate('privacy-policy')}</a>
            ` : ``}
        </open>
        <modal>
            <content>
                <close></close>
                <heading>${L10N.translate('disclaimer')}</heading>
                <text>${marked(Settings.disclaimer || '')}</text>
            </content>
        </modal>
    `; }

    /**
     * Gets the CSS
     */
    get css() { return `
        open {
            display: block;
            position: absolute;
            bottom: 2rem;
            left: 10rem;
            font-size: 1.2rem;
            font-weight: bold;
        }
            open button {
                padding: 0;
                margin: 0 1rem 0 0;
                cursor: pointer;
                background-color: transparent;
                border: 0;
                font: inherit;
                text-decoration: underline;
            }

            open a {
                color: inherit;
                text-decoration: underline;
            }
        
        :host([left]) open {
            left: 2rem;
        }

        modal {
            pointer-events: none;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            transition: opacity 0.33s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 300;
        }

        modal[open] {
            pointer-events: all;
            opacity: 1;
        }

        content {
            position: relative;
            width: calc(100% - 4rem);
            max-width: 50rem;
            padding: 2rem;
            background-color: white;
            color: black;

            ${Sprite.mixin('ui', 'frame-generic')}
        }

        heading {
            display: block;
            font-size: 3rem;
            margin-bottom: 2rem;
            font-family: SketchNote, sans-serif;
        }

        text {
            display: block;
            font-size: 1.5rem;
        }

        a {
            color: rgb(0, 0, 0);
        }

        close {
            position: absolute;
            display: block;
            cursor: pointer;
            top: 2rem;
            right: 2rem;
            width: 3rem;
            height: 3rem;
            ${Sprite.mapNamedCss('ui', 'close')}
        }
    `; }
}

UIDisclaimer.register();
