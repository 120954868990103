'use strict';
    
const STRINGS = {
    'de': {
        'all-timespans': 'alles anzeigen',
        'answer': 'Antworten',
        'back': 'Zurück',
        'captcha-is-wrong': 'Der Sicherheitscode war nicht korrekt',
        'captcha': 'Sicherheitscode eingeben',
        'class-selector-proceed': 'Weiter',
        'class-selector-start': 'START!',
        'class-selector': 'Charakterauswahl',
        'disclaimer': 'Disclaimer',
        'downloading-assets': 'Download läuft …',
        'enter-fullscreen': 'Klicken für Vollbild',
        'Invalid username': 'Ungültiger Benutzername',
        'journal-completion': 'Fortschritt bis zur Austellung',
        'journal': 'Forscherjournal',
        'jump': 'Springen',
        'level-complete-snippet': 'SNIPPET ERHALTEN',
        'level-complete': 'Challenge beendet!',
        'level-start': 'Challenge starten',
        'levels': 'Challenges',
        'login-has-account': 'Ich habe bereits einen Account',
        'login-needs-account': 'Ich habe noch keinen Account',
        'login': 'Einloggen',
        'logout': 'Logout',
        'more-correct-responses': '**ACHTUNG!** _Es gibt mehrere korrekte Anworten._',
        'new-session': 'Neue Session',
        'password-too-short': 'Passwort muss mindestens vier Zeichen lang sein',
        'password-wrong': 'Kombination aus Benutzer & Passwort nicht gefunden',
        'password': 'Passwort',
        'pause-heading': 'WAS? PAUSE??',
        'pause-map': 'Zurück zur Map',
        'pause-resume': 'Weiterspielen',
        'pick-role': 'Wie möchtest du spielen?',
        'preloading-assets': 'Laden …',
        'privacy-policy': 'Impressum/Datenschutz',
        'proceed': 'Weiter',
        'register': 'Registrieren',
        'retry': 'Wiederholen',
        'select': 'Auswählen',
        'send': 'Abschicken',
        'session-join-confirm': 'Beitreten',
        'session-join-heading': 'Einer Session beitreten',
        'session-join-id': 'XXXXXX',
        'session-manage-heading': 'Sessions verwalten',
        'session-not-found': 'Session nicht gefunden …',
        'session': 'Session',
        'single-player': 'Privatperson',
        'stage-challenge-dialog-proceed': 'Weiter',
        'stage-instructions-heading': 'AUFGABE',
        'stage-proceed': 'Hier geht’s weiter!',
        'student': 'Schüler_in',
        'teacher': 'Lehrer_in',
        'timespans-heading': 'Legende',
        'topic-complete-close': 'Schliessen',
        'topic-complete-heading': ' ',
        'topic-complete-text': ' ',
        'topic-journal-answer': 'Kapitelfrage beantworten',
        'topic-journal-enter': 'Hier eintauchen',
        'topic-selector-enter': 'Auswählen',
        'topic-selector-journal-description': 'Hier gehst du auf Abenteuer und sammelst deine Erlebnisse.',
        'topic-selector-world-map-description': 'Hier findest du alle Ereignisse auf einen Blick.',
        'topic-world-map': 'Übersichtskarte',
        'user-already-exists': 'Dieser Benutzername wird bereits verwendet',
        'Username must be at least 4 characters long': 'Benutzernamen muss mindestens 4 Zeichen haben',
        'username-too-short': 'Benutzername muss mindestens vier Zeichen lang sein',
        'username-wrong': 'Kombination aus Benutzer & Passwort nicht gefunden',
        'username': 'Benutzername',
        'world-map': 'Weltkarte',
    }
};

global.L10N = class L10N {
    /**
     * Gets the current language
     *
     * @return {String} Language
     */
    static get language() {
        return 'de';
    }

    /**
     * Translates a string
     *
     * @param {String} name
     * @param {Boolean} markdown
     *
     * @return {String} Translation
     */
    static translate(name, markdown) {
        let string = STRINGS[this.language][name] || `[${name}]`;

        if(markdown && string) {
            return marked(string);
        }

        return string;
    }
};
