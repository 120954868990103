'use strict';

global.Viewport = class Viewport {
    /**
     * Initialises the viewport
     */
    static init() {
        this.updateScaleVariable();

        window.addEventListener('resize', () => {
            this.updateScaleVariable();
        }, true)
    }

    /**
     * Enters full screenmode
     */
    static set fullscreen(isFullscreen) {
        if(isFullscreen) {
            let html = document.documentElement;

            if(html.requestFullscreen) {
                html.requestFullscreen();
            } else if(html.webkitRequestFullscreen) {
                html.webkitRequestFullscreen();
            }
        
        } else {
            if(document.exitFullscreen) {
                document.exitFullscreen();
            } else if(document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if(document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            }
        }
    }

    /**
     * Gets whether we're in fullscreen mode
     *
     * @return {Boolean} Is fullscreen
     */
    static get fullscreen() {
        return window.outerHeight - window.innerHeight < 2;
    }

    /**
     * Gets pixel ratio
     *
     * @return {Number} Ratio
     */
    static get pixelRatio() {
        let max = Tool.isIPad ? 1.25 : 1.75;

        return Math.max(1, Math.min(max, window.devicePixelRatio || 1));
    }

    /**
     * Gets the root element
     *
     * @return {HTMLElement} Root
     */
    static get root() {
        return document.body; 
    }
   
    /**
     * Sets the loading state
     */
    static set isLoading(value) {
        if(value) {
            this.root.setAttribute('loading', true);
        } else {
            this.root.removeAttribute('loading');
        }
    }

    /**
     * Sets the viewport offset
     *
     * @param {Vector} offset
     */
    static set offset(offset) {
        this._offset = new Vector(offset.x, offset.y);

        this.root.style = `transform: translate(${offset.x}rem, ${offset.y}rem);`;
        this.root.setAttribute('x', offset.x);
        this.root.setAttribute('y', offset.y);

        let event = new Event('offset');
        this.root.dispatchEvent(event);
    }

    /**
     * Gets the viewport offset
     *
     * @return {Vector} Offset
     */
    static get offset() {
        return this._offset || new Vector(
            parseFloat(this.root.getAttribute('x')) || 0,
            parseFloat(this.root.getAttribute('y')) || 0
        );
    }

    /**
     * Gets the viewport's offset from the client in pixels
     *
     * @return {Vector} Offset in pixels
     */
    static get screenOffset() {
        let bounds = this.root.getBoundingClientRect();

        return new Vector(
            bounds.x,
            bounds.y
        );
    }
    
    /**
     * Projects screen coordinates to world coordinates
     *
     * @param {Vector} position
     *
     * @return {Vector} World
     */
    static projectFromScreen(position) {
        position = position.subtract(this.screenOffset);
        position.x = this.toWorldUnits(position.x);
        position.y = this.toWorldUnits(position.y);
        position = position.round(2);

        return position;
    }

    /**
     * Gets the viewport dimensions
     *
     * @return {Vector} Size
     */
    static get size() {
        return new Vector(
            this.toWorldUnits(this.root.offsetWidth),
            this.toWorldUnits(this.root.offsetHeight)
        );
    }
    
    /**
     * Gets the world unit (1vw)
     *
     * @return {Number} World unit
     */
    static get worldUnit() {
        return document.body.offsetWidth / 100;
    }
    
    /**
     * Converts a pixel number to world units
     *
     * @param {Number} pixels
     *
     * @return {Number} World unit
     */
    static toWorldUnits(pixels) {
        return pixels / this.worldUnit;
    }

    /**
     * Updates the scale variable
     */
    static updateScaleVariable() {
        let scale = Math.min(1, window.innerHeight / 768);
        let style = document.querySelector('style');

        style.innerHTML = style.innerHTML.replace(/--viewport-scale: [0-9.]+;/, () => {
            return `--viewport-scale: ${scale};`;
        });
    }
}

Viewport.init();
