'use strict';

let layers = [];
let flip = { x: false, y: false };
let element = document.createElement('canvas');
let context = element.getContext('2d');

global.Canvas = class Canvas {
    /**
     * Gets the context
     */
    static get context() {
        return context;
    }

    /**
     * Scales the context
     *
     * @param {Boolean} x
     * @param {Boolean} y
     */
    static flip(x, y) {
        flip.x = x;
        flip.y = y;
    }

    /**
     * Restores the context
     */
    static unflip() {
        flip.x = false;
        flip.y = false;
    }
    
    /**
     * Gets whether a rectangle is within the frumstrum
     *
     * @param {Number} x
     * @param {Number} y
     * @param {Number} width
     * @param {Number} height
     *
     * @return {Bool} Is within fustrum
     */
    static isWithinFrustrum(x, y, width, height) {
        return x <= Viewport.size.x && y <= Viewport.size.y && x + width >= 0 && y + height >= 0;
    }

    /**
     * Add to draw
     *
     * @param {Object} instance
     */
    static add(instance, layer = 0) {
        if(
            typeof instance.onDraw !== 'function' &&
            typeof instance.onDrawDeferred !== 'function'
        ) { return; }

        while(layers.length <= layer) {
            layers.push([]);
        }

        layers[layer].push(instance);
    }
    
    /**
     * Draws bounds
     *
     * @param {Number} x
     * @param {Number} y
     * @param {Number} width
     * @param {Number} height
     */
    static drawBounds(x, y, width, height) {
        if(!this.isWithinFrustrum(x, y, width, height)) { return; }    

        context.setLineDash([]);
        context.lineWidth = 1;
        context.strokeStyle = 'red';
        context.strokeRect(
            x * Viewport.worldUnit,
            y * Viewport.worldUnit,
            width * Viewport.worldUnit,
            height * Viewport.worldUnit
        );
    }
    
    /**
     * Draws a stroke rectangle
     *
     * @param {Number} x
     * @param {Number} y
     * @param {Number} width
     * @param {Number} height
     * @param {String} strokeColor
     * @param {Number} strokeWidth
     * @param {Numebr} strokeDash
     */
    static drawStrokeRect(x, y, width, height, strokeColor, strokeWidth, strokeDash) {
        if(!this.isWithinFrustrum(x, y, width, height)) { return; }    

        if(strokeWidth) {
            context.lineWidth = strokeWidth * Viewport.worldUnit;
        }

        if(strokeDash) {
            context.setLineDash([ strokeDash * Viewport.worldUnit ]);
        }

        context.strokeStyle = strokeColor;
        context.strokeRect(
            x * Viewport.worldUnit,
            y * Viewport.worldUnit,
            width * Viewport.worldUnit,
            height * Viewport.worldUnit
        );
    }

    /**
     * Draws a cropped image
     *
     * @param {String} url
     * @param {Number} x
     * @param {Number} y
     * @param {Number} width
     * @param {Number} height
     * @param {Number} cropX
     * @param {Number} cropY
     * @param {Number} cropWidth
     * @param {Number} cropHeight
     */
    static drawCroppedImage(url, x, y, width, height, cropX, cropY, cropWidth, cropHeight) {
        if(!this.isWithinFrustrum(x, y, width, height)) { return; }    
       
        let image = Asset.get(url);

        if(!image) { return; }

        context.setTransform(1, 0, 0, 1, 0, 0);
        
        if(flip.x) {
            x *= -1;
            width *= -1;
            context.scale(-1, 1);
        }
        
        if(flip.y) {
            y *= -1;
            height *= -1;
            context.scale(1, -1);
        }
        
        x = Math.round(x * Viewport.worldUnit);
        y = Math.round(y * Viewport.worldUnit);
        width = Math.round(width * Viewport.worldUnit);
        height = Math.round(height * Viewport.worldUnit);

        try {
            context.drawImage(
                image,
                cropX,
                cropY,
                cropWidth,
                cropHeight,
                x,
                y,
                width,
                height
            );

        } catch(e) {
            // Image is broken, fail silently

        }
        
        context.setTransform(1, 0, 0, 1, 0, 0);
    }

    /**
     * Gets valid drawing rectangle for an image
     *
     * @param {Image} image
     * @param {Number} x
     * @param {Number} y
     * @param {Number} width
     * @param {Number} height
     *
     * @return {Object} Rectangle
     */
    static getDrawingRectangle(image, x, y, width, height) {
        if(!image) { return { x: 0, y:0, width: 0, height: 0 }; }

        if(!height && width) {
            height = width * (image.naturalHeight / image.naturalWidth);
        
        } else if(!width && height) {
            width = height * (image.naturalWidth / image.naturalHeight);

        } else if(!width && !height) {
            height = image.naturalHeight / Viewport.worldUnit;
            width = image.naturalWidth / Viewport.worldUnit;

        }
       
        if(flip.x) {
            x -= Viewport.size.x - width;
            width *= -1;
        }
        
        if(flip.y) {
            y -= Viewport.size.y - height;
            height *= -1;
        }
       
        return {
            x: x,
            y: y,
            width: width,
            height: height
        };
    }

    /**
     * Draws an image
     *
     * @param {String} url
     * @param {Number} x
     * @param {Number} y
     * @param {Number} width
     * @param {Number} height
     */
    static drawImage(url, x, y, width, height) {
        let image = Asset.get(url);

        if(!image) { return; }

        let rect = this.getDrawingRectangle(image, x, y, width, height);

        if(!this.isWithinFrustrum(rect.x, rect.y, rect.width, rect.height)) { return; }
        
        try {
            context.drawImage(
                image,
                Math.round(rect.x * Viewport.worldUnit),
                Math.round(rect.y * Viewport.worldUnit),
                Math.round(rect.width * Viewport.worldUnit),
                Math.round(rect.height * Viewport.worldUnit)
            );

        } catch(e) {
            // Image is broken, fail silently

        }
    }
    
    /**
     * Draws an image repeatedly along the X axis
     *
     * @param {String} url
     * @param {Number} x
     * @param {Number} y
     * @param {Number} width
     * @param {Number} height
     */
    static drawImageRepeatX(url, x, y, width, height) {
        let image = Asset.get(url);

        if(!image) { return; }

        let rect = this.getDrawingRectangle(image, x, y, width, height);
        
        while(rect.x + rect.width < -rect.width) {
            rect.x += rect.width;
        }
        
        try {
            while(rect.x + rect.width <= Viewport.size.x + rect.width) {
                context.drawImage(
                    image,
                    Math.round(rect.x * Viewport.worldUnit),
                    Math.round(rect.y * Viewport.worldUnit),
                    Math.round(rect.width * Viewport.worldUnit),
                    Math.round(rect.height * Viewport.worldUnit)
                );

                rect.x += rect.width;
            }
            
        } catch(e) {
            // Image is broken, fail silently

        }
    }

    /**
     * Draws a named sprite
     *
     * @param {String} mapName
     * @param {String} spriteName
     * @param {Number} x
     * @param {Number} y
     * @param {Number} width
     * @param {Number} height
     */
    static drawNamedSprite(mapName, spriteName, x, y, width, height) {
        let map = Sprite.getMap(mapName);

        if(!map.sprites || !map.sprites[spriteName]) {
            Debug.error(this, new Error(`Mapped sprite ${mapName}/${spriteName} not found`));
        }

        let sprite = map.sprites[spriteName];

        this.drawPositionedSprite(mapName, sprite.x, sprite.y, x, y, width, height);
    }
    
    /**
     * Draws a positioned sprite
     *
     * @param {String} mapName
     * @param {String} spriteX
     * @param {String} spriteY
     * @param {Number} x
     * @param {Number} y
     * @param {Number} width
     * @param {Number} height
     */
    static drawPositionedSprite(mapName, spriteX, spriteY, x, y, width, height) {
        let map = Sprite.getMap(mapName);
        
        this.drawCroppedImage(
            map.image,
            x,
            y,
            width,
            height,
            spriteX * map.unit,
            spriteY * map.unit,
            map.unit,
            map.unit
        );
    }

    /**
     * Draws a string
     *
     * @param {String} string
     * @param {Number} x
     * @param {Number} y
     * @param {String} color
     */
    static drawText(string, x, y, color = 'red') {
        context.font = '2rem sans-serif';
        context.fillStyle = color;
        context.fillText(string, x * Viewport.worldUnit, y * Viewport.worldUnit);
    }

    /**
     * Event: Tick
     */
    static onTick(delta) {
        if(document.hidden) { return; }

        if(element.parentElement !== document.body) {
            document.body.prepend(element);
        }
        
        element.width = document.body.offsetWidth;
        element.height = document.body.offsetHeight;

        context.clearRect(0, 0, element.width, element.height);
        context.imageSmoothingQuality = 'high';
        context.imageSmoothingEnabled = true;

        for(let instances of layers) {
            for(let i = instances.length - 1; i >= 0; i--) {
                if(!instances[i] || instances[i]._isDestroyed) {
                    instances.splice(i, 1);
                    continue;
                }

                if(instances[i]._isHidden) { continue; }

                if(typeof instances[i].onDraw === 'function') {
                    instances[i].onDraw(context);
                }
            }
        }
        
        for(let instances of layers) {
            for(let instance of instances) {
                if(instance._isHidden) { continue; }
                
                if(typeof instance.onDrawDeferred === 'function') {
                    instance.onDrawDeferred(context);
                }
            }
        }
    }
}

Clock.add(Canvas);
