'use strict';

const DB_VERSION = 1;

global.Asset = class Asset {
    /**
     * Preloads assets into memory
     *
     * @param {Array} urls
     * @param {Boolean} clear
     */
    static async preload(urls, clear = true) {
        let preloader = new UIPreloader();

        preloader.text = L10N.translate('preloading-assets');

        urls = urls.concat([]);

        if(clear || !this.cache) {
            this.cache = {};
        }

        await Clock.waitForFrames(10);

        preloader.show();

        for(let url of urls) {
            if(!url) { continue; }

            let size = 0;
            let dimension = 'w';

            if(Array.isArray(url)) {
                if(url[1]) {
                    size = url[1];
                }

                if(url[2] === 'w' || url[2] === 'h') {
                    dimension = url[2];
                }
                
                url = url[0];
            }

            Debug.log(this, `Preloading ${url}...`);

            await this.preloadOne(url, size, dimension);
            
            preloader.progress += 1 / urls.length;
        }
            
        preloader.progress = 1;
        
        preloader.hide();

        await Clock.waitForSeconds(0.5);

        preloader.destroy();
    }

    /**
     * Initialises the assets
     */
    static async load() {
        this.timestamps = {};

        for(let asset of await Http.get('/api/assets')) {
            this.timestamps[asset.url] = asset.timestamp;
        }
    }

    /**
     * Downloads an asset
     *
     * @param {String} url
     *
     * @return {String} Object URL
     */
    static downloadBlob(url, size = 0, dimension = 'w') {
        if(typeof url === 'object' && url.contentUrl) {
            url = url.contentUrl;
        }
        
        if(typeof url !== 'string') {
            Debug.error(this, new Error(`Url was not a string: ${JSON.stringify(url)}`));
            return '';
        }
      
        if(this.isBitmapUrl(url)) {
            url = `/rx/${size ? dimension + '_' + Math.min(4096, Math.round(size * Viewport.worldUnit * Viewport.pixelRatio)) : '-'}/127.0.0.1${url}`;
        }

        return new Promise((resolve, reject) => {
            let request = new XMLHttpRequest();
            request.open('GET', url, true);
            request.responseType = 'blob';

            request.onload = () => {
                let blob = request.response;

                resolve(blob);
            };

            request.onerror = (e) => {
                Debug.error(this, e);
                resolve(null);
            };

            request.send();
        });
    }

    /**
     * Gets the database
     *
     * @return {IndexedDb} Database
     */
    static getDatabase() {
        if(this._database) {
            return this._database;
        }

        return new Promise((resolve, reject) => {
            let request = indexedDB.open('assets', DB_VERSION);

            request.onupgradeneeded = (e) => {
                let database = e.target.result;
                
                database.createObjectStore('blobs');
                database.createObjectStore('timestamps');
            };

            request.onsuccess = (e) => {
                this._database = request.result;

                resolve(this._database);
            };

            request.onerror = (e) => {
                Debug.error(this, e);
                resolve(null);
            };
        });
    }
    
    /**
     * Gets all timestamps from the database
     *
     * @return {Array} Timestamps
     */
    static async getTimestamps() {
        let database = await this.getDatabase(); 
        let transaction = database.transaction(['timestamps'], 'readonly');
        let store = transaction.objectStore('timestamps');

        let keys = await new Promise((resolve) => {
            let get = store.getAllKeys();
            
            get.onsuccess = (e) => {
                resolve(e.target.result);
            };

            get.onerror = (e) => {
                Debug.error(this, e);
                resolve([]);
            };
        });
        
        let values = await new Promise((resolve) => {
            let get = store.getAll();
            
            get.onsuccess = (e) => {
                resolve(e.target.result);
            };

            get.onerror = (e) => {
                Debug.error(this, e);
                resolve([]);
            };
        });

        let timestamps = {};

        for(let i = 0; i < keys.length; i++) {
            timestamps[keys[i]] = values[i];
        }

        return timestamps;
    }
    
    /**
     * Gets a timestamp from the database
     *
     * @param {String} url
     *
     * @return {Number} Timestamp
     */
    static async getTimestamp(url) {
        if(!url) {
            return 0;
        }

        if(typeof url === 'object' && url.contentUrl) {
            url = url.contentUrl;
        }
        
        if(typeof url !== 'string') {
            Debug.error(this, new Error(`Url was not a string: ${JSON.stringify(url)}`));
            return 0;
        }
        
        let database = await this.getDatabase(); 
        let transaction = database.transaction(['timestamps'], 'readonly');
        let store = transaction.objectStore('timestamps');
        let get = store.get(url);

        return await new Promise((resolve) => {
            get.onsuccess = (e) => {
                resolve(e.target.result);
            };

            get.onerror = (e) => {
                Debug.error(this, e);
                resolve(null);
            };
        });
    }

    /**
     * Gets an asset from the database
     *
     * @param {String} url
     *
     * @return {String} Object URL
     */
    static async getBlob(url) {
        if(typeof url === 'object' && url.contentUrl) {
            url = url.contentUrl;
        }
        
        if(typeof url !== 'string') {
            Debug.error(this, new Error(`Url was not a string: ${JSON.stringify(url)}`));
            return null;
        }

        if(this.timestamps && this.timestamps[url]) {
            let timestamp = await this.getTimestamp(url);

            if(!timestamp || timestamp < this.timestamps[url]) {
                return null;
            }
        }

        let database = await this.getDatabase(); 
        let transaction = database.transaction(['blobs'], 'readonly');
        let store = transaction.objectStore('blobs');
        let get = store.get(url);

        return await new Promise((resolve) => {
            get.onsuccess = (e) => {
                resolve(e.target.result);
            };

            get.onerror = (e) => {
                Debug.error(this, e);
                resolve(null);
            };
        });
    }

    /**
     * Puts an asset into the database
     *
     * @param {String} url
     * @param {String} blob
     */
    static async putBlob(url, blob) {
        if(!url) {
            Debug.error(this, new Error('No URL was provided'));
            return;
        }
        
        if(!blob) {
            Debug.error(this, new Error(`No blob was provided for URL ${url}`));
            return;
        }
        
        if(typeof url === 'object' && url.contentUrl) {
            url = url.contentUrl;
        }
        
        if(typeof url !== 'string') {
            Debug.error(this, new Error(`Url was not a string: ${JSON.stringify(url)}`));
            return;
        }

        let database = await this.getDatabase(); 
        let transaction = database.transaction(['blobs', 'timestamps'], 'readwrite');
       
        // Put the new timestamp
        let putTimestamp = transaction.objectStore('timestamps').put(Date.now(), url);

        await new Promise((resolve) => {
            putTimestamp.onsuccess = (e) => {
                resolve();
            };

            putTimestamp.onerror = (e) => {
                Debug.error(this, e);
                resolve();
            };
        });
        
        // Put the new blob
        let putBlob = transaction.objectStore('blobs').put(blob, url);

        await new Promise((resolve) => {
            putBlob.onsuccess = (e) => {
                resolve();
            };

            putBlob.onerror = (e) => {
                Debug.error(this, e);
                resolve();
            };
        });
    }

    /**
     * Fetches an asset from cache, or downloads it if necessary
     *
     * @param {String} url
     *
     * @return {String} Blob
     */
    static async fetchBlob(url, size, dimension) {
        // First attempt to get from database
        let result = await this.getBlob(url);

        // Then attempt to get from HTTP
        if(!result) {
            result = await this.downloadBlob(url, size, dimension);

            // Put result of HTTP request in database
            if(result) {
                await this.putBlob(url, result);
            }
        }

        return result;
    }

    /**
     * Preloads a single asset into memory
     *
     * @param {String|Object} url
     */
    static async preloadOne(url, size = 0, dimension = 'w') {
        if(!url) {
            Debug.error(this, new Error('The url was null'));
            return;
        }

        if(typeof url === 'object' && url.contentUrl) {
            url = url.contentUrl;
        }

        if(typeof url !== 'string') {
            Debug.error(this, new Error(`Url was not a string: ${JSON.stringify(url)}`));
            return;
        }

        // Video
        if(url.toLowerCase().indexOf('.mp4') > -1) {
            let video = document.createElement('video');

            await new Promise((resolve, reject) => {
                video.oncanplaythrough = resolve;
                video.onerror = reject;
            
                video.src = url;
                video.load();
            });
            
        // Images
        } else {
            if(!this.cache) {
                this.cache = {};
            }

            let image = new Image();

            this.cache[url] = image;

            if(Debug.isActive) {
                image.src = url;

                if(!image.ready) {
                    await new Promise((resolve, reject) => {
                        image.onload = resolve;
                        image.onerror = reject;
                    });
                    
                    image.ready = true;
                }

            } else {
                let blob = null;

                try {
                    blob = await this.fetchBlob(url, size, dimension);
                } catch(e) {
                    Debug.error(this, e);
                }

                if(blob) {
                    image.src = URL.createObjectURL(blob);
                
                } else {
                    image.src = url;

                }
                    
                image.ready = true;
            }
        }
    }

    /**
     * Gets an image from the cache
     *
     * @param {String} url
     *
     * @return {HTMLImageElement} image
     */
    static get(url, size) {
        if(!url) { return null; }
        
        if(typeof url === 'object' && url.contentUrl) {
            url = url.contentUrl;
        }

        if(typeof url !== 'string') {
            Debug.error(this, new Error(`Url was not a string: ${JSON.stringify(url)}`));
            return null;
        }

        if(!this.cache) {
            this.cache = {};
        }

        // Lazy load asset if needed
        if(!this.cache[url]) {
            this.preloadOne(url, size);
            return null;

        // Check if asset is ready before returning it
        } else if(!this.cache[url].ready) {
            return null;

        }
    
        // Asset is downloaded, ready to be used
        return this.cache[url];
    }

    /**
     * Gets if a URL points to a bitmap
     *
     * @param {String} url
     *
     * @return {Boolean} Is bitmap URL
     */
    static isBitmapUrl(url) {
        if(!url) { return false; }

        url = url.toLowerCase();

        return url.endsWith('.png') || url.endsWith('.jpg') || url.endsWith('.jpeg');
    }

    /**
     * Gets a blob URL from the cache
     *
     * @param {String} url
     *
     * @return {String} Blob URL
     */
    static getUrl(url, size = 0, dimension = 'w') {
        if(!url) { return ''; }

        if(typeof url === 'object' && url.contentUrl) {
            url = url.contentUrl;
        }

        if(typeof url !== 'string') {
            Debug.error(this, new Error(`Url was not a string: ${JSON.stringify(url)}`));
            return null;
        }
        
        if(Debug.isActive) { return url; }
                
        let image = this.get(url);

        if(!image) {
            if(this.isBitmapUrl(url)) {
                return `/rx/${size ? dimension + '_' + Math.min(4096, Math.round(size * Viewport.worldUnit * Viewport.pixelRatio)) : '-'}/127.0.0.1${url}`;
            }

            return url;
        }

        return image.src;
    }
};
