'use strict';

global.Level = class Level extends GameElement {
    /**
     * Constructor
     */
    constructor(data) {
        super(data);

        Level.current = this;
    }

    /**
     * Preload
     */
    async load() {
        Audio.sfx(null);
        
        let preload = [];

        if(this.snippet && this.snippet.image) {
            preload.push([ this.snippet.image, 100 ]);
        }

        for(let stage of this.stages) {
            let model = global[stage['@type']];

            if(!model) { continue; }

            preload = preload.concat(model.getPreloadedAssets(stage))
        }

        await Asset.preload(preload);
    }

    /**
     * Event: Init
     */
    onReady() {
        Audio.sfx(null);
        
        this.startNextStage(); 

        this.getDescendant('complete action[back]').onclick = (e) => {
            this.onClickBack();
        };
        
        this.getDescendant('complete action[retry]').onclick = (e) => {
            this.onClickRetry();
        };
    }
    
    /**
     * Event: Destroy
     */
    onDestroy() {
        if(Level.current === this) {
            Level.current = null;
        }
    }

    /**
     * Gets the snippet config
     *
     * @return {Object} Snippet
     */
    get snippet() {
        return this.levelConfig.snippet;
    }
    
    /**
     * Gets the map config
     *
     * @return {Object} Map
     */
    get mapConfig() {
        if(!this._mapConfig) {
            this._mapConfig = this.config.maps[this.mapId];
        }

        return this._mapConfig;
    }

    /**
     * Gets the level config
     *
     * @return {Object} Level
     */
    get levelConfig() {
        if(!this._config && this.mapConfig) {
            this._config = this.mapConfig.levels[this.id];
        }

        return this._config; 
    }

    /**
     * Gets a list of stages
     *
     * @return {Array} Stages
     */
    get stages() {
        let stages = [];

        for(let element of this.levelConfig.stages.itemListElement) {
            stages.push(element.item);
        }

        return stages;
    }

    /**
     * Event: Complete
     */
    onComplete() {
        this.isCompleted = true; 

        if(this.snippet && this.snippet.name) {
            User.current.addSnippet(this.snippet.name);
            
            Audio.ui('level/snippet-get');
        }

        this.update();

        User.current.setCompleted(this.id);
    }
    
    /**
     * Event: Click back
     */
    onClickBack() {
        Audio.ui('ui/button-click');

        Router.go(`/topic/${this.topicId}/map/${this.mapId}`);
    }

    /**
     * Event: Click retry
     */
    onClickRetry() {
        Audio.ui('ui/button-click');
        
        Router.reload(); 
    }

    /**
     * Gets the topic of this level
     *
     * @return {String} Topic
     */
    get topicId() {
        return Router.path[1];
    }
    
    /**
     * Gets the map of this level
     *
     * @return {String} Map
     */
    get mapId() {
        return Router.path[3];
    }
    
    /**
     * Gets the id of this level
     *
     * @return {String} Id
     */
    get id() {
        return Router.path[5];
    }

    /**
     * Starts the next stage
     */
    async startNextStage() {
        if(!this.levelConfig.stages) {
            return Debug.error(new Error('No stages in config'));
        }

        if(this._currentStageIndex === undefined) {
            this._currentStageIndex = 0;

        } else if(this._currentStageIndex >= this.stages.length - 1) {
            this.onComplete();
            return;

        } else {
            this._currentStageIndex++;
        
        }

        let config = this.stages[this._currentStageIndex];

        if(!config) {
            return Debug.error(this, new Error(`No config for stage index ${this._currentStageIndex}`));
        }
        
        if(!config['@type']) {
            return Debug.error(this, new Error(`Missing "type" parameter in config`));
        }

        let model = global[config['@type']];
        
        if(!model) {
            return Debug.error(this, new Error(`No model found for stage type "${config.type}"`));
        }
        
        if(this.currentStage) {
            this.currentStage.setAttribute('transitioning', 'out');
            
            await this.currentStage.transitionOut();

            this.currentStage.destroy();
        }

        let nextStage = new model(config);
        nextStage.level = this;

        this.getDescendant('stage').appendChild(nextStage);
        
        await nextStage.waitUntilReady(); 
            
        nextStage.setAttribute('transitioning', 'in');

        await nextStage.transitionIn();
        
        nextStage.removeAttribute('transitioning');

        this.currentStage = nextStage;
    }
    
    /**
     * Transition in
     */
    async transitionIn() {
        await Clock.waitForSeconds(0.5); 
    }

    /**
     * Transition out
     */
    async transitionOut() {
        await Clock.waitForSeconds(0.5);
    }

    /**
     * Gets the HTML
     */
    get html() { return `
        <transition></transition>
        <stage></stage>
        <complete>
            <box>
                <heading>${L10N.translate('level-complete')}</heading>
                ${this.snippet && this.snippet.image ? `
                    <snippet>
                        <heading>${L10N.translate('level-complete-snippet')}</heading>
                        <img src="${Asset.getUrl(this.snippet.image)}">
                    </snippet>
                ` : ``}
                <actions>
                    <action retry>${L10N.translate('retry')}</action>
                    <action back>${L10N.translate('back')}</action>
                </actions>
            </box>
            <inventory></inventory>
        </complete>
    `; }

    /**
     * Gets the CSS
     */
    get css() { return `
        :host {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 900;
        }

        @keyframes inventory {
            0% { transform: none; }
            10% { transform: scale(1.4) rotate(-6deg); }
            20% { transform: none; }
            30% { transform: scale(1.4) rotate(8deg); }
            40% { transform: none; }
        }

        stage {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
        }
        
        /* Transition */
        transition {
            position: absolute;
            z-index: 900;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: white;
            opacity: 0;
            transition: opacity 0.5s ease;
            pointer-events: none;
        }
            :host([transitioning]) transition {
                opacity: 1;
                pointer-events: all;
            }

        /* Complete */
        complete {
            display: ${this.isCompleted ? 'flex' : 'none'};
            position: absolute;
            z-index: 200;
            background-color: rgba(0, 0, 0, 0.5);
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
        }
            complete inventory {
                display: ${this.snippet && this.snippet.image ? 'block' : 'none'};
                left: 2rem;
                bottom: 2rem;
                position: absolute;
                width: 4rem;
                height: 4rem;
                animation: inventory 2s ease infinite;

                ${Sprite.mapNamedCss('ui', 'inventory-toggle')}
            }
                complete inventory::after {
                    content: '';
                    width: 1.2rem;
                    height: 1.2rem;
                    border-radius: 50%;
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0.4rem;
                    background-color: var(--color-alert);
                }

            complete heading {
                display: block;
                font-size: 3rem;
                font-family: SketchNote, sans-serif;
            }

            complete box {
                padding: 2rem 4rem;
                display: block;
                text-align: center;
                position: relative;
            }
                complete box::before,
                complete box::after {
                    content: '';
                    display: block;
                    position: absolute;
                    transform: rotate(-2deg);
                }
                
                complete box::before {
                    background-color: white;
                    z-index: -2;
                    top: -1.8rem;
                    left: -1.8rem;
                    width: calc(100% + 3.6rem);
                    height: calc(100% + 3.6rem);
                }

                complete box::after {
                    z-index: -1;
                    top: -2rem;
                    left: -2rem;
                    width: calc(100% + 4rem);
                    height: calc(100% + 4rem);

                    ${Sprite.mixin('ui', 'frame-generic')}
                }

            complete actions {
                display: flex;
            }

            complete action {
                ${Sprite.mixin('ui', 'button')}
            }
                complete action:active {
                    transform: scale(0.9);
                }

            complete snippet {
                margin-top: 4rem;
                font-size: 2rem;
                background-color: var(--color-primary);
                display: block;
                position: relative;
                padding: 2rem 0;
            }
                complete snippet heading {
                    font-size: 2rem;
                    position: absolute;
                    width: 100%;
                    top: -1rem;
                }

                complete snippet img {
                    display: block;
                    margin: 0 auto;
                    max-width: 30rem;
                    max-height: 20rem;
                    transform: rotate(-4deg);
                }
    `; }
}

Level.register();
