'use strict';

/**
 * The crash effect
 */
class DodgeCrash extends KeyframeSprites {
    get layer() { return 2; }

    /**
     * Constructor
     */
    constructor(data) {
        data = data || {};

        data.map = 'player-crash-cloud';

        super(data);
        
        this.add('crash', [
            [ 0, 0 ],
            [ 1, 0 ],
            [ 2, 0 ],
            [ 3, 0 ],
            [ 0, 1 ],
            [ 1, 1 ],
            [ 2, 1 ],
            [ 3, 1 ],
            [ 0, 2 ],
        ], false);

        this.transform.position = this.transform.size.multiply(-0.9);

        this.play('crash');
    }

    /**
     * Event: Crash
     *
     * @param {DodgeObstacle} obstacle
     */
    onCrash(obstacle) {
        this.transform.position = obstacle.transform.position.add(obstacle.config.image.offset).subtract(Vector.ONE);
        this.transform.size = Vector.ONE.multiply(obstacle.config.image.size + 2);
        this.play('crash');
    }
}

DodgeCrash.register();

/**
 * The obstacle 
 */
class DodgeObstacle extends GameElement {
    /**
     * Event: Init
     */
    onReady() {
        Physics.addCollider(this);

        this.transform.size.x = this.config.size.x;
        this.transform.size.y = this.config.size.y;    
    }

    /**
     * Event: Draw
     */
    onDraw(context) {
        if(!this.config.image || !this.config.image.source) { return; }

        Canvas.drawImage(
            this.config.image.source,
            this.transform.position.x + this.config.image.offset.x,
            this.transform.position.y + this.config.image.offset.y,
            this.config.image.size
        );
    }
}

DodgeObstacle.register();

/**
 * The player
 */
class DodgePlayer extends GameElement {
    /**
     * Event: Init
     */
    onReady() {
        this._crashTimer = 0;
        
        this.transform.size.x = 4;
        this.transform.size.y = 6;
        this.transform.position.y = Viewport.size.y - 20;
        this.transform.position.x = Viewport.size.x / 2 - this.transform.size.x / 2;;
        
        Physics.addCollider(this);
       
        // Sprite
        this.sprite = new KeyframeSprites({
            map: `player-dodge-${this.config.environment || 'sea'}-${User.current.getClass()}`
        });

        this.sprite.add('go', [
            [ 6, 2 ],
            [ 7, 2 ],
            [ 0, 3 ],
            [ 1, 3 ],
            [ 2, 3 ],
            [ 3, 3 ],
            [ 4, 3 ],
            [ 5, 3 ],
            [ 6, 3 ],
            [ 7, 3 ],
            [ 0, 4 ],
            [ 1, 4 ],
            [ 2, 4 ],
            [ 3, 4 ],
            [ 4, 4 ],
            [ 5, 4 ],
            [ 6, 4 ],
            [ 7, 4 ],
        ]);

        this.sprite.add('crash', [
            [ 0, 0 ],
            [ 1, 0 ],
            [ 2, 0 ],
            [ 3, 0 ],
            [ 4, 0 ],
            [ 5, 0 ],
            [ 6, 0 ],
            [ 7, 0 ],
            [ 0, 1 ],
            [ 1, 1 ],
            [ 2, 1 ],
            [ 3, 1 ],
            [ 4, 1 ],
            [ 5, 1 ],
            [ 6, 1 ],
            [ 7, 1 ],
            [ 0, 2 ],
            [ 1, 2 ],
            [ 2, 2 ],
            [ 3, 2 ],
            [ 4, 2 ],
            [ 5, 2 ],
        ], false);

        this.sprite.play('go');
        
        this.sprite.transform.size.x = 20;
        this.sprite.transform.size.y = 20;
        this.sprite.transform.position.x = -(this.sprite.transform.size.x / 2 - this.transform.size.x + 2);
        this.sprite.transform.position.y = -(this.sprite.transform.size.y / 2 - this.transform.size.y + 2);

        this.appendChild(this.sprite);
    }

    /**
     * Event: Tick
     */
    onTick(delta) {
        if(!Stage.current || !Stage.current.isTutorialPassed) { return; }
            
        // Count down crash timer
        if(this._crashTimer > 0)  {
            this._crashTimer -= delta;
        }
        
        if(this._crashTimer > 0) {
            this.sprite.play('crash');

        } else {
            this.sprite.play('go');
            
            if(this.config.environment === 'sea') {
                Audio.sfx('player/player-row', true);
            } else if(User.current.getClass() === 'robo') {
                Audio.sfx('player/player-roll', true);
            } else {
                Audio.sfx('player/player-walk', true);
            }
        }
    }

    /**
     * Event: Hit obstacle
     *
     * @param {DodgeObstacle} obstacle
     */
    onHitObstacle(obstacle) {
        Audio.sfx(`player/player-obstacle-${this.config.environment || 'land'}`);

        this._crashTimer = 0.5;
    }
}

DodgePlayer.register();

global.StageChallengeDodge = class StageChallengeDodge extends StageChallenge {
    /**
     * Get preloaded assets
     */
    static getPreloadedAssets(config) {
        let preload = [
            `/asset/player/player-dodge-${config.environment || 'sea'}-${User.current.getClass()}.png`,
            '/asset/player/player-crash-cloud.png',
            '/asset/stage/stage-challenge-dodge-goal.svg',
            [ config.backgroundImage, 100 ]
        ];

        if(config.obstacles) {
            for(let element of config.obstacles.itemListElement) {
                let obstacle = element.item;
                
                if(!obstacle.image || !obstacle.image.source) { continue; }

                preload.push([ obstacle.image.source, obstacle.size.x ]);
            }
        }
        
        return super.getPreloadedAssets(config).concat(preload);
    }
    
    /**
     * Gets the name of the music theme
     *
     * @return {String} Music
     */
    get music() {
        return 'theme-dramatic';
    }
    
    /**
     * Event: Init
     */
    onReady() {
        super.onReady();

        this._progress = 0;

        // Player
        if(!this.config.player) {
            this.config.player = {};
        }

        this.config.player.environment = this.config.environment;
        this.player = new DodgePlayer(this.config.player);

        this.appendChild(this.player);
        
        this.player.onColliderEnter = (obstacle) => {
            this.onPlayerHitObstacle(obstacle);
        };

        // Hook up events
        this.getDescendant('left').onmousedown = (e) => {
            this._isLeftPressed = true;
        };
        
        this.getDescendant('left').ontouchstart = (e) => {
            this._isLeftPressed = true;
        };
        
        this.getDescendant('right').onmousedown = (e) => {
            this._isRightPressed = true;
        };
        
        this.getDescendant('right').ontouchstart = (e) => {
            this._isRightPressed = true;
        };
        
        this.onmouseup = (e) => {
            this._isLeftPressed = false;
            this._isRightPressed = false;
        };
        
        this.ontouchend = (e) => {
            this._isLeftPressed = false;
            this._isRightPressed = false;
        };

        // Crash
        this._crash = new DodgeCrash();
        
        this.appendChild(this._crash);
    }
    
    /**
     * Event: Tutorial passed
     */
    onTutorialPassed() {
        super.onTutorialPassed();

        this.startCountdown();
    }

    /**
     * Event: Draw
     */
    onDraw(context) {
        // Track
        for(let i = 0; i < 2; i++) {
            Canvas.drawImage(
                this.config.backgroundImage,
                0,
                i * Viewport.size.x + (this._progress - Math.floor(this._progress / Viewport.size.x) * Viewport.size.x) - Viewport.size.x,
                Viewport.size.x,
                Viewport.size.x
            );
        }
        
        // Goal
        Canvas.drawImage(
            '/asset/stage/stage-challenge-dodge-goal.svg',
            0,
            Viewport.size.y - (this.config.finishPosition - (this._progress - 20)),
            Viewport.size.x
        );
    }

    /**
     * Event: Tick
     */
    onTick(delta) {
        if(this.isPaused || !this._isRunning) { return; }

        // Speed modifier
        this._speedModifier = this._speedModifier === undefined ? 1 : this._speedModifier;
            
        if(this._speedModifier < 1) {
            this._speedModifier = Math.min(1, this._speedModifier + delta * (this.config.recovery || 0.5));
        }
        
        // In progress
        if(!this.isCompleted) {
            this._progress = (this._progress || 0) + (delta * this.speed);
           
            // Finish position
            if(this._progress >= (this.config.finishPosition || 100) - 20) {
                this.onComplete();
                return;
            }

            // Obstacles
            if(!this._obstacleTimer || this._obstacleTimer < 0) {
                this.insertObstacle();
                this._obstacleTimer = Math.floor(Math.random() * (this.config.obstacleTimeoutMax || 2)) + (this.config.obstacleTimeoutMin || 1);
            
            } else {
                this._obstacleTimer = (this._obstacleTimer || 0) - delta;

            }

            for(let obstacle of this._obstacles || []) {
                obstacle.transform.position.y += delta * this.speed;

                if(obstacle.transform.position.y > Viewport.size.y + obstacle.transform.size.y * 4) {
                    obstacle.destroy();
                }
            }

            // Set player position
            if(this._isLeftPressed || Input.isKeyPressed('ArrowLeft') || Input.isKeyPressed('KeyA')) {
                if(this.player.transform.position.x > this.player.transform.size.x * 2) {
                    this.player.transform.position.x -= delta * this.speed * 0.5;
                }

            } else if(this._isRightPressed || Input.isKeyPressed('ArrowRight') || Input.isKeyPressed('KeyD')) {
                if(this.player.transform.position.x < Viewport.size.x - this.player.transform.size.x * 2) {
                    this.player.transform.position.x += delta * this.speed * 0.5;
                }
            
            }
        
        } else {
            this.player.transform.position.y -= delta * this.speed;

        }
    }

    /**
     * Event: Player hit an obstacle
     *
     * @param {Obstacle} obstacle
     */
    async onPlayerHitObstacle(obstacle) {
        this._speedModifier = 0;

        if(this._obstacles) {
            let index = this._obstacles.indexOf(obstacle);

            if(index > -1) {
                this._obstacles.splice(index, 1);
            }
        }

        this.player.onHitObstacle(obstacle);

        obstacle.destroy();

        // Crash
        this._crash.onCrash(obstacle);
    }

    /**
     * Inserts a new obstacle
     */
    insertObstacle() {
        let finishPosition = (this.config.finishPosition || 100) - this._progress;

        if(finishPosition < 100) { return; }

        let index = Math.floor(Math.random() * this.obstacles.length);
        let config = this.obstacles[index];

        config.layer = 2;

        let obstacle = new DodgeObstacle(config);
        let min = Viewport.size.x / 6;
        let width = min * 3;

        obstacle.transform.position.y = -obstacle.transform.size.y;
        obstacle.transform.position.x = min + Math.floor(Math.random() * width) - (obstacle.transform.size.x / 2);

        if(!this._obstacles) {
            this._obstacles = [];
        }
        
        this._obstacles.push(obstacle);

        this.appendChild(obstacle);
    }

    /**
     * Starts the countdown
     */
    startCountdown() {
        Audio.ui('stage/stage-challenge-countdown');

        this.setCountdown(3);

        setTimeout(() => {
            this.setCountdown(2);
        
            setTimeout(() => {
                this.setCountdown(1);

                setTimeout(() => {
                    this.onCountdownEnded();

                }, 1000);
            }, 1000);
        }, 1000);
    }

    /**
     * Gets a list of obstacles
     *
     * @return {Array} Obstacles
     */
    get obstacles() {
        let obstacles = [];

        for(let element of this.config.obstacles.itemListElement) {
            obstacles.push(element.item);
        }

        return obstacles;
    }

    /**
     * Sets the countdown timer
     *
     * @param {Number} time
     */
    setCountdown(time) {
        if(this._isDestroyed) { return; }

        this.getDescendant('countdown').innerHTML = time; 
    }

    /**
     * Event: Countdown ended
     */
    onCountdownEnded() {
        if(this._isDestroyed) { return; }
        
        this.getDescendant('countdown').innerHTML = ''; 
        this._isRunning = true;

        this.update();
    }
   
    /**
     * Gets the current speed
     *
     * @return {Number} Speed
     */
    get speed() {
        if(this.isPaused) { return 0; }

        return (this.config.speed || 50) * (this._speedModifier || 1);
    }

    /**
     * Gets the CSS
     */
    get css() { return super.css + `
        countdown {
            display: block;
            font-size: 8rem;
            font-family: SketchNote, sans-serif;
            position: absolute;
            z-index: 20;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        left, right {
            display: ${this.isCompleted ? 'none' : 'block'};
            position: absolute;
            z-index: 10;
            color: white;
            bottom: 5rem;
            cursor: pointer;
            width: 10rem;
            height: 10rem;
        }

        left {
            left: 5rem;
            ${Sprite.mapNamedCss('ui', 'button-left')}
        }

        right {
            right: 5rem;
            ${Sprite.mapNamedCss('ui', 'button-right')}
        }
    `; }

    /**
     * Gets the HTML
     */
    get html() { return super.html + `
        <countdown></countdown>
        <left></left>
        <right></right>
    `; }
}

StageChallengeDodge.register();
