'use strict';

window.global = window;

require('./src/Tool');
require('./src/Asset');
require('./src/Vector');
require('./src/Math');
require('./src/Audio');
require('./src/Clock');
require('./src/Canvas');
require('./src/Debug');
require('./src/Physics');
require('./src/AStar');
require('./src/GameElement');
require('./src/Sprite');
require('./src/Viewport');
require('./src/Http');
require('./src/Input');
require('./src/Settings');

require('./src/l10n');

require('./src/Keyframe/KeyframeSprites');
require('./src/Keyframe/KeyframeVideo');

require('./src/TopicSelector/TopicSelector');

require('./src/TopicMap/TopicMap');
require('./src/TopicMap/TopicMapCompass');
require('./src/TopicMap/TopicMapPoint');
require('./src/TopicMap/TopicMapPlayer');

require('./src/TopicWorldMap/TopicWorldMap');
require('./src/TopicWorldMap/TopicWorldMapPoint');

require('./src/TopicJournal/TopicJournal');
require('./src/TopicJournal/TopicJournalEnvelope');

require('./src/Level/Level');

require('./src/Stage/Stage');
require('./src/Stage/StageChallenge');
require('./src/Stage/StageChallengeDialog');
require('./src/Stage/StageChallengeDodge');
require('./src/Stage/StageChallengeDragDrop');
require('./src/Stage/StageChallengeJumpRun');
require('./src/Stage/StageChallengeSearch');
require('./src/Stage/StageShowcase');
require('./src/Stage/StageShowcaseAudio');
require('./src/Stage/StageShowcaseHotspots');
require('./src/Stage/StageShowcaseImages');
require('./src/Stage/StageShowcaseVideo');

require('./src/UI/UIClassSelector');
require('./src/UI/UIDisclaimer');
require('./src/UI/UILogin');
require('./src/UI/UIMusicToggle');
require('./src/UI/UIPreloader');
require('./src/UI/UISession');
require('./src/UI/UIStar');
require('./src/UI/UITutorial');
require('./src/UI/UITutorialDialog');

require('./src/User');
require('./src/Router');
