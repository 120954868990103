'use strict';

const SLOT_IDLE_TIMEOUT = 1.0;

class DragDropSlot extends GameElement {
    /**
     * Event: Drop
     *
     * @param {DragDropObject} object
     */
    onDrop(object) {
        if(!object) { return; }

        let rotation = object.config.rotation || 0;

        rotation -= Math.floor(rotation/360) * 360;
        
        let id = object.config.name;
        let isRight = rotation === 0 && this.config.accepts.indexOf(id) > -1;

        if(isRight) {
            this.setAttribute('state', 'right');

            object.style.transition = 'none';
            object.style.left = `${this.config.position.x}rem`;
            object.style.top = `${this.config.position.y}rem`;
            object.style.width = `${this.config.size.x}rem`;
            object.style.height = `${this.config.size.y}rem`;
           
            object.onCompleted(this.config.hideObjectOnSuccess);
        
            Audio.ui('stage/stage-correct');

        } else {
            this.setAttribute('state', 'wrong');
            
            Audio.ui('stage/stage-incorrect');
        
        }

        setTimeout(() => {
            this.removeAttribute('state');
        }, SLOT_IDLE_TIMEOUT * 1000);
    }
    
    /**
     * Gets the CSS
     */
    get css() { return `
        :host {
            display: block;
            position: absolute;
            left: ${this.config.position.x}rem;
            top: ${this.config.position.y}rem;
            width: ${this.config.size.x}rem;
            height: ${this.config.size.y}rem;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            background-image: url('${Asset.getUrl(this.config.imageIdle)}');

            ${Debug.isActive ? 'border: 1px solid red;' : ''}
        }
            :host([state="right"]) {
                background-image: url('${Asset.getUrl(this.config.imageRight)}');
            }

            :host([state="wrong"]) {
                background-image: url('${Asset.getUrl(this.config.imageWrong)}');
            }
    `; } 
}

DragDropSlot.register();

class DragDropObject extends GameElement {
    /**
     * Event: Init
     */
    onReady() {
        this._hasRotation = !this.isRightSideUp;

        this.getDescendant('rotate').onclick = () => {
            this.onClickRotate();
        };

        this.update();
    }

    /**
     * Event: Complete
     */
    onCompleted(hide = false) {
        if(hide) {
            this.style.display = 'none';
        }

        this.setAttribute('completed', true);
        this.getDescendant('rotate').style.display = 'none';
    }

    /**
     * Event: Rotate
     */
    onClickRotate() {
        let amount = -90;
        let image = this.getDescendant('img');

        this.config.rotation = Math.round((this.config.rotation + amount) / 90) * 90;
  
        Audio.ui('stage/stage-challenge-dragdrop-rotate');

        this.update();
    }

    /**
     * Gets if right side is up
     *
     * @return {Boolean} Is right side up
     */
    get isRightSideUp() {
        return Math.abs(this.config.rotation || 0) % 360 === 0;
    }

    /**
     * Gets the HTML
     */
    get html() { return `
        <img src="${Asset.getUrl(this.config.image)}">
        <rotate></rotate>
    `; }

    /**
     * Gets the CSS
     */
    get css() { return `
        :host {
            position: absolute;
            display: block;
            z-index: 10;
            cursor: grab;
            transition: transform 0.25s ease;
            left: ${this.config.position.x}rem;
            top: ${this.config.position.y}rem;
            width: ${this.config.size.x}rem;
            height: ${this.config.size.y}rem;
            
            ${!this.config.rotation ? `
                overflow: hidden;
            ` : ``}
            
            ${Debug.isActive ? 'border: 1px solid red;' : ''}
        }
        
        img {
            display: block;
            object-fit: contain;
            width: 100%;
            height: 100%;
            transition: transform 0.25s ease;
            pointer-events: none;
            user-select: none;
            ${this.config.rotation ? `
                transform: rotate(${this.config.rotation}deg);
            ` : `
                transform: scale(1.01);
            `}
        }

        rotate {
            position: absolute;
            display: ${this._hasRotation ? 'block' : 'none'};
            cursor: pointer;
            width: 3rem;
            height: 3rem;
            top: -1rem;
            left: -1rem;
            z-index: 10;
            ${Sprite.mapNamedCss('ui', 'rotate-left')}
        }
    `; }
}

DragDropObject.register();

global.StageChallengeDragDrop = class StageChallengeDragDrop extends StageChallenge {
    /**
     * Get preloaded assets
     */
    static getPreloadedAssets(config) {
        let preload = [
            '/asset/ui/sprites.svg',
            [ config.backgroundImage, 100 ]
        ];
        
        if(config.slots) {
            for(let element of config.slots.itemListElement) {
                if(!element.item) { continue; }

                let slot = element.item;

                preload = preload.concat([
                    [ slot.imageRight, 50 ],
                    [ slot.imageWrong, 50 ],
                    [ slot.imageIdle, 50 ]
                ]);
            }
        }

        if(config.objects) {
            for(let element of config.objects.itemListElement) {
                if(!element.item) { continue; }

                let object = element.item;

                preload.push([ object.image, 50 ]);
            }
        }

        return super.getPreloadedAssets(config).concat(preload);
    }
    
    /**
     * Event: Init
     */
    onReady() {
        super.onReady();

        for(let object of this.objects) {
            let obj = new DragDropObject(object);

            obj.onmousedown = (e) => {
                this.onObjectStartDrag(obj);
            };
            
            obj.ontouchstart = (e) => {
                this.onObjectStartDrag(obj);
            };

            this.appendChild(obj);
        }

        for(let slot of this.slots) {
            slot.hideObjectOnSuccess = this.config.hideObjectOnSuccess;

            this.appendChild(new DragDropSlot(slot)); 
        }

        window.onmouseup = (e) => {
            this.onObjectStopDrag();
        };

        window.ontouchend = (e) => {
            this.onObjectStopDrag();
        };

        window.onmousemove = (e) => {
            this.onPointerMove(e.pageX, e.pageY);
        };
        
        window.ontouchmove = (e) => {
            this.onPointerMove(e.touches[0].pageX, e.touches[0].pageY);
        }
    }

    /**
     * Event: Start dragging an object
     *
     * @param {HTMLElement} object
     */
    onObjectStartDrag(object) {
        this._draggingObject = object;
        this._draggingObjectOffset = new Vector(object.offsetLeft, object.offsetTop)
            .add(new Vector(object.offsetWidth / 2, object.offsetHeight / 2))
            .add(Viewport.screenOffset);
        this._draggingObject.setAttribute('dragging', true);

        this._draggingObject.style.zIndex = 30;
    }

    /**
     * Event: Stop dragging an object
     */
    onObjectStopDrag() {
        if(!this._draggingObject || this.isPaused) { return; }

        let dragRect = this._draggingObject.getBoundingClientRect();
        let mostOverlappedSlot = null;
        let mostOverlappedRect = null;

        for(let slot of this.getDescendants('ge-dragdropslot')) {
            let slotRect = slot.getBoundingClientRect();

            if(!Physics.isOverlap(dragRect, slotRect)) {
                continue;
            }

            if(!mostOverlappedSlot) {
                mostOverlappedRect = slotRect;
                mostOverlappedSlot = slot;
                continue;
            }

            let mostOverlap = Physics.getOverlap(dragRect, mostOverlappedRect);
            let thisOverlap = Physics.getOverlap(dragRect, slotRect);

            if(thisOverlap.width * thisOverlap.height > mostOverlap.width * mostOverlap.height) {
                mostOverlappedRect = slotRect;
                mostOverlappedSlot = slot;
            }
        }

        if(mostOverlappedSlot) {
            mostOverlappedSlot.onDrop(this._draggingObject);
            
            this.checkCompleted();

            if(this.isCompleted) {
                this.onComplete();
            }
        }

        if(!this._draggingObject.getAttribute('completed')) {
            this._draggingObject.style.transition = null;
        }

        this._draggingObject.style.transform = null;
        this._draggingObject.style.zIndex = null;
        this._draggingObject.removeAttribute('dragging');
        
        this._draggingObject = null;
        this._draggingObjectOffset = null;
    }

    /**
     * Event: Pointer is moved
     *
     * @param {Number} x
     * @param {Number} y
     */
    onPointerMove(x, y) {
        if(!this._draggingObject || this.isPaused) { return; }

        let position = new Vector(x, y).subtract(this._draggingObjectOffset);

        this._draggingObject.style.transition = 'none';
        this._draggingObject.style.transform = `translate(${position.x}px, ${position.y}px)`;
    }

    /**
     * Gets all objects
     *
     * @return {Array} Objects
     */
    get objects() {
        if(!this.config || !this.config.objects) {
            return [];
        }

        let objects = [];

        for(let element of this.config.objects.itemListElement) {
            if(!element.item) { continue; }

            objects.push(element.item);
        }

        return objects;
    }
    
    /**
     * Gets all slots
     *
     * @return {Array} Slots
     */
    get slots() {
        if(!this.config || !this.config.slots) {
            return [];
        }
    
        let slots = [];

        for(let element of this.config.slots.itemListElement) {
            if(!element.item) { continue; }

            slots.push(element.item);
        }

        return slots;
    }
    
    /**
     * Checks if the challenge has been completed
     */
    checkCompleted() {
        let objects = this.getDescendants('ge-dragdropobject:not([completed])');

        for(let incompleted of objects) {
            let id = incompleted.config.name;

            for(let slot of this.slots) {
                if(slot.accepts.indexOf(id) < 0) { continue; }

                this.isCompleted = false;
                return;
            }
        }

        this.isCompleted = true;
    }

    /**
     * Gets the CSS
     */
    get css() { return super.css + `
        :host {
            ${this.config.backgroundImage ? `background-image: url('${Asset.getUrl(this.config.backgroundImage)}');` : ``}
            background-size: 100% auto;
            background-position: center;
            background-repeat: no-repeat;
        }
    `; }
}

StageChallengeDragDrop.register();
