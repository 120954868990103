'use strict';

global.TopicWorldMapPoint = class TopicWorldMapPoint extends GameElement {
    /**
     * Event: Init
     */
    onReady() {
        super.onReady();

        this.transform.position.x = this.position.x;
        this.transform.position.y = this.position.y;
        this.transform.size.x = 1.5;
        this.transform.size.y = 1.5;

        this.update();
    }
    
    /**
     * Gets the topic of this level
     *
     * @return {String} Topic
     */
    get topicId() {
        return Router.path[1];
    }
    
    /**
     * Gets the map of this level
     *
     * @return {String} Map
     */
    get mapId() {
        return Router.path[3];
    }

    /**
     * Gets the id of this level
     *
     * @return {String} Id
     */
    get id() {
        if(!this.config || !this.config.identifier) {
            return '';
        }

        return this.config.identifier;
    }

    /**
     * Gets the name of this level
     *
     * @return {String} Name
     */
    get name() {
        if(!this.config || !this.config.name) {
            return '[name]';
        }

        return this.config.name;
    }
   
    /**
     * Gets the image URL
     *
     * @return {String} URL
     */
    get image() {
        if(!this.config || !this.config.previewImage || !this.config.previewImage.source) {
            return '';
        }

        return this.config.previewImage.source.contentUrl;
    }

    /**
     * Gets the description of this level
     *
     * @return {String} Description
     */
    get description() {
        return marked(this.config.worldMapDescription || '');
    }

    /**
     * Gets the position on the map
     *
     * @return {Vector} Position
     */
    get position() {
        let position = new Vector(0, 0);

        if(this.config && this.config.worldPosition) {
            position.x = this.config.worldPosition.x || 0;
            position.y = this.config.worldPosition.y || 0;
        }

        return position;
    }

    /**
     * Gets the CSS
     */
    get css() { return `
        :host {
            display: block;
            position: absolute;
            left: ${this.transform.position.x}rem;
            top: ${this.transform.position.y}rem;
            cursor: pointer;
            background-color: var(--color-primary);
            width: ${this.transform.size.x}rem;
            height: ${this.transform.size.y}rem;
            border-radius: 50%;
            outline: none !important;
            transition: transform 0.25s ease;
            box-shadow: 0.1rem 0.1rem 0 0 black;
            border: 0.2rem solid transparent;
        }
            :host([hidden]) {
                display: none;
            }

            :host([open]) {
                border-color: var(--color-primary);
                background-color: white;
            }
    `; }

    /**
     * Gets the HTML
     */
    get html() { return `
    `; }
}

TopicWorldMapPoint.register();
