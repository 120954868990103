'use strict';

global.TopicMapPoint = class TopicMapPoint extends GameElement {
    /**
     * Event: Init
     */
    onReady() {
        super.onReady();

        Physics.addCollider(this);

        this.transform.position.x = this.position.x + this.offset.x;
        this.transform.position.y = this.position.y + this.offset.y;
        this.transform.size.x = 7;
        this.transform.size.y = 7;

        let button = this.getDescendant('button');

        if(button) {
            button.onclick = (e) => {
                e.stopPropagation();

                this.onClickStart();
            };
        }
    }
    
    /**
     * Gets the layer of this element
     *
     * @return {Number} Layer
     */
    get layer() {
        return 1;
    }
    
    /**
     * Gets the topic of this level
     *
     * @return {String} Topic
     */
    get topicId() {
        return Router.path[1];
    }
    
    /**
     * Gets the map of this level
     *
     * @return {String} Map
     */
    get mapId() {
        return Router.path[3];
    }

    /**
     * Gets the id of this level
     *
     * @return {String} Id
     */
    get id() {
        if(!this.config || !this.config.identifier) {
            return '';
        }

        return this.config.identifier;
    }

    /**
     * Gets the name of this level
     *
     * @return {String} Name
     */
    get name() {
        if(!this.config || !this.config.name) {
            return '[name]';
        }

        return this.config.name;
    }
   
    /**
     * Gets the image URL
     *
     * @return {String} URL
     */
    get image() {
        if(!this.config || !this.config.previewImage || !this.config.previewImage.source) {
            return '';
        }

        return this.config.previewImage.source.contentUrl;
    }

    /**
     * Gets the description of this level
     *
     * @return {String} Description
     */
    get description() {
        return marked(this.config.description || '[description]');
    }

    /**
     * Gets whether this level has been completed
     *
     * @return {Boolean} Is completed
     */
    get isCompleted() {
        return User.current.isCompleted(this.id);
    }
    
    /**
     * Gets whether this is the final level on the map
     *
     * @return {Boolean} Is final
     */
    get isFinal() {
        return this.config && this.config.isFinal === true;
    }
    
    /**
     * Gets the position on the map
     *
     * @return {Vector} Position
     */
    get position() {
        let position = new Vector(0, 0);

        if(this.config && this.config.position) {
            position.x = this.config.position.x || 0;
            position.y = this.config.position.y || 0;
        }

        return position;
    }

    /**
     * Gets the offset of the level enter area
     *
     * @return {Vector} Offset
     */
    get offset() {
        let offset = new Vector(0, 0);

        if(this.config && this.config.offset) {
            offset.x = this.config.offset.x || 0;
            offset.y = this.config.offset.y || 0;
        }

        return offset;
    }

    /**
     * Event: Collider enter
     *
     * @param {HTMLElement} collider
     */
    onColliderEnter(collider) {
        if(collider.parentElement instanceof TopicMapPlayer === false) { return; }
        
        this._player = collider.parentElement;
        this.isPreviewVisible = true;

        this.update();
    }
    
    /**
     * Event: Collider exit
     *
     * @param {HTMLElement} collider
     */
    onColliderExit(collider) {
        if(collider.parentElement instanceof TopicMapPlayer === false) { return; }

        this.isPreviewVisible = false;

        this.update();
    }

    /**
     * Event: Click start
     */
    onClickStart() {
        Audio.ui('ui/button-click');

        if(this._player) {
            User.current.setLastMapPosition(this.mapId, this._player.transform.position);
        }

        Router.go(`/topic/${this.topicId}/map/${this.mapId}/level/${this.id}`); 
    }

    /**
     * Event: Draw
     */
    onDraw(context) {
        let transform = this.globalTransform;

        // Draw preview image
        Canvas.drawImage(
            this.image,
            transform.position.x - this.offset.x,
            transform.position.y - this.offset.y - Math.abs(this.config.previewImage.size.x - this.config.previewImage.size.y) / 2,
            this.config.previewImage.size.x
        );
        
        // Draw marker
        Canvas.drawNamedSprite(
            'ui',
            `point-marker-${this.isCompleted ? 'completed' : 'new'}`,
            transform.position.x,
            transform.position.y,
            transform.size.x,
            transform.size.y
        );

        if(!this.config.canEnter) {
            Canvas.drawNamedSprite(
                'ui',
                'padlock',
                transform.position.x,
                transform.position.y - 2,
                transform.size.x,
                transform.size.y
            );
        }
    }

    /**
     * Gets the blocking volume in world units
     *
     * @return {DOMRect} Blocking volume
     */
    get blockingVolume() {
        let rect = {
            x: 0,
            y: 0,
            width: 0,
            height: 0
        };

        if(!this.config.deadzone || !this.config.deadzone.size || !this.config.deadzone.position) {
            return rect;
        }

        rect.x = this.position.x + this.config.deadzone.position.x || 0;
        rect.y = this.position.y + this.config.deadzone.position.y || 0;
        rect.width = this.config.deadzone.size.x || 0;
        rect.height = this.config.deadzone.size.y || 0;

        return rect;
    }

    /**
     * Gets the CSS
     */
    get css() { return `
        preview {
            position: absolute;
            bottom: 6rem;
            left: 50%;
            min-width: 24rem;
            max-width: 34rem;
            transform: translateX(-50%);
            background-color: white;
            padding: 3rem 3rem 1rem 3rem;
            display: ${this.isPreviewVisible ? 'block' : 'none'};
            z-index: 100;

            ${Sprite.mixin('ui', 'frame-generic')}
        }

        name {
            font-family: SketchNote, sans-serif;
            font-size: 3rem;
            display: block;
            margin-bottom: 1rem
        }
        
        button {
            margin: 0 auto;

            ${Sprite.mixin('ui', 'button')}
        }
            button:active {
                transform: scale(0.9);
            }

        remaining {
            display: block;
            font-family: SketchNote, sans-serif;
            font-size: 2rem;
            margin: 2rem auto 2rem auto;
            width: 100%;
            text-align: center;
        }

        description {
            font-size: 1.4rem;
            display: block;
            line-height: 1.5;
        }
    `; }

    /**
     * Gets the HTML
     */
    get html() { return `
        <previewimage></previewimage>
        <preview>
            <name>${this.name}</name>
            <description>${this.description}</description>

            ${this.config.canEnter ? `
                <button>${L10N.translate('level-start')}</button>
            ` : `
                <remaining>${this.config.completedLevels}/${this.config.totalLevels} ${L10N.translate('levels')}</remaining>
            `}
        </preview>
    `; }
}

TopicMapPoint.register();
