'use strict';

global.StageShowcaseVideo = class StageShowcaseVideo extends StageShowcase {
    /**
     * Get preloaded assets
     */
    static getPreloadedAssets(config) {
        let preload = [
            '/asset/ui/sprites.svg'
        ];

        return super.getPreloadedAssets(config).concat(preload);
    }
    
    /**
     * Event: Init
     */
    onReady() {
        super.onReady();

        this._isPlaybackPaused = true;
        this.isPaused = true;

        // Hook up events
        this.videoElement = this.getDescendant('video');
        this.rangeElement = this.getDescendant('input[type="range"]');
        
        this.videoElement.onended = (e) => {
            this.update();
        };

        this.rangeElement.onchange = (e) => {
            this.videoElement.currentTime = this.rangeElement.value;
            this.videoElement.play();
            this._isScrobbling = false;
        };
        
        this.rangeElement.oninput = (e) => {
            this._isScrobbling = true;
        };

        this.getDescendant('playpause').onclick = () => {
            this.onClickTimeControl(true);
        };

        this.videoElement.ondurationchange = () => {
            this.rangeElement.max = this.videoElement.duration;
            this.update();
        };
    }
    
    /**
     * Gets whether the pause menu should be shown
     *
     * @return {Boolean} Show pause menu
     */
    get showPauseMenu() {
        return this.isPaused && !this._isPlaybackPaused;
    }
    
    /**
     * Gets whether the next button should be shown
     *
     * @return {Boolean} Show next button
     */
    get showNextButton() {
        return this._showNextButton === true;
    }

    /**
     * Event: Click time control
     */
    onClickTimeControl(isPlayback) {
        if(isPlayback) {
            if(this.videoElement.paused) {
                this.videoElement.play();
            } else {
                this.videoElement.pause();
            }
        
            this._isPlaybackPaused = this.videoElement.paused;
            this.isPaused = this.videoElement.paused;

        } else {
            this.isPaused = true;
            this.videoElement.pause();
            this._isPlaybackPaused = false;
        
        }

        this.update();

        if(!this.isPaused) {
            setTimeout(() => {
                this._showNextButton = true;

                this.update();
            }, 3000);
        }
    }
    
    /**
     * Event: Click resume play
     */
    onClickResume() {
        this.videoElement.play();
        this.isPaused = false;
        this._isPlaybackPaused = false;

        this.update();
    }
    
    /**
     * Event: Tick
     */
    onTick(delta) {
        if(!this._isScrobbling) {
            this.rangeElement.value = this.videoElement.currentTime;
        }
    }

    /**
     * Overrides whether the play button should be shown
     *
     * @return {Boolean} Show play button
     */
    get showPlayButton() {
        return !this.videoElement || this.videoElement.paused;
    }

    /**
     * Gets the HTML
     */
    get html() { return super.html + `
        <content>
            <video src="${this.config.video ? this.config.video.contentUrl : ''}"></video>
            <playback>
                <playpause></playpause> 
                <input type="range" value="0" step="0.1" min="0" max="0">
            </playback>
        </content>
    `; }

    /**
     * Gets the CSS
     */
    get css() { return super.css + `
        :host {
            background-color: white;
        }

        content {
            position: absolute;
            display: block;
            width: calc(100% - 20rem);
            height: calc(100% - 20rem);
            left: 10rem;
            top: 10rem;

            ${Sprite.mixin('ui', 'frame-generic')}
        }

        video {
            display: block;
            position: absolute;
            top: 1rem;
            left: 1rem;
            width: calc(100% - 2rem);
            height: calc(100% - 2rem);
        }

        playback {
            display: flex;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            justify-content: center;
            align-items: center;
            padding: 2rem;
            box-sizing: border-box;
        }
            playpause {
                display: block;
                cursor: pointer;
                margin-right: 2rem;
                width: 6rem;
                height: 6rem;
                flex-shrink: 0;
                ${Sprite.mapNamedCss('ui', this.isPaused ? 'play' : 'pause')}
            }

            input[type="range"] {
                cursor: pointer;
                display: block;
                flex-grow: 1;
            }
    `; }
}

StageShowcaseVideo.register();
