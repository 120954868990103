'use strict';

global.StageShowcaseAudio = class StageShowcaseAudio extends StageShowcase {
    /**
     * Get preloaded assets
     */
    static getPreloadedAssets(config) {
        let preload = [
            '/asset/ui/sprites.svg',
            [ config.backgroundImage, 100 ]
        ];

        return super.getPreloadedAssets(config).concat(preload);
    }
    
    /**
     * Event: Init
     */
    onReady() {
        super.onReady();

        this._isPlaybackPaused = true;
        this.isPaused = true;

        // Hook up events
        this.audioElement = this.getDescendant('audio');
        this.rangeElement = this.getDescendant('input[type="range"]');

        this.audioElement.onended = (e) => {
            this.update();
        };

        this.rangeElement.onchange = (e) => {
            this.audioElement.currentTime = this.rangeElement.value;
            this.audioElement.play();
            this._isScrobbling = false;
        };

        this.rangeElement.oninput = (e) => {
            this._isScrobbling = true;
        };
        
        this.getDescendant('playpause').onclick = () => {
            this.onClickTimeControl(true);
        };

        this.audioElement.ondurationchange = () => {
            this.rangeElement.max = this.audioElement.duration;
            this.update();
        };
    }
    
    /**
     * Gets whether the pause menu should be shown
     *
     * @return {Boolean} Show pause menu
     */
    get showPauseMenu() {
        return this.isPaused && !this._isPlaybackPaused;
    }
    
    /**
     * Gets whether the next button should be shown
     *
     * @return {Boolean} Show next button
     */
    get showNextButton() {
        return this._showNextButton === true;
    }
    
    /**
     * Event: Click time control
     */
    onClickTimeControl(isPlayback) {
        if(isPlayback) {
            if(this.audioElement.paused) {
                this.audioElement.play();
            } else {
                this.audioElement.pause();
            }
        
            this._isPlaybackPaused = this.audioElement.paused;
            this.isPaused = this.audioElement.paused;

        } else {
            this.isPaused = true;
            this.audioElement.pause();
            this._isPlaybackPaused = false;
        
        }

        this.update();

        if(!this.isPaused) {
            setTimeout(() => {
                this._showNextButton = true;

                this.update();
            }, 3000);
        }
    }
    
    /**
     * Event: Click resume play
     */
    onClickResume() {
        this.audioElement.play();
        this.isPaused = false;
        this._isPlaybackPaused = false;

        this.update();
    }
    
    /**
     * Event: Tick
     */
    onTick(delta) {
        if(!this._isScrobbling) {
            this.rangeElement.value = this.audioElement.currentTime;
        }
    }

    /**
     * Overrides whether the play button should be shown
     *
     * @return {Boolean} Show play button
     */
    get showPlayButton() {
        return !this.audioElement || this.audioElement.paused;
    }

    /**
     * Gets the HTML
     */
    get html() { return super.html + `
        <audio src="${this.config.audio.contentUrl}"></audio>
        <playback>
            <playpause></playpause> 
            <input type="range" value="0" step="0.1" min="0" max="0">
        </playback>
    `; }

    /**
     * Gets the CSS
     */
    get css() { return super.css + `
        :host {
            ${this.config.backgroundImage ? `background-image: url('${Asset.getUrl(this.config.backgroundImage)}');` : ``}
            background-position: center;
            background-size: cover;
            background-color: white;
        }

        audio {
            display: none;
        }

        playback {
            display: flex;
            width: 60rem;
            position: absolute;
            bottom: 10rem;
            left: 20rem;
            justify-content: center;
            align-items: center;
            padding: 2rem;
            box-sizing: border-box;
        }
            playpause {
                display: block;
                cursor: pointer;
                margin-right: 2rem;
                width: 6rem;
                height: 6rem;
                flex-shrink: 0;
                ${Sprite.mapNamedCss('ui', this.isPaused ? 'play' : 'pause')}
            }

            input[type="range"] {
                cursor: pointer;
                display: block;
                flex-grow: 1;
            }
    `; }
}

StageShowcaseAudio.register();
