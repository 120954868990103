'use strict';

global.StageShowcase = class StageShowcase extends Stage {
    /**
     * Gets whether the next button should be shown
     *
     * @return {Boolean} Show next button
     */
    get showNextButton() {
        return true;
    }
}
