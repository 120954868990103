'use strict';

global.Physics = class Physics {
    /**
     * Adds a new collider
     *
     * @param {GameElement} collider
     */
    static addCollider(collider) {
        if(collider instanceof HTMLElement === false) {
            return Debug.error(new Error(collider, 'Not a HTMLElement'));
        }
     
        this._colliders.push(collider);
    }

    /**
     * Check if 2 rectangles are overlapping
     *
     * @param {DOMRect} rect1
     * @param {DOMRect} rect2
     *
     * @return {Boolean} Overlap
     */
    static isOverlap(rect1, rect2) {
        if(!rect1 || !rect2) { return false; }

        return (
            rect1.x < rect2.x + rect2.width &&
            rect1.x + rect1.width > rect2.x &&
            rect1.y < rect2.y + rect2.height &&
            rect1.y + rect1.height > rect2.y
        );
    }

    /**
     * Gets overlap size of 2 rectangles
     *
     * @param {DOMRect} rect1
     * @param {DOMRect} rect2
     *
     * @return {DOMRect} Intersection
     */
    static getOverlap(rect1, rect2) {
        if(!rect1 || !rect2) { return null; }
            
        let x = Math.max(rect1.x, rect2.x);
        let y = Math.max(rect1.y, rect2.y);
        let w = x - Math.min(rect1.x + rect1.width, rect2.x + rect2.width);
        let h = y - Math.min(rect1.y + rect1.height, rect2.y + rect2.height);

        return new DOMRect(x, y, w, h);
    }

    /**
     * Init
     */
    static init() {
        this._colliders = [];

        Clock.add(this);

        if(Debug.isActive) {
            Canvas.add(this, 10);
        }
    }

    /**
     * Event: Draw
     */
    static onDrawDeferred(context) {
        if(!Debug.isActive) { return; }
        
        for(let collider of this._colliders) {
            let bounds = collider.bounds;

            Canvas.drawBounds(
                bounds.x,
                bounds.y,
                bounds.width,
                bounds.height
            );
        }
    }

    /**
     * Event: Tick
     */
    static onTick(delta) {
        for(let i = this._colliders.length; i >= 0; i--) {
            let a = this._colliders[i];

            if(!a || a._isDestroyed) {
                this._colliders.splice(i, 1);
                continue;
            }

            for(let b of this._colliders) {
                if(a === b) { continue; }

                let isOverlap = this.isOverlap(a.bounds, b.bounds);
                let overlapIndex = a.overlaps ? a.overlaps.indexOf(b) : -1;

                if(isOverlap && overlapIndex < 0) {
                    if(typeof a.onColliderEnter === 'function') {
                        a.onColliderEnter(b);
                    }

                    if(!a.overlaps) {
                        a.overlaps = [];
                    }

                    a.overlaps.push(b);
                }

                if(!isOverlap && overlapIndex >= 0) {
                    if(typeof a.onColliderExit === 'function') {
                        a.onColliderExit(b);
                    }
                    
                    if(a.overlaps) {
                        a.overlaps.splice(overlapIndex, 1);
                    }
                }
            }
        }
    }
}

Physics.init();
