'use strict';

global.StageShowcaseHotspots = class StageShowcaseHotspots extends StageShowcase {
    /**
     * Get preloaded assets
     */
    static getPreloadedAssets(config) {
        let preload = [
            '/asset/ui/sprites.svg',
            [ config.backgroundImage, 100 ]
        ];

        return super.getPreloadedAssets(config).concat(preload);
    }
    
    /**
     * Gets a list of hotspots
     *
     * @return {Array} Hotspots
     */
    get hotspots() {
        let hotspots = [];

        for(let element of this.config.hotspots.itemListElement) {
            hotspots.push(element.item);
        }

        return hotspots;
    }

    
    /**
     * Gets the CSS
     */
    get css() { return super.css + `
        :host {
            ${this.config.backgroundImage ? `background-image: url('${Asset.getUrl(this.config.backgroundImage)}');` : ``}
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: white;
        }

        hotspot {
            position: absolute;
            display: block;
        }

        hotspot toggle {
            display: block;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 2rem;
            height: 2rem;
            background-color: var(--color-primary);
            border-radius: 50%;
            top: 0;
            cursor: pointer;
            box-shadow: 0.2rem 0.2rem 0 0 black;
        }

        hotspot content {
            width: 20rem;
            display: none;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: calc(100% + 2rem);
            background-color: white;
            color: black;
            padding: 2rem;
            font-size: 1.2rem;
            line-height: 1.5;
            z-index: 20;

            ${Sprite.mixin('ui', 'frame-generic')}
        }

        hotspot[flipped] content {
            bottom: auto;
            top: calc(100% + 4rem);
        }

        hotspot toggle:hover + content {
            display: block;
        }
    `; }

    /**
     * Gets the HTML
     */
    get html() { return super.html + `
        ${this.hotspots.map(hotspot => `
            <hotspot ${hotspot.position.y < 30 ? 'flipped' : ''} style="left:${hotspot.position.x}rem; top:${hotspot.position.y}rem;">
                <toggle></toggle>
                <content>${hotspot.text}</content>
            </hotspot>
        `).join('')}
    `; }
}

StageShowcaseHotspots.register();
