'use struct';

global.Stage = class Stage extends GameElement {
    /**
     * Constructor
     */
    constructor(data) {
        super(data);

        Stage.current = this;
    }
    
    /**
     * Get preloaded assets
     */
    static getPreloadedAssets(config) {
        if(config.tutorial && config.tutorial.image) {
            return [
                '/asset/ui/brush-1.png',
                [ config.tutorial.image, 50 ]
            ];
        }

        return [];
    }

    /**
     * Event: Init
     */
    onReady() {
        Audio.music(this.music);

        // Bind events
        this.getDescendant('timecontrol').onclick = (e) => {
            this.onClickTimeControl();
        };

        this.getDescendant('pausemenu action[map]').onclick = (e) => {
            this.onClickReturnToMap();
        };
        
        this.getDescendant('pausemenu action[resume]').onclick = (e) => {
            this.onClickResume();
        };

        this.getDescendant('next').onclick = (e) => {
            this.onClickNextStage();
        };

        this.onclick = () => {
            Audio.music(this.music);
        };
       
        // Bind key down & up
        Input.watch(this);

        // Check for tutorial
        if(this.config.tutorial && this.config.tutorial.text) {
            let tutorial = new UITutorial(this.config.tutorial);

            tutorial.addEventListener('destroy', () => {
                this.onTutorialPassed();
            });

            this.appendChild(tutorial);
        
        } else {
            this.onTutorialPassed();

        }
    }
    
    /**
     * Event: Destroy
     */
    onDestroy() {
        if(Stage.current === this) {
            Stage.current = null;
        }
        
        Audio.sfx(null);
    }
    
    /**
     * Event: Key up
     *
     * @param {InputEvent} e
     */
    onKeyUp(e) {}
    
    /**
     * Event: Key down
     *
     * @param {InputEvent} e
     */
    onKeyDown(e) {}

    /**
     * Event: Tutorial passed
     */
    onTutorialPassed() {
        this.isTutorialPassed = true;
    }

    /**
     * Event: Click next
     */
    onClickNextStage() {
        Audio.ui('ui/button-click');
        Audio.sfx(null);
    
        Level.current.startNextStage();
    }

    /**
     * Event: Click return to map
     */
    onClickReturnToMap() {
        Audio.ui('ui/button-click');
            
        Clock.start();
        
        Router.go(`/topic/${this.topicId}/map/${this.mapId}`);
    }

    /**
     * Event: Click time control
     */
    onClickTimeControl() {
        Audio.ui('ui/button-click');

        this.isPaused = !this.isPaused;
        
        this.onPauseChanged();

        this.update();
        
        if(this.isPaused) {
            Clock.stop();
            Audio.sfx(null);
        } else {
            Clock.start();
        }
    }
   
    /**
     * Event: Click resume play
     */
    onClickResume() {
        Audio.ui('ui/button-click');
        
        this.isPaused = false;

        this.onPauseChanged();

        this.update();

        Clock.start();
    }

    /**
     * Event: Pause changed
     */
    onPauseChanged() { }

    /**
     * Gets whether the play button should be shown
     *
     * @return {Boolean} Show play button
     */
    get showPlayButton() {
        return this.isPaused;
    }

    /**
     * Gets whether the pause menu should be shown
     *
     * @return {Boolean} Show pause menu
     */
    get showPauseMenu() {
        return this.isPaused;
    }
    
    /**
     * Gets whether the next button should be shown
     *
     * @return {Boolean} Show next button
     */
    get showNextButton() {
        return this.isCompleted;
    }
    
    /**
     * Gets the topic of this level
     *
     * @return {String} Topic
     */
    get topicId() {
        return Router.path[1];
    }
    
    /**
     * Gets the map of this level
     *
     * @return {String} Map
     */
    get mapId() {
        return Router.path[3];
    }
    
    /**
     * Gets the id of this level
     *
     * @return {String} Id
     */
    get levelId() {
        return Router.path[5];
    }
   
    /**
     * Gets the name of the music theme
     *
     * @return {String} Music
     */
    get music() {
        if(Level.current.isFinal) {
            return 'theme-dramatic';
        }
        
        return Level.current.config.music;
    }

    /**
     * Transition in
     */
    async transitionIn() {
        await Clock.waitForSeconds(0.5);
    }

    /**
     * Transition out
     */
    async transitionOut() {
        await Clock.waitForSeconds(0.5);
    }
    
    /**
     * Gets the HTML
     */
    get html() { return `
        <transition></transition>
        <ge-uimusictoggle></ge-uimusictoggle>
        <header>
            <timecontrol></timecontrol>
        </header>
        ${this.config.instructions ? `
            <instructions>
                <heading>${L10N.translate('stage-instructions-heading')}</heading>
                <text>${marked(this.config.instructions)}</text>
            </instructions>
        ` : ``}
        <next>${L10N.translate('stage-proceed')}</next>
        <pausemenu>
            <box>
                <heading>${L10N.translate('pause-heading')}</heading>
                <actions>
                    <action map>${L10N.translate('pause-map')}</action>
                    <action resume>${L10N.translate('pause-resume')}</action>
                </actions>
            </box>
        </pausemenu>
    `; }
    
    /**
     * Gets the CSS
     */
    get css() { return `
        :host {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }
        
        /* Transition */
        transition {
            position: absolute;
            z-index: 900;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: white;
            opacity: 0;
            transition: opacity 0.5s ease;
            pointer-events: none;
        }
            :host([transitioning]) transition {
                opacity: 1;
                pointer-events: all;
            }
        
        /* Header */
        header {
            display: ${this.isCompleted ? 'none' : 'flex'};
            position: absolute;
            top: 2rem;
            left: 2rem;
            width: calc(100% - 4rem);
            z-index: 50;
        }
            header timecontrol {
                display: block;
                width: 4rem;
                height: 4rem;
                cursor: pointer;
                ${Sprite.mapNamedCss('ui', 'pause-transparent')} 
            }

            header timecontrol[hidden] {
                display: none;
            }
       
        /* Instructions */
        instructions {
            position: absolute;
            left: 0;
            top: 0;
            font-size: 1.5rem;
            line-height: 1.5;
            padding: 2rem 12rem 5rem 12rem;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            width: 100%;
            background-image: url('/asset/ui/brush-2.png');
            background-size: 100% 100%;
            background-position: center;
            background-repeat: no-repeat;
            z-index: 40;
        }
            instructions heading {
                flex-shrink: 0;
                padding-right: 2rem;
                font-size: 2rem;
                font-family: SketchNote, sans-serif;
            }

            instructions text {
                flex-grow: 1;
            }

        /* Pause menu */
        pausemenu {
            display: ${this.showPauseMenu ? 'flex' : 'none'};
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 200;
        }
            pausemenu box {
                background-color: white;
                padding: 4rem;
                border: 2px solid black;
            }
                pausemenu box heading {
                    font-size: 3rem;
                    display: block;
                    color: black;
                    margin-bottom: 2rem;
                    font-family: SketchNote, sans-serif;
                    text-align: center;
                }
                
                pausemenu box actions {
                    display: flex;
                    width: 100%;
                }

                pausemenu box action {
                    ${Sprite.mixin('ui', 'button')}
                }
                    pausemenu box action:active {
                        transform: scale(0.9);
                    }

        /* Next button */
        next {
            position: absolute;
            display: block;
            pointer-events: ${this.showNextButton ? 'all' : 'none'};
            opacity: ${this.showNextButton ? '1' : '0'};
            transform: ${this.showNextButton ? 'none' : 'translateX(-50%)'};
            transition: opacity 0.25s ease, transform 0.25s ease;
            right: 2rem;
            bottom: 2rem;
            font-size: 1.5rem;
            font-weight: bold;
            font-style: italic;
            color: white;
            cursor: pointer;
            z-index: 180;
            line-height: 10rem;
            text-align: center;
            width: 20rem;
            height: 10rem;
            user-select: none;
        
            ${Sprite.mapNamedCss('ui', 'button-arrow-right')}
        }
            next:active {
                transform: scale(0.9);
            }
    `; }
}
