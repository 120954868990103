'use strict';

// Settings
const ANSWER_INIT_DELAY = 2.0;
const ANSWER_SEQUENCE_DELAY = 0.25;

global.StageChallengeDialog = class StageChallengeDialog extends StageChallenge {
    /**
     * Event: Init
     */
    onReady() {
        // Get steps container
        this.stepsElement = this.getDescendant('steps');

        // Set up player
        if(this.config.includePlayer) {
            this.player = new KeyframeVideo({
                isVertical: true,
                source: `/asset/player/player-dialog-${User.current.getClass()}.mp4`
            });
            
            this.player.add('idle', 0, 1.9);
            this.player.add('talk', 2, 3.9);
            
            this.player.play('idle');

            this.getDescendant('player').appendChild(this.player);
        
            this.player.adoptTransformFromBounds();
        }
            
        // Set up NPC
        this.npc = new KeyframeVideo({
            isVertical: true,
            source: this.config.npc ? this.config.npc.contentUrl : ''
        });

        this.npc.add('idle', 0, 1.9);
        this.npc.add('talk', 2, 3.9);
            
        this.npc.play('idle');

        this.getDescendant('npc').appendChild(this.npc);

        this.npc.adoptTransformFromBounds();

        // Hook up events
        this.getDescendant('proceed').onclick = (e) => {
            this.onClickNext();
        };
        
        this.getDescendant('answer action').onclick = (e) => {
            this.onClickAnswer();
        };
        
        super.onReady();
    }

    /**
     * Update bubbles
     */
    updateBubbles() {
        for(let i = 0; i < this.stepsElement.children.length; i++) {
            let step = this.stepsElement.children[i];
            let path = step.querySelector('svg path');
            let offset = 10 + ((window.innerHeight - step.getBoundingClientRect().bottom) / window.innerHeight) * 100;

            if(!path) {
                let arrow = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
                arrow.setAttribute('viewBox', '0 0 100 100');

                path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                arrow.appendChild(path);

                step.appendChild(arrow);
            }

            if(step.tagName === 'RESPONSE' || step.getAttribute('speaker') === 'player') {
                path.setAttribute('d', `M100,40 L60,${offset} L100,60`);
            } else {
                path.setAttribute('d', `M0,40 L40,${offset} L0,60`);
            }
        }
    }

    /**
     * Event: Tutorial passed
     */
    onTutorialPassed() {
        super.onTutorialPassed(); 
        
        this.nextStep();
    }

    /**
     * Event: Click next
     */
    onClickNext() {
        Audio.ui('ui/button-click');

        this.nextStep();
    }

    /**
     * Event: Click a response
     *
     * @param {Object} config
     */
    onClickResponse(config) {
        if(this.isCompleted) { return; }
        
        Audio.ui('ui/button-click');

        if(!this._pickedResponses) {
            this._pickedResponses = [];
        }

        let isNew = true;

        for(let i = 0; i < this._pickedResponses.length; i++) {
            if(this._pickedResponses[i].text !== config.text) { continue; }

            this._pickedResponses.splice(i, 1);
            isNew = false;
            break;
        }

        if(isNew) {
            this._pickedResponses.push(config);
        }

        this.highlightPickedResponses();

        this.update();
    }

    /**
     * Highlights all picked responses
     */
    highlightPickedResponses(setState = false) {
        if(!this._pickedResponses) { return; }
        
        for(let response of this.getDescendants('response')) {
            let isPicked = false;
            let isCorrect = false;

            for(let config of this._pickedResponses) {
                if(config.text !== response.getAttribute('raw')) { continue; }

                isPicked = true;
                isCorrect = config.isCorrect;
            }

            if(isPicked) {
                if(setState) {
                    if(isCorrect) {
                        response.setAttribute('state', 'correct');
                    } else {
                        response.setAttribute('state', 'incorrect');
                    }
                }

                response.setAttribute('active', true);
            
            } else {
                response.removeAttribute('active');
            
            }
        }
    }

    /**
     * Gets all responses
     *
     * @return {Array} Responses
     */
    get responses() {
        if(!this.config || !this.config.query || !this.config.query.responses) { return []; }

        let responses = [];

        for(let element of this.config.query.responses.itemListElement) {
            if(!element.item) { continue; }

            responses.push(element.item);
        }

        return responses;
    }
    
    /**
     * Gets amount of correct responses
     *
     * @return {Number} Correct responses
     */
    get correctResponses() {
        let amount = 0;

        for(let response of this.responses) {
            if(!response.isCorrect) { continue; }

            amount++;
        }

        return amount;
    }

    /**
     * Event: Answer question
     */
    async onClickAnswer() {
        if(!this._pickedResponses) { return; }

        Audio.ui('ui/button-click');

        let correctAmount = 0;
        let incorrectAmount = 0;

        this.highlightPickedResponses(true);

        for(let i = this._pickedResponses.length - 1; i >= 0; i--) {
            let response = this._pickedResponses[i];

            if(!response.isCorrect) {
                this._pickedResponses.splice(i, 1);
                incorrectAmount++;
                continue;
            }

            correctAmount++;
        }

        if(correctAmount >= this.correctResponses) {
            this.onComplete();

        }
        
        if(this.player) {
            this.player.play('talk');
            this.npc.play('idle');

            await Clock.waitForSeconds(2);

            this.player.play('idle');
        }
    }

    /**
     * Gets the steps
     *
     * @return {Array} Steps
     */
    get steps() {
        if(!this.config || !this.config.steps) { return []; }

        let steps = [];

        for(let element of this.config.steps.itemListElement) {
            if(!element.item) { continue; }

            steps.push(element.item);
        }

        return steps;
    }

    /**
     * Advance to the next step in the conversation
     */
    async nextStep() {
        if(this.isCompleted || this._isAtQuery) { return; }
 
        // Increment step
        if(this._currentStep === undefined) {
            this._currentStep = 0;
        } else {
            this._currentStep++;
        }

        let step = this.steps[this._currentStep];

        // We're at the last step
        if(this._currentStep === this.steps.length) {
            // There's a query waiting, hop to it
            if(this.config.query && this.config.query.text) {
                step = this.config.query;
                step.speaker = 'npc';
                this._isAtQuery = true;

            // There's no query, complete the challenge
            } else {
                this.onComplete();
                return;
            }
        }

        if(!step) {
            return Debug.error(this, new Error(`Steps out of range (${this._currentStep}/${this.steps.length})`));
        }

        // Render step
        this.addStep(step);

        // Update view
        this.update(); 
        
        // NPC is the speaker
        if(step.speaker === 'npc') {
            this.npc.play('talk');
                
            if(this.player) {
                this.player.play('idle');
            }
            
            await Clock.waitForSeconds(2);

            this.npc.play('idle');

        // Player is the speaker
        } else {
            this.npc.play('idle');

            if(this.player) {
                this.player.play('talk');
           
                await Clock.waitForSeconds(2);

                this.player.play('idle');
            }
        }
    }

    /**
     * Restores step offsets
     */
    async animateStepOffsets() {
        let last = this.getDescendants('steps step, steps response').pop();

        if(!last) { return; }
        
        let container = this.getDescendant('steps');
        let offset = last.offsetHeight + parseFloat(window.getComputedStyle(last).marginBottom);

        container.style.transition = null;
        container.style.transform = `translateY(${offset}px)`;

        await Clock.waitForFrames(1);

        container.style.transition = 'transform 0.25s ease';
        container.style.transform = null;

        await Clock.waitForSeconds(0.25);
    }

    /**
     * Adds a step to the conversation
     *
     * @param {Object} config
     */
    async addStep(config) {
        let element = document.createElement('step');

        try {
            element.innerHTML = marked(config.text);
        } catch(e) {
            Debug.error(this, e);
            element.innerHTML = config.text;
        }
        
        element.setAttribute('speaker', config.speaker);

        this.getDescendant('steps').appendChild(element);
        this.animateStepOffsets();

        if(config.responses) {
            element.setAttribute('question', true);

            for(let i = 0; i < config.responses.itemListElement.length; i++) {
                this.addResponse(config.responses.itemListElement[i].item, ANSWER_INIT_DELAY + ANSWER_SEQUENCE_DELAY * i);
            }
        }

        await Clock.waitForFrames(1);

        this.updateBubbles();
    }

    /**
     * Adds a response option to the conversation
     *
     * @param {Object} config
     * @param {Number} delay
     */
    async addResponse(config, delay = 0) {
        if(this.isCompleted) { return; }

        await Clock.waitForSeconds(delay);

        let steps = this.getDescendant('steps')
            
        if(!steps) { return; }

        let element = document.createElement('response');
        element.setAttribute('raw', config.text);

        try {
            element.innerHTML = marked(config.text);
        } catch(e) {
            Debug.error(this, e);
            element.innerHTML = config.text;
        }

        steps.appendChild(element);
        this.animateStepOffsets();

        element.onclick = (e) => {
            this.onClickResponse(config);
        };

        this.updateBubbles();
    }

    /**
     * Gets the CSS
     */
    get css() { return super.css + `
        :host {
            ${this.config.backgroundImage ? `
                background-image: url('${this.config.backgroundImage}');
            ` : ``}
            background-color: white;
            background-size: 100% auto;
            background-position: top;
            background-repeat: no-repeat;
        }

        main {
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
            npc, player, conversation {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }
            
            conversation {
                padding: ${this.config.includePlayer ? '4rem' : '4rem 4rem 4rem 10rem'};
                overflow: hidden;
                flex-basis: 30rem;
                flex-shrink: 0;
                position: relative;
                z-index: 10;
            }
                conversation::after {
                    content: '';
                    position: absolute;
                    z-index: 10;
                    top: 0;
                    width: 100%;
                    height: 10rem;
                    left: 0;
                    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 255), rgba(255, 255, 255, 0));
                    pointer-events: none;
                }

            npc, player {
                flex-grow: 1;
            }
                npc ge-keyframevideo, player ge-keyframevideo {
                    top: 0;
                    position: absolute;
                    height: 100%;
                }

                npc ge-keyframevideo {
                    left: 53.9rem;
                }
                
                player ge-keyframevideo {
                    left: -27.9rem;
                }

        proceed {
            color: white;
            font-size: 1.5rem;
            text-align: center;
            line-height: 8rem;
            font-weight: bold;
            font-style: italic;
            width: 16rem;
            height: 8rem;
            max-height: 10rem;
            display: ${this._isAtQuery ? 'none' : 'block'};
            opacity: ${this.isCompleted ? '0': '1'};
            pointer-events: ${this.isCompleted ? 'none': 'all'};
            float: right;
            font-size: 2rem;
            cursor: pointer;
            position: relative;
            z-index: 20;
            margin: 2rem auto 0 auto;
            transition: transform 0.1s ease;
            
            ${Sprite.mapNamedCss('ui', 'button-arrow-down')}
        }
            proceed:active {
                transform: scale(0.9);
            }

            proceed[hidden] {
                transform: scale(0);
                pointer-events: none;
            }

        answer {
            display: ${this._isAtQuery ? 'flex' : 'none'};
            align-items: center;
            margin-top: 2rem;
            position: relative;
            z-index: 20;
        }
            answer info {
                flex-grow: 1;
                font-size: 1.4rem;
                line-height: 1.25;
                display: block;
                opacity: ${this.isCompleted ? '0': '1'};
            }
                answer info p {
                    margin: 0;
                }

            answer action {
                flex-shrink: 0;
                opacity: ${this._pickedResponses && this._pickedResponses.length > 0 && !this.isCompleted ? '1': '0'};
                pointer-events: ${this._pickedResponses && this._pickedResponses.length > 0 && !this.isCompleted ? 'all': 'none'};
                float: right;
                position: relative;
                z-index: 20;

                ${Sprite.mixin('ui', 'button')}
            }
                answer action:active {
                    transform: scale(0.9);
                }

        steps {
            position: relative;
        }

        step, response {
            padding: 2rem;
            display: block;
            background-color: white;
            color: black;
            font-size: 1.5rem;
            position: relative;
            margin-bottom: 2rem;

            ${Sprite.mixin('ui', 'frame-generic')}
        }
            step p, response p {
                margin: 0;
                padding: 0;
            }

        step[question] {
            padding-right: 6rem;
            color: white;
            background-color: black;
            margin-bottom: 4rem;
        }
            step[question] svg path {
                fill: black;
            }

            step[question]::before {
                content: '?';
                color: white;
                display: block;
                font-size: 6rem;
                font-family: SketchNote, sans-serif;
                top: 50%;
                right: 2rem;
                position: absolute;
                transform: translateY(-50%);
            }

        step svg, response svg {
            position: absolute;
            display: block;
            width: 10rem;
            height: 10rem;
            left: calc(100% - 0.4rem);
            bottom: -3rem;
        }
            step svg path, response svg path {
                fill: white;
                stroke: black;
                stroke-width: 2px;
            }

        response svg, step[speaker="player"] svg {
            right: calc(100% - 0.4rem);
            left: auto;
        }
      
        response[state]::before {
            content: '';
            display: block;
            position: absolute;
            width: 4rem;
            right: -2rem;
            top: -2rem;
            height: 4rem;
        }
            response[state="correct"]::before {
                ${Sprite.mapNamedCss('ui', 'tick-green-shadow')};
            }
            
            response[state="incorrect"]::before {
                ${Sprite.mapNamedCss('ui', 'cross-red-shadow')};
            }

        response {
            cursor: pointer;
            padding: 2rem;
        }

        response:hover,
        response[active] {
            background-color: var(--color-primary);
        }
            response:hover svg path,
            response[active] svg path {
                fill: var(--color-primary);
            }

        step[speaker="npc"]::after {
            right: calc(-0.8rem);
            border-bottom-width: 0;
            border-left-width: 0;
        }
    `; }

    /**
     * Gets the HTML
     */
    get html() { return super.html + `
        <main>
            ${this.config.includePlayer ? `<player></player>` : ``}
            <conversation>
                <steps></steps>
                <proceed>${L10N.translate('stage-challenge-dialog-proceed')}</proceed>
                <answer>
                    <info>
                        ${this.correctResponses > 1 ? `${L10N.translate('more-correct-responses', true)}` : ``}
                    </info>
                    <action>${L10N.translate('answer')}</action>
                </answer>
            </conversation>
            <npc></npc>
        </main>
    `; }
}

StageChallengeDialog.register();
